import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Avatar, notification } from "antd";
import { Col, Row } from "antd";
import { BlockUI } from "primereact/blockui";
import _ from "lodash";

// import HeatSelector from "./NumpadModules/HeatSelector/HeatSelector.component";
import UserSelector from "./NumpadModules/UserSelector/UserSelector.component";

import "./NumPad.styles.css";

import { ProgressSpinner } from "primereact/progressspinner";

class NumPad extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      firstInput: true,
      selectedUser: {},
      blockNumPad: true,
      highlightUserList: false,
    };

    this.addDigit = this.addDigit.bind(this);
    this.clearDigits = this.clearDigits.bind(this);
    this.handleSelectedUser = this.handleSelectedUser.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevStates) {
    //Reset Userlist if changing MO
    if (this.props.MO !== prevProps.MO) {
      //   Set selected user to empty when closing num pad modal
      // Set num pad blocker to visible when closing modal
      if (this.props.user.length !== 1) {
        this.setState({ selectedUser: {} });
        this.setState({ blockNumPad: true });
      }
    }

    //Reset num pad blocker if changing workcenters
    if (
      // this.props.workcenter !== prevProps.workcenter &&
      !_.isEqual(
        this.props.workcenter?.workcenter_no,
        prevProps.workcenter?.workcenter_no
      ) &&
      this.props.user.length > 1
    ) {
      this.setState({ selectedUser: {} });
      this.setState({ blockNumPad: true });
    }
    if (
      this.props.operationNo !== prevProps.operationNo &&
      this.props.user.length > 1
    ) {
      this.setState({ selectedUser: {} });
      this.setState({ blockNumPad: true });
    }
    if (this.props.user && !prevProps.user && this.props.user.length === 1) {
      this.setState({ selectedUser: this.props.user[0] });
    }

    // if (
    //   this.props.heatNumberArray !== prevProps.heatNumberArray &&
    //   this.props.heatNumberArray.length >= 1
    // ) {
    //   this.setState({ selectedHeat: this.props.heatNumberArray[0] });
    // }

    // if (
    //   this.state.selectedHeat !== prevStates.selectedHeat &&
    //   this.props.workcentertype !== 1
    // ) {
    //   this.setState({ firstInput: true });
    //   // this.props.pinHandler(this.props.remainingQty);
    // }

    //If user is selected unblock num pad
    if (this.state.selectedUser !== prevStates.selectedUser) {
      if (Object.keys(this.state.selectedUser).length === 0) {
        this.setState({ blockNumPad: true });
      } else {
        this.setState({ blockNumPad: false, highlightUserList: false });
        // if(this.state.selectedUser.length > 0) {
      }

      // }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSelectedUser(value) {
    this.setState({ selectedUser: value });
  }

  // handleSelectedHeat(value) {
  //   this.setState({ selectedHeat: value });
  // }

  // 12 is the column limit in db
  addDigit(digit, limit = 12, e) {
    var pin = this.props.pin;

    //Clear current input if first input
    if (this.state.firstInput === true) {
      pin = "";
    }

    if (pin.length < limit) {
      const newPin = pin + digit;
      this.props.pinHandler(newPin);
      this.setState({ firstInput: false });
    }
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  }

  clearDigits(e) {
    this.props.pinHandler("");
    let currentPin = this.props.pin;
    this.setState({ firstInput: true });
    if (currentPin === "") {
      this.props.onCancel();
    }
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  }

  onClickCancel = () => {
    this.props.onCancel();
    this.setState({ firstInput: true });
  };

  onPost = (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }

    this.props.onPost(this.props.pin, this.state.selectedUser);

    this.setState({ firstInput: true });

    this.props.pinHandler("");
  };

  render() {
    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        onCancel={() => this.onClickCancel()}
        zIndex={2000}
        width={"90vw"}
        footer={null}
        style={{}}
        centered
      >
        <div className="numpad-background">
          <Row style={{ padding: "1rem" }}>
            <Col span={6} style={{ height: "100%", position: "relative" }}>
              {" "}
              {this.state.highlightUserList ? (
                <div className="glowContainer"></div>
              ) : (
                <></>
              )}
              <div className="userSelectorDiv">
                <UserSelector
                  users={this.props.user}
                  selectedUser={this.state.selectedUser}
                  handleSelectedUser={this.handleSelectedUser}
                  highlightUserList={this.state.highlightUserList}
                />
              </div>
            </Col>
            {/* {this.props.heatNumberArray && this.props.workcentertype !== 1 ? (
              <Col span={6} style={{ height: "100%" }}>
                <div>
                  {" "}
                  <div className="heatSelectorDiv">
                    <HeatSelector
                      MO={this.props.MO}
                      selectedHeat={this.state.selectedHeat}
                      operationNo={this.props.operationNo}
                      handleSelectedHeat={this.handleSelectedHeat}
                      heatNumberArray={this.props.heatNumberArray}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )} */}

            <Col
              span={12}
              style={{
                height: "100%",
              }}
            >
              <div
                className="numpad-container"
                onClick={() => {
                  if (this.state.blockNumPad === true) {
                    this.setState({ highlightUserList: true });
                  }
                }}
              >
                <div
                  id="numpad-overlay"
                  style={
                    this.props.loading
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div id="loading-display">
                    <ProgressSpinner
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                </div>

                <BlockUI
                  blocked={this.state.blockNumPad}
                  style={{ borderRadius: "12px" }}
                >
                  <div className="numpad numpad-grid">
                    <div className="numpad-trackpad">
                      <span className="numpad-label">Please input number</span>
                      <div className="numpad-pin">{this.props.pin}</div>
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(1, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(1, undefined, e)}
                    >
                      1
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(2, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(2, undefined, e)}
                    >
                      2
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(3, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(3, undefined, e)}
                    >
                      3
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(4, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(4, undefined, e)}
                    >
                      4
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(5, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(5, undefined, e)}
                    >
                      5
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(6, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(6, undefined, e)}
                    >
                      6
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(7, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(7, undefined, e)}
                    >
                      7
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(8, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(8, undefined, e)}
                    >
                      8
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(9, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(9, undefined, e)}
                    >
                      9
                    </div>
                    <div
                      className="numpad-block text-danger"
                      onClick={(e) => this.clearDigits(e)}
                      onTouchEnd={(e) => this.clearDigits(e)}
                    >
                      CANCEL
                    </div>
                    <div
                      className="numpad-block"
                      onClick={(e) => this.addDigit(0, undefined, e)}
                      onTouchEnd={(e) => this.addDigit(0, undefined, e)}
                    >
                      0
                    </div>
                    <div
                      className="numpad-block text-success"
                      onClick={(e) => {
                        if (this.props.pin.length > 0) {
                          this.onPost(e);
                        } else {
                          notification.error({
                            message: "Failed",
                            description: "Please input a quantity.",
                            duration: 3,
                            style: {
                              width: "30vw",
                            },
                          });
                        }
                      }}
                      onTouchEnd={(e) => {
                        if (this.props.pin.length > 0) {
                          this.onPost(e);
                        } else {
                          notification.error({
                            message: "Failed",
                            description: "Please input a quantity.",
                            duration: 3,
                            style: {
                              width: "30vw",
                            },
                          });
                        }
                      }}
                    >
                      POST
                    </div>
                  </div>
                </BlockUI>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.users,
  workcentertype: state.workCenter.workcenter_type,
  workcenter: state.workCenter,
});

export default connect(mapStateToProps)(withRouter(NumPad));
