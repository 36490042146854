import {
  Button,
  Spin,
  notification,
  Row,
  Col,
  Descriptions,
  Table,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import {
  getComponentItems,
  getHisotry,
  getReleasedProdOrders,
  updateRoutingQtyService,
} from "./consumption.service";
import "./consumption.styles.scss";
import { connect } from "react-redux";
import config from "../../config";
import ConsumptionTable from "./consumptionTable.component";
import axios from "axios";
import { CONST_API_URL } from "../../../appconfig";
import POCErrorModal from "../ConsumptionModal/pocerrormodal";
import { updateCutConsumptionService } from "../ConsumptionModal/pocmodal.service";
import _ from "lodash";

/*
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};
const { TabPane } = Tabs;
*/

function Consumption({
  parentVisible,
  form,
  Mo,
  workCenter,
  partNo,
  partCount,
  selectedmo,
  workcenterplace,
  setParentVisible,
  terminalPartCount,
  operationNo,
  onUpdateCurrentPartCount,
  fetchSelectedHeatNumber,
  progressSetupTime,
  user,
  operationNoChar,
  hspop,
  division,
  refreshPartScrapTotalCount,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [heatnoVisible, setheatnoVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingForPost, setLoadingForPost] = useState(false);
  const [loadingForTable, setLoadingForTable] = useState(true);
  const [currentState, setCurrentState] = useState([]);
  const [initialCount, setInitialCount] = useState([]);
  const [sendBackState, setSendBackState] = useState([]);
  const [history, setHistory] = useState([]);
  const [parentState, setParentState] = useState([]);

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalData, setErrorModalData] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentSuccessful, setEmailSentSuccessful] = useState(false);

  const [componentStep, setComponentStep] = useState(0);
  const [rollbackStatus, setRollbackStatus] = useState([]);

  useEffect(() => {
    setModalVisible(parentVisible);
  }, [parentVisible]);

  useEffect(() => {
    async function fetchComponentItemsandHistory() {
      setLoading(true);
      let response = await getComponentItems(Mo, workCenter, partNo, division);

      let tempObj = response.map((component) => ({
        key: component.ComponentItemNo,
        name: component.ComponentItemNo,
        inputQuantity: 0,
        scrapQuantity: 0,
        totalQuantity: 0,
        ...component,
      }));
      setCurrentState(tempObj);
      //console.log("79", currentState);
      const tempHistory = [];
      for (let i = 0; i < tempObj.length; i++) {
        let response = await getHisotry({
          consumptionSource: "MO",
          webUserID: user.first_name + " " + user.last_name,
          division_Code: division,
          mO_Job_No: tempObj[i].MO_No,
          item_No: tempObj[i].ComponentItemNo,
          mO_Line_No: tempObj[i].MO_Line_No,
        });
        tempHistory.push({
          history: response.history,
          componentItemNo: tempObj[i].ComponentItemNo,
        });
      }
      let orderHistory = combineHistory(tempHistory);
      setHistory(orderHistory);
      setLoading(false);
    }
    if (modalVisible === true) {
      fetchComponentItemsandHistory();
    }
  }, [modalVisible]);

  const combineHistory = (oldhistory) => {
    let tempHistory = [];
    for (let i = 0; i < oldhistory.length; i++) {
      for (let j = 0; j < oldhistory[i].history.length; j++) {
        if (oldhistory[i].history[j].charAt(0) != "Q") {
          console.log(oldhistory[i].history[j]);
          tempHistory.push({
            data: oldhistory[i].history[j],
            time: oldhistory[i].history[j].split(",")[8],
          });
        }
      }
    }
    tempHistory.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.time) - new Date(a.time);
    });
    return tempHistory;
  };

  //Return the title of the modal
  const modalTitle = () => {
    return (
      <Row>
        <Col span={8}>
          {" "}
          <Descriptions bordered={true} colon={false}>
            <Descriptions.Item style={{ background: "#1e1e1e" }}>
              <h1 style={{ color: "white" }}>
                <b>Consumption</b>
              </h1>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Descriptions bordered={true} colon={false}>
            <Descriptions.Item
              style={{ background: "#1e1e1e" }}
              label={
                <h1 style={{ color: "white", fontWeight: "bold" }}>
                  Output Quantity
                </h1>
              }
            >
              <h1 style={{ color: "white", fontWeight: "bold" }}>
                {partCount}
              </h1>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  };

  //Handle On Click Cancel button
  const onClickCancel = (e) => {
    setParentVisible(false);
    setSendBackState([]);
    setComponentStep(0);

    handleHoldButton(e);
  };

  //Handle on Click post button
  const onClickPost = async (e) => {
    let navErrorMessage = "";
    setLoadingForPost(true);
    var today = new Date();
    var length = sendBackState.length;
    let optionalMOHeader = {};

    for (var index = 0; index < length; ++index) {
      //console.log("Doing index", index);
      var item = sendBackState[index];
      console.log("item", item);

      if (!!!item.Location && _.isEmpty(optionalMOHeader)) {
        optionalMOHeader = (await getReleasedProdOrders(Mo))[0];
      }
      //Extra fields to pass to backend
      const consumptionObject = {
        CompLineNo: item.ComponentLineNo,
        CompItemNo: item.ComponentItemNo,
        LotNo: !!item.LotNumber ? item.LotNumber : "",
        LocationCode: !!item.Location
          ? item.Location
          : optionalMOHeader.Location_Code,
        // LotNo: item.LotNumber === "-" ? "" : item.LotNumber,
        // LocationCode: item.Location === "-" ? "" : item.Location,
        // LocationCode: item.Location,
        CutLen: item.ComponentQuantityPer,
        CutQty: item.InputQuantity,
        ScrapQty: item.ScrapQuantity,
        TotalQty: item.TotalQuantity,
        CompItemDesc: item.ComponentItemDescription,
      };

      const extraFields = {
        facility: division === "MCKENZIE" ? "MCKENZIE" : "Havensteel",
        prodOrdNo: item.MO_No,
        prodOrdLineNo: item.MO_Line_No,

        webUserID: user.first_name + " " + user.last_name,
        strAppLocalDate:
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        strAppLocalTime:
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds(),
        lineNo: item.Line_No,
      };

      var result = await updateCutConsumptionService({
        batch: [consumptionObject],
        extraFields: extraFields,
      });
      if (result.data.type) {
        // Success, Do nothing
      } else {
        navErrorMessage = result.data.msg;
        showErrorModalMessage(
          {
            MO_No: consumptionObject.prodOrdNo,
            PartNumber: partNo,
            ComponentItemNo: consumptionObject.compItemNo,
          },
          navErrorMessage,
          consumptionObject
        );

        setLoadingForPost(false);
        setRollbackStatus([]);
        // Error, reload from index
        for (var i = 0; i < index; ++i) {
          //console.log("Reloading index", i);
          var itemReload = sendBackState[i];
          // console.log("ReloadTEST", itemReload);
          const consumptionObjectReload = {
            CompLineNo: itemReload.ComponentLineNo,
            CompItemNo: itemReload.ComponentItemNo,
            LotNo: !!itemReload.LotNumber ? itemReload.LotNumber : "",
            LocationCode: !!itemReload.Location ? itemReload.Location : "",
            // LotNo: itemReload.LotNumber === "-" ? "" : itemReload.LotNumber,
            // LocationCode:
            //   itemReload.Location === "-" ? "" : itemReload.Location,
            // LocationCode: itemReload.Location,
            CutLen: itemReload.ComponentQuantityPer,
            CutQty: itemReload.InputQuantity * -1,
            ScrapQty: itemReload.ScrapQuantity * -1,
            TotalQty: itemReload.TotalQuantity * -1,
          };

          const extraFieldsReload = {
            facility: division === "MCKENZIE" ? "MCKENZIE" : "Havensteel",
            prodOrdNo: itemReload.MO_No,
            prodOrdLineNo: itemReload.MO_Line_No,

            webUserID: user.first_name + " " + user.last_name,
            strAppLocalDate:
              today.getFullYear() +
              "-" +
              (today.getMonth() + 1) +
              "-" +
              today.getDate(),
            strAppLocalTime:
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds(),
            lineNo: itemReload.Line_No,
          };

          // rollback by posting negative value
          setRollbackStatus((prev) => {
            // rollback is pending
            const newState = prev;
            newState[i] = {
              ...consumptionObjectReload,
            };
            return newState;
          });

          const rbResult = await updateCutConsumptionService({
            batch: [consumptionObjectReload],
            extraFields: extraFieldsReload,
          });
          setRollbackStatus((prev) => {
            // rollback is done, update revertStatus
            const newState = prev;
            newState[i] = {
              ...newState[i],
              revertStatus: rbResult.data.type,
            };
            return newState;
          });
        }
        break;
      }
    }

    if (index === length) {
      // if (store.getState().selectedmo?.isLastOperation) {
      var item = currentState[0];
      console.log("]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]");
      console.log(item);
      console.log(user);
      console.log("]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]");
      //console.log(selectedmo);
      const outputObject = {
        SetupTimeHr: Number(
          (item.MO_Line_Quantity / partCount) * (progressSetupTime / 3600000)
        ),
        ComponentItemNo: item.ComponentItemNo,
        facility: division === "MCKENZIE" ? "MCKENZIE" : "Havensteel",
        hspopNo: hspop,
        // lot_Heat_No: "",
        operationNo: operationNoChar,
        outputQty: partCount,
        prodOrdLineNo: item.MO_Line_No,
        prodOrdNo: item.MO_No,
        prodOrderItemNo: item.PartNumber,
        routingNo: item.RoutingNo,
        routingReferenceNo: item.RoutingReferenceNo,
        runTimeHr: Number(localStorage.getItem("runTime") / 3600000),
        scrapQty: 0,
        userId: user.ID,
        webUserActualID: user.ID,
        webUserID: user.first_name + " " + user.last_name,
        workCenterNo: workCenter.workcenter_no,
      };

      const packageConsumptionBatch = sendBackState.map((item) => ({
        CompLineNo: item.ComponentLineNo,
        CompItemNo: item.ComponentItemNo,
        LotNo: !!item.LotNumber ? item.LotNumber : "",
        LocationCode: !!item.Location ? item.Location : "",
        // LotNo: item.LotNumber === "-" ? "" : item.LotNumber,
        // LocationCode: item.Location === "-" ? "" : item.Location,
        // LocationCode: item.Location,
        CutLen: item.ComponentQuantityPer,
        CutQty: item.InputQuantity,
        ScrapQty: item.ScrapQuantity,
        TotalQty: item.TotalQuantity,
        CompItemDesc: item.ComponentItemDescription,
      }));
      var outputResult = await updateRoutingQtyService(
        outputObject,
        packageConsumptionBatch
      );
      if (outputResult.data.type) {
        //update part_count in sql but this is not in use because of pulling part count directly from NAV
        axios
          .post(`${CONST_API_URL}/AdjustPartCount`, {
            MO: Mo,
            OperationNo: operationNoChar,
            count: Number(terminalPartCount) + Number(partCount),
          })
          .then((res) => {});
        refreshPartScrapTotalCount();
        fetchSelectedHeatNumber(Mo);

        // All success
        notification.success({
          message: "Success",
          description: "Successfully posted",
          duration: 5,
          style: {
            width: "50vw",
          },
        });
      } else {
        navErrorMessage = outputResult.data.msg;
        showErrorModalMessage(
          {
            MO_No: outputObject.prodOrdNo,
            PartNumber: partNo,
          },
          navErrorMessage,
          outputObject
        );

        setLoadingForPost(false);
        setRollbackStatus([]);
        // Error, reload from index
        for (var i = 0; i < index; ++i) {
          //console.log("Reloading index", i);
          var itemReload = sendBackState[i];
          console.log(i);
          console.log(sendBackState[i]);
          const consumptionObjectReload = {
            CompLineNo: itemReload.ComponentLineNo,
            CompItemNo: itemReload.ComponentItemNo,
            LotNo: !!itemReload.LotNumber ? itemReload.LotNumber : "",
            LocationCode: !!itemReload.Location ? itemReload.Location : "",
            // LotNo: itemReload.LotNumber === "-" ? "" : itemReload.LotNumber,
            // LocationCode:
            //   itemReload.Location === "-" ? "" : itemReload.Location,
            // LocationCode: itemReload.Location,
            CutLen: itemReload.ComponentQuantityPer,
            CutQty: itemReload.InputQuantity,
            ScrapQty: itemReload.ScrapQuantity,
            TotalQty: itemReload.TotalQuantity,
          };

          const extraFieldsReload = {
            facility: division === "MCKENZIE" ? "MCKENZIE" : "Havensteel",
            prodOrdNo: itemReload.MO_No,
            prodOrdLineNo: itemReload.MO_Line_No,

            webUserID: user.first_name + " " + user.last_name,
            strAppLocalDate:
              today.getFullYear() +
              "-" +
              (today.getMonth() + 1) +
              "-" +
              today.getDate(),
            strAppLocalTime:
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds(),
            lineNo: itemReload.Line_No,
          };

          // rollback by posting negative value
          setRollbackStatus((prev) => {
            // rollback is pending
            const newState = prev;
            newState[i] = {
              ...consumptionObjectReload,
            };
            return newState;
          });
          const rbResult = await updateCutConsumptionService({
            batch: [consumptionObjectReload],
            extraFields: extraFieldsReload,
          });
          setRollbackStatus((prev) => {
            // rollback is done, update revertStatus
            const newState = prev;
            newState[i] = {
              ...newState[i],
              revertStatus: rbResult.data.type,
            };
            return newState;
          });
        }
        setLoadingForPost(false);
      }
    } else {
    }
    setLoadingForPost(false);
    setParentVisible(false);
    setSendBackState([]);
    handleHoldButton(e);
  };

  const onClickOpenHeatModal = async (e) => {
    setheatnoVisible(true);
    // handleHoldButton(e);
  };

  //Handle hold button
  const handleHoldButton = (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  };

  //Return the footer of the modal
  const modalFooter = () => {
    return [
      <Button
        key="ModalCancelButton"
        disabled={loading || loadingForPost || loadingForTable}
        type="primary"
        style={{
          fontSize: "3rem",
          height: "fit-content",
          backgroundColor: config.branding.primaryColor,
          borderColor: config.branding.primaryColor,
        }}
        onClick={(e) => {
          onClickCancel(e);
        }}
        onTouchEnd={(e) => {
          onClickCancel(e);
        }}
      >
        Cancel
      </Button>,
      <Button
        key="ModalSaveButton"
        disabled={
          loading ||
          loadingForPost ||
          loadingForTable ||
          componentStep === 2 ||
          sendBackState.length <= 0
        }
        type="primary"
        style={{
          fontSize: "3rem",
          height: "fit-content",
          backgroundColor: config.branding.primaryColor,
          borderColor: config.branding.primaryColor,
        }}
        onClick={(e) => onClickPost(e)}
        onTouchEnd={(e) => onClickPost(e)}
        width={window.innerWidth > 1280 ? "80vw" : "90vw"}
      >
        Post
      </Button>,
    ];
  };

  const showErrorModalMessage = (
    dataObject,
    errorMessage,
    consumptionObject
  ) => {
    setErrorModalVisible(true);
    setErrorModalData({
      ...dataObject,
      errorMessage: errorMessage,
      ...consumptionObject,
      userFullName: `${user?.first_name} ${user?.last_name}`,
      MO_No: Mo,
    });
  };

  const createPFooter = () => {
    if (rollbackStatus?.length > 0) {
      return (
        <div style={{ border: "1px dashed darkgray", padding: "0.4em" }}>
          <h3 style={{ fontSize: "1.8em" }}>Revert Status</h3>
          {/* <p style={{ fontSize: "1.6em" }}> */}
          {rollbackStatus.map((obj, index) => (
            <p style={{ fontSize: "1.6em", margin: 0 }}>
              {`${index + 1}. `}
              {obj.CompItemNo} (cutQty:{obj.CutQty}
              {obj.ScrapQty ? `, scrap: ${obj.ScrapQty}` : ""}
              {obj.LotNo ? `, heat: ${obj.LotNo}` : ""}) -{" "}
              {obj.revertStatus ? (
                <span className="text-success">Reverted</span>
              ) : obj.revertStatus === undefined ? (
                <Spin />
              ) : (
                <span className="text-danger">Revert failed</span>
              )}
            </p>
          ))}
          {/* </p> */}
        </div>
      );
    } else {
      return <></>;
      // return <Spin />;
    }
  };

  const renderContent = () => {
    const columns = [
      {
        title: "# Cuts",
        dataIndex: "numOfCuts",
        key: "numOfCuts",
      },
      {
        title: "Cut By",
        dataIndex: "cutBy",
        key: "cutBy",
      },
      {
        title: "Material",
        dataIndex: "material",
        key: "material",
      },
      {
        title: "Heat",
        dataIndex: "heat",
        key: "heat",
      },
      { title: "Cut Qty", dataIndex: "cutQty", key: "cutQty" },
      { title: "Length", dataIndex: "length", key: "length" },
      { title: "Scrap", dataIndex: "scrap", key: "scrap" },
      // {
      //   title: "Operation No",
      //   dataIndex: "operationNo",
      //   key: "operationNo",
      // },
      // { title: "Consumed By", dataIndex: "consumedBy", key: "consumedBy" },
    ];

    const parseHistory = () => {
      var data = [];

      for (var i = 0; i < history.length; i++) {
        var dataString = history[i].data;
        // var numOfConsumptions = 0;
        // var pallet = "";
        // var consumedBy = "";
        // var material = "";
        // var heat = "";
        // var location = "PENDING";

        // console.log(dataString);

        // numOfConsumptions = dataString.split(",")[5];
        // consumedBy = dataString.split(",")[4];
        // material = dataString.split(",")[1];
        // heat = dataString.split(",")[3];
        // location = dataString.split(",")[2];
        // operationNo = dataString.split(",")[7];
        // pallet = dataString.split(",")[6];

        var cutQty = "";
        var cutBy = "";
        var material = "";
        var heat = "";
        var numOfCuts = "";
        var length = "";
        var scrap = "";

        var split = dataString.split(",");

        cutQty = split[0];
        cutBy = split[1];
        material = split[2];
        heat = split[3];
        numOfCuts = split[4];
        length = split[5];
        scrap = split[6];

        data.push({
          key: data.length,
          cutQty: cutQty,
          cutBy: cutBy,
          material: material,
          heat: heat,
          numOfCuts: numOfCuts,
          length: length,
          scrap: scrap,
          // numOfConsumptions: numOfConsumptions,
          // consumedBy: consumedBy,
          // material: material,
          // pallet: pallet,
          // heat: heat,
          // location: location,
          // operationNo: operationNo,
        });
        //Remove row with header information
      }

      // data.shift();

      return data;
    };

    return (
      <div>
        {loadingForPost ? (
          <div
            style={{
              height: "65vh",

              color: "black",
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spin
              size="large"
              tip="Posting To Microsoft Business Central"
            ></Spin>
          </div>
        ) : (
          <div>
            <Row
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingTop: "20px",
              }}
            >
              <Col span={24}>
                {/* {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 999,
                      color: "black",
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spin size="large" tip="Loading Consumption History"></Spin>
                  </div>
                ) : (
                  ""
                )}{" "} */}
                <div style={{}}>
                  <h1
                    className="animate__animated animate__fadeIn"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      background: "#fafafa",
                      padding: "10px",
                      background: "#1e1e1e",
                      color: "white",
                    }}
                  >
                    Consume History
                  </h1>
                  {/* <Divider /> */}
                  {loading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        color: "black",
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "100px",
                      }}
                    >
                      {" "}
                      <Spin
                        size="large"
                        tip="Loading Consumption History"
                      ></Spin>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "300px",

                        overflow: "auto",
                      }}
                      className="history__table--big animate__animated animate__fadeIn"
                    >
                      {" "}
                      <Table
                        bordered
                        columns={columns}
                        dataSource={parseHistory()}
                        pagination={false}
                      ></Table>
                    </div>
                  )}
                </div>
              </Col>

              <Col span={24} style={{ paddingBottom: "1rem" }}>
                {loadingForTable && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 10000000000000,
                      color: "black",
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spin size="large" tip="Loading"></Spin>
                  </div>
                )}
                <div
                  className="multi-step-table"
                  style={{
                    // height: "800px",
                    marginTop: "50px",
                  }}
                >
                  <ConsumptionTable
                    moNumber={Mo}
                    inputPartCount={partCount}
                    // workCenter={workCenter}
                    // itemNumber={partNo}
                    currentState={currentState}
                    sendBackState={sendBackState}
                    handleSetSendBackState={(newState) => {
                      console.log("newState", newState);
                      setSendBackState(newState);
                    }}
                    loading={loadingForTable}
                    setLoading={(value) => setLoadingForTable(value)}
                    // parentState={sendBackState}
                    componentStep={componentStep}
                    setComponentStep={(newState) => setComponentStep(newState)}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="multi-consumption-modal">
      <Modal
        title={modalTitle()}
        visible={modalVisible}
        width={window.innerWidth - 100}
        height={window.innerHeight - 50}
        destroyOnClose
        //onCancel={() => handleMocalClose()}
        getContainer={false}
        centered
        forceRender={true}
        footer={modalFooter()}
        closable={false}
      >
        {renderContent()}
      </Modal>

      <POCErrorModal
        visible={errorModalVisible}
        onCancel={() => {
          setErrorModalVisible(false);
          setRollbackStatus([]);
        }}
        data={errorModalData}
        setEmailSent={(status) => setEmailSent(status)}
        setEmailSuccessful={(status) => setEmailSentSuccessful(status)}
        setErrorModalVisible={(status) => setErrorModalVisible(status)}
        emailSent={emailSent}
        emailSentSuccessful={emailSentSuccessful}
        pFooter={createPFooter()}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  workcenterplace: state.workCenter?.workcenter_name || "",
  division: state.appConfig.division,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Consumption
);
