import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../assets/Haven_logo_Final_White-01.svg";

import { Button } from "primereact/button";
import "./header.styles.css";
import { Avatar, Menu, Dropdown, Select, notification, Row } from "antd";
import axios from "axios";

import { withRouter } from "react-router-dom";
import KeyPad from "../login/KeyPad.component";
import moment from "moment";
import HelpScreenshotButton from "./HelpScreenshotButton/HelpScreenshotButton.component";
import { getDivisions } from "../../api/api";
import { CONST_API_URL } from "../../appconfig";
import { RedoOutlined, LoadingOutlined } from "@ant-design/icons";
import { confirmDialog } from "primereact/confirmdialog";
import SupervisorPermissionComponent from "../SupervisorPermissionModal/SupervisorPermission.component";
import SettingsDrawer from "../settingsDrawer/settingsDrawer.component";

import { connect } from "react-redux";

import { workCenterActions } from "../../store/work-center-slice";
import { appConfigActions } from "../../store/app-config.slice";
const { Option } = Select;

const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

class Header extends React.Component {
  _isMounted = false;
  state = {
    workCenters: [],
    hover: false,
    signinVisible: false,
    emailVisible: false,
    date: new Date(),
    test: "",
    workCentersClicked: false,
    divisionClicked: false,
    divisions: [],
    reloadLoading: false,
    online: true,
    showSupervisorPopup: false,
    logoutUserData: { user: null, logoutType: "" },
  };

  componentDidMount() {
    const fetchDivisions = async () => {
      const result = await getDivisions();
      if (result) {
        this.setState({ divisions: result });
      }
    };

    this._isMounted = true;
    axios
      .post(CONST_API_URL + "/GetWorkCenters", {
        ...(this.props.appConfig?.division && {
          division: this.props.appConfig?.division,
        }),
      })
      .then((res) => {
        const data = res.data;
        this.setState({ workCenters: data });
      });

    this.updateUserRefreshScreen(this.props.workCenter.workcenter_no);
    this.timeinterval = setInterval(() => {
      if (this._isMounted) {
        this.setState({ date: new Date() });
      }
    }, 60 * 1000);
    this.updateUserRefreshScreeninterval = setInterval(async () => {
      if (this.props.workCenter.workcenter_no !== "") {
        this.updateUserRefreshScreen(this.props.workCenter.workcenter_no);
      }
    }, 30 * 1000);

    fetchDivisions();
    if (this.props.workCenter?.workcenter_no) {
      this.updateUserSwitchWorkCenter(this.props.workCenter.workcenter_no);
    }

    //---Online/offline status---
    this.setState({
      online: typeof navigator.onLine === "boolean" ? navigator.onLine : true,
    });
    window.addEventListener("online", this.goOnline);
    window.addEventListener("offline", this.goOffline);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timeinterval);
    clearInterval(this.updateUserRefreshScreeninterval);

    //---Online/offline status---
    window.removeEventListener("online", this.goOnline);
    window.removeEventListener("offline", this.goOffline);
  }

  goOnline = () => this.setState({ online: true });
  goOffline = () => this.setState({ online: false });

  onHover = () => {
    this.setState({ hover: true });
  };

  updateUserSwitchWorkCenter = async (workcenter) => {
    let currentUser = await axios
      .post(`${CONST_API_URL}/GetUserByWorkCenter`, { workcenter })
      .then((res) => {
        return res;
      })
      .catch((error) => console.log(`GetUsers Client - ${error}`));
    currentUser = currentUser.data;

    this.props.updateUsers(currentUser);
  };

  fetchUsers = async (workcenterNo) => {
    const users = (
      await axios.post(`${CONST_API_URL}/GetUserByWorkCenter`, {
        workcenter: workcenterNo,
      })
    )?.data;
    return users;
  };

  updateUserRefreshScreen = async (workcenter) => {
    // let currentUser = await axios
    //   .post(`${CONST_API_URL}/GetUserByWorkCenter`, { workcenter })
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((error) => console.log(`GetUsers Client - ${error}`));
    // currentUser = currentUser.data;
    // if (!this.props.user) {
    //   updateUserSession(currentUser);
    // } else {
    //   if (!arraysEqual(currentUser, this.props.user)) {
    //     updateUserSession(currentUser);
    //   }
    // }
  };

  onSelect = async (value, allValue) => {
    // await this.updateUserSwitchWorkCenter(allValue.workcenter_no);
    const users = await this.fetchUsers(allValue.workcenter_no);

    const formattedValue = {
      workcenter_name: value,
      workcenter_no: allValue.workcenter_no,
      workcenter_type: allValue.consumption_type,
      workcenterpausetype: allValue.pause_type,
      workcenterNonProductiveGroup: allValue.non_productive_group,
      workcenterLoginSupervisorRequired:
        allValue.login_supervisor_approval_required,
      subcontractor_no: allValue.subcontractor_no,
      ...(users && { users: users }),
    };
    this.setState({
      workCentersClicked: false,
    });

    //Store in redux
    this.props.updateWorkCenter(formattedValue);
  };

  onSelectDivision = (selectedDivision) => {
    this.setState(
      {
        divisionClicked: false,
        workCenters: [],
      },
      () =>
        axios
          .post(CONST_API_URL + "/GetWorkCenters", {
            division: selectedDivision,
          })
          .then((res) => {
            const data = res.data;
            this.setState({ workCenters: data });
          })
    );

    //Store in redux
    this.props.updateDivision(selectedDivision);
  };

  onClickStopProg = (e) => {
    e.stopPropagation();
  };

  onClick = () => {
    this.setState({ signinVisible: true });
  };

  onEmailClick = () => {
    this.setState({ emailVisible: true });
  };

  onWorkcenterClick = () => {
    this.setState({ workCentersClicked: true });
  };

  onDivisionClick = () => {
    this.setState({ divisionClicked: true });
  };

  onModalCancle = () => {
    this.setState({ signinVisible: false });
  };

  onEmailCancel = () => {
    this.setState({ emailVisible: false });
  };

  onSignOut = async (user, type, supervisorApproved = false) => {
    const currentDateTime = moment().utc().format("YYYY-MM-DD HH:mm:ss:SSS");
    let userArray = this.props.workCenter.users;
    let signOutUser = userArray.find((e) => e.ID === user.ID);

    const userLogoutData = {
      user_id: user.ID,
      log_out: currentDateTime,
      log_id: signOutUser.log_id,
      supervisorApproved,
      endShiftFg: type === "endshift",
    };
    try {
      await axios.post(`${CONST_API_URL}/RecordUserLogout`, { userLogoutData });

      userArray = userArray.filter((el) => el.ID !== user.ID);
      // if (userArray.length === 0) {
      //   // disconnectUserSession();

      // } else {
      //   // disconnectUserSession();
      //   // updateUserSession(userArray);

      // }

      this.props.updateUsers(userArray);

      notification.success({
        message: "Success",
        description: `Username ${user.first_name} ${user.last_name} is successfully logged out.`,
        duration: 10,
        style: {
          width: "40vw",
        },
      });
    } catch (error) {
      console.log(
        `ERROR - ${
          type === "endshift"
            ? "RecordUserLogout-endshift"
            : "RecordUserSignout-signout"
        }`,
        error
      );
      const errorMsg =
        error?.response?.data?.msg || "Unexpected Error occurred";
      const errorCode = error?.response?.data?.errorCode;
      // notification.error({
      //   description: errorMsg,
      //   duration: 10,
      //   style: {
      //     minWidth: "30vw",
      //   },
      // });

      this.setState({
        logoutUserData: { user: user, logoutType: type },
        showSupervisorPopup: true,
      });
    }
  };

  onClickEndShift = (user) => {
    confirmDialog({
      header: <span style={{ fontSize: "2rem" }}>Confirm End Shift</span>,
      message: (
        <div>
          <p style={{ fontSize: "3rem" }}>
            User: {user.first_name} {user.last_name}
            <br />
            Have you completed your timecard entries?
          </p>
        </div>
      ),
      accept: () => this.onSignOut(user, "endshift"),
    });
  };

  onSuccessHandler = () => {
    const { user, logoutType } = this.state.logoutUserData;
    if (user) {
      this.onSignOut(user, logoutType, true);
    }
  };

  toCheckboxFormat = (objArray) => {
    let formatted = [];

    for (var i = 0; i < objArray.length; i++) {
      formatted.push(objArray[i].Name || "");
    }
    return formatted;
  };

  filterOutHidden = (objArray) => {
    if (!this.props.deviceConfig?.hiddenWorkCenters) return objArray;

    let filtered = objArray.filter((elem) => {
      return !this.props.deviceConfig?.hiddenWorkCenters.includes(elem.Name);
    });
    return filtered;
  };

  renderUserAvatars = (users) => {
    if (!users || users.length < 1) {
      return <></>;
    }

    if (users.length <= 2) {
      return users.map((user) => {
        return (
          <Dropdown
            key={user.ID}
            overlay={
              <Menu>
                <Menu.Item key="MenuItem">
                  <Row style={{ alignItems: "center", fontSize: "2em" }}>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                      }}
                    >{`${user.first_name} ${user.last_name}`}</p>
                    <Button
                      size="lg"
                      severity="danger"
                      style={{ fontSize: "3rem", margin: "0 1rem" }}
                      onClick={() => this.onSignOut(user, "signout")}
                      onTouchEnd={(e) => {
                        e.preventDefault();
                        this.onSignOut(user, "signout");
                      }}
                    >
                      <h1 style={{ color: "white" }}>Sign out</h1>
                    </Button>
                    <Button
                      size="lg"
                      variant="danger"
                      style={{ fontSize: "3rem", margin: "0 1rem" }}
                      onClick={(e) => this.onClickEndShift(user)}
                      onTouchEnd={(e) => {
                        e.preventDefault();
                        this.onClickEndShift(user);
                      }}
                    >
                      <h1 style={{ color: "white" }}>End Shift</h1>
                    </Button>
                  </Row>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Avatar
              key={user.log_id}
              style={{
                backgroundColor: "#1E90FF",
                marginRight: "15px",
                fontSize: "3rem",
              }}
              size={70}
            >
              {user.first_name.charAt(0) + user.last_name.charAt(0)}
            </Avatar>
          </Dropdown>
        );
      });
    } else {
      const firstUser = users[0];
      const addtUserCount = users.length - 1 || 0;
      return (
        <>
          <Dropdown
            key={firstUser.ID}
            overlay={
              <Menu>
                <Menu.Item key="MenuItem">
                  <Row style={{ alignItems: "center", fontSize: "2em" }}>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                      }}
                    >{`${firstUser.first_name} ${firstUser.last_name}`}</p>
                    <Button
                      size="lg"
                      severity="danger"
                      style={{ fontSize: "3rem", margin: "0 1rem" }}
                      onClick={() => this.onSignOut(firstUser, "signout")}
                      onTouchEnd={(e) => {
                        e.preventDefault();
                        this.onSignOut(firstUser, "signout");
                      }}
                    >
                      <h1 style={{ color: "white" }}>Sign out</h1>
                    </Button>
                    <Button
                      size="lg"
                      variant="danger"
                      style={{ fontSize: "3rem", margin: "0 1rem" }}
                      onClick={(e) => this.onClickEndShift(firstUser)}
                      onTouchEnd={(e) => {
                        e.preventDefault();
                        this.onClickEndShift(firstUser);
                      }}
                    >
                      <h1 style={{ color: "white" }}>End Shift</h1>
                    </Button>
                  </Row>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Avatar
              key={firstUser.log_id}
              style={{
                backgroundColor: "#1E90FF",
                marginRight: "15px",
                fontSize: "3rem",
              }}
              size={70}
            >
              {firstUser.first_name.charAt(0) + firstUser.last_name.charAt(0)}
            </Avatar>
          </Dropdown>
          <Dropdown
            key={"addtionalUserDropdown"}
            overlay={
              <Menu>
                {users.slice(1).map((user, index) => (
                  <Menu.Item key={`addtMenuItem-${index}`}>
                    <Row style={{ alignItems: "center", fontSize: "2em" }}>
                      <p
                        style={{
                          flex: 1,
                          margin: 0,
                        }}
                      >{`${user.first_name} ${user.last_name}`}</p>
                      <Button
                        size="lg"
                        severity="danger"
                        style={{ fontSize: "3rem", margin: "0 1rem" }}
                        onClick={() => this.onSignOut(user, "signout")}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          this.onSignOut(user, "signout");
                        }}
                      >
                        <h1 style={{ color: "white" }}>Sign out</h1>
                      </Button>
                      <Button
                        size="lg"
                        variant="danger"
                        style={{ fontSize: "3rem", margin: "0 1rem" }}
                        onClick={(e) => this.onClickEndShift(user)}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          this.onClickEndShift(user);
                        }}
                      >
                        <h1 style={{ color: "white" }}>End Shift</h1>
                      </Button>
                    </Row>
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <Avatar
              key={"additionalUserAvatar"}
              style={{
                backgroundColor: "#1E90FF",
                marginRight: "15px",
                fontSize: "3rem",
              }}
              size={70}
            >
              {`+${addtUserCount}`}
            </Avatar>
          </Dropdown>
        </>
      );
    }
  };

  render() {
    return (
      <>
        <Navbar bg="black" variant="dark">
          <Container fluid>
            <HelpScreenshotButton />
            <Navbar.Brand
              href="/"
              onClick={() => this.setState({ reloadLoading: true })}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                alt=""
                src={logo}
                width="340"
                className="d-inline-block align-top"
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {this.state.reloadLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: "2em", color: "white" }}
                  />
                ) : (
                  <RedoOutlined style={{ fontSize: "2em", color: "white" }} />
                )}
                <div>
                  {this.state.online ? (
                    <div className="header__status-online">
                      <i className="pi pi-circle-fill mr-1" />
                      online
                    </div>
                  ) : (
                    <div className="header__status-offline">
                      <i className="pi pi-circle-fill mr-1" />
                      offline
                    </div>
                  )}
                </div>
              </div>
            </Navbar.Brand>

            <Navbar.Collapse
              id="responsive-navbar-nav"
              style={{ textAlign: "center", width: "100%" }}
            >
              {!this.props.appConfig?.division && (
                <Nav className="ms-3 me-3" style={{ width: "100%" }}>
                  <div
                    onClick={this.onDivisionClick}
                    className="d-flex align-items-center animate__animated animate__fadeIn"
                    style={{ width: "100%" }}
                  >
                    {!this.props.appConfig?.division &&
                    this.state.divisionClicked ? (
                      <Select
                        defaultOpen="true"
                        autoFocus={true}
                        listHeight={window.innerHeight * 0.7}
                        optionFilterProp="children"
                        onClick={this.onClickStopProg}
                        onSelect={this.onSelectDivision}
                        value={this.props.appConfig.division || ""}
                        size={"large"}
                        style={{
                          width: "100%",
                          fontSize: "3rem",
                        }}
                        // onSearch={this.onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onDropdownVisibleChange={() =>
                          this.setState({ divisionClicked: false })
                        }
                      >
                        {this.state.divisions.map((item) => {
                          return (
                            <Option
                              style={{ fontSize: "2.6rem" }}
                              key={item.division}
                              value={item.division}
                            >
                              {item.division}
                            </Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <div
                        style={{
                          color: "white",
                          fontSize: "2.8rem",
                          fontFamily: `Roboto, sans-serif`,
                          padding: "0.5rem 1rem",
                          width: "100%",
                          borderRadius: "5px",
                          ...(!this.props.appConfig?.division
                            ? {
                                background: "#d32f2f",
                              }
                            : {
                                border: "1px solid white",
                              }),
                        }}
                      >
                        <p>
                          {this.props.appConfig.division || "Select a Division"}
                        </p>
                      </div>
                    )}
                  </div>
                </Nav>
              )}

              {this.props.appConfig?.division && (
                <Nav className="ms-3 me-3" style={{ width: "100%" }}>
                  <div
                    onClick={this.onWorkcenterClick}
                    className="d-flex align-items-center animate__animated animate__fadeIn"
                    style={{ width: "100%" }}
                  >
                    {this.state.workCentersClicked ? (
                      <Select
                        defaultOpen="true"
                        autoFocus={true}
                        listHeight={window.innerHeight * 0.7}
                        placeholder="Select a Work Station"
                        optionFilterProp="children"
                        onClick={this.onClickStopProg}
                        onSelect={this.onSelect}
                        value={this.props.workCenter?.workcenter_name}
                        size={"large"}
                        style={{
                          width: "100%",
                          fontSize: "3rem",
                        }}
                        onSearch={this.onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionLabelProp="value"
                        onDropdownVisibleChange={() => {
                          this.setState({ workCentersClicked: false });
                        }}
                      >
                        {this.filterOutHidden(this.state.workCenters).map(
                          (item) => {
                            return (
                              <Option
                                style={{ fontSize: "2.6rem" }}
                                key={item.No}
                                value={item.Name}
                                consumption_type={item.consumption_type}
                                pause_type={item.pause_type}
                                non_productive_group={item.non_productive_group}
                                login_supervisor_approval_required={
                                  item.login_supervisor_approval_required
                                }
                                workcenter_no={item.No}
                                subcontractor_no={item.subcontractor_no}
                              ></Option>
                            );
                          }
                        )}
                      </Select>
                    ) : (
                      <div
                        style={{
                          color: "white",
                          fontSize: "2.8rem",
                          fontFamily: `Roboto, sans-serif`,
                          background: "#d32f2f",
                          padding: "0.5rem 1rem",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        className="animate__animated animate__fadeIn"
                      >
                        <p>
                          {this.props.workCenter.workcenter_name === ""
                            ? "Select a Work Station"
                            : this.props.workCenter.workcenter_name}
                        </p>
                      </div>
                    )}
                  </div>
                </Nav>
              )}
            </Navbar.Collapse>

            <div className="d-flex">
              {this.renderUserAvatars(this.props.workCenter.users)}
            </div>

            <div
              className="d-flex justify-content-end align-items-center"
              style={{ gap: "10px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <Button
                      size="lg"
                      type="primary"
                      disabled={
                        this.props.workCenter?.workcenter_name !== ""
                          ? false
                          : true
                      }
                      onClick={() => this.onClick()}
                      onTouchEnd={(e) => {
                        e.preventDefault();
                        this.onClick();
                      }}
                      style={{ fontSize: "3rem" }}
                    >
                      <h1 style={{ color: "white" }}> Login</h1>
                    </Button>
                  </div>
                  <div>
                    <SettingsDrawer
                      items={this.toCheckboxFormat(this.state.workCenters)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5px",
                  }}
                >
                  <p
                    style={{
                      color: "var(--gray-400)",
                      fontSize: "1.3rem",
                    }}
                  >
                    {this.state.date.toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>

              <KeyPad
                visible={this.state.signinVisible}
                onCancel={this.onModalCancle}
                requireSupervisorApproval={
                  this.props.workCenter?.workcenterLoginSupervisorRequired ||
                  false
                }
              />
            </div>
          </Container>
        </Navbar>

        <SupervisorPermissionComponent
          showModal={this.state.showSupervisorPopup}
          type={"Supervisor Approval"}
          message={"Supervisor approval required to sign out without posting"}
          onCancel={() =>
            this.setState({
              showSupervisorPopup: false,
            })
          }
          bgColor="black"
          dialogWidth="60vw"
          onSuccess={() => this.onSuccessHandler()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  deviceConfig: state.deviceConfig,
  workCenter: state.workCenter,
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateDivision: (division) =>
      dispatch(appConfigActions.updateDivision(division)),
    updateWorkCenter: (workCenter) =>
      dispatch(workCenterActions.updateWorkCenter(workCenter)),
    updateUsers: (users) => dispatch(workCenterActions.updateUsers(users)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
