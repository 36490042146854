import axios from "axios";
import { CONST_API_URL } from "../../../appconfig";

export async function updateCutConsumptionService(consumptionObject) {
  try {
    const result = await axios.post(
      `${CONST_API_URL}/consumption/updateCutConsumption`,
      consumptionObject
    );
    return result;
  } catch (error) {
    console.log(error);
  }
}
