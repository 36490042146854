import { useCallback, useEffect, useRef } from "react";

function BarcodeScanner(props) {
  const { onScan, active } = props;
  const keyStack = useRef("");
  const timeout = useRef(null);

  const eventListener = useCallback((event) => {
    //console.log("Here is called");
    const { key } = event;
    let reading = false;
    if (key === "Enter") {
      onScan(keyStack.current);
      keyStack.current = "";
    } else if (key === "Shift") {
    } else {
      keyStack.current += key;
      // console.log("Keystack:", keyStack.current);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        keyStack.current = "";
        reading = false;
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (active) {
      window.addEventListener("keydown", eventListener);
    } else {
      window.removeEventListener("keydown", eventListener);
    }
  }, [active]);

  return <></>;
}

export default BarcodeScanner;
