import { Layout } from "antd";
import React from "react";
import WorkCenterTable from "../../components/admin-page/work-center-table/work-center-table.component";

const { Content } = Layout;

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ background: "black", width: "100%", height: "100%" }}>
        <WorkCenterTable />
      </div>
    );
  }
}

export default AdminPage;
