import React from "react";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./MOSidebarContent.styles.css";
import { connect } from "react-redux";
import config from "../../config";
import { confirmDialog } from "primereact/confirmdialog";
import _ from "lodash";
import { CONST_API_URL } from "../../../appconfig";

const isArrayEqual = (x, y) => {
  return _.isEmpty(_.xorWith(x, y, _.isEqual));
};
class MOSidebarContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MOs: [],
      menuItems: [],
      currentMO: this.props.currentMO,
      slideMenuLoading: true,
      selectedMO: null, //Tracks current selected MO for different styling
      dialogVisible: false,
    };

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleSelectedMOChange = this.handleSelectedMOChange.bind(this);
    this.MOBodyTemplate = this.MOBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.loadMOByWorkCenter(this.props.workcenter);

    this.interval = setInterval(() => {
      this.loadMOByWorkCenter(this.props.workcenter);
    }, 30000);
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    // if (this.props.workcenter !== prevProps.workcenter) {
    if (
      !_.isEqual(
        this.props.workcenter?.workcenter_no,
        prevProps.workcenter?.workcenter_no
      )
    ) {
      this.loadMOByWorkCenter(this.props.workcenter);
      this.setState({ selectedMO: null });
      sessionStorage.setItem("scrollPos", 0);
    }
    if (document.getElementById("MODatatable")) {
      document
        .getElementById("MODatatable")
        .getElementsByClassName("p-datatable-wrapper")[0].scrollTop =
        sessionStorage.getItem("scrollPos");
    }
  }

  handleSelectedMOChange(value) {
    this.setState({ selectedMO: value });
    //Save scroll position
  }

  loadMOByWorkCenter(workcenterObj) {
    const obj = { workcenter: workcenterObj?.workcenter_no };
    axios
      .post(`${CONST_API_URL}/GetMOByWorkCenter`, obj)
      .then((res) => {
        let allMOs = res.data;
        if (res.status === 200) {
          let tempNewMenu = [];
          let newMenu = [];
          for (let x = 0; x < allMOs.length; x++) {
            tempNewMenu.push({
              ...allMOs[x],
              label: allMOs[x].order_no,
              subLabel: allMOs[x].item_no,
              customer_name: allMOs[x].customer_name
                ? allMOs[x].customer_name
                : "TBD",
              hspop_no: allMOs[x].hspop_no ? allMOs[x].hspop_no : "TBD",
              // bom_no: allMOs[x].bom_no ? allMOs[x].bom_no : "TBD",
              component_no_str: allMOs[x].component_no_str
                ? allMOs[x].component_no_str
                : "TBD",
              operationNo: String(allMOs[x].operation_no),
              operationNoChar: String(allMOs[x].operation_no_char),
              routingDescription: String(allMOs[x].routing_description),
            });
          }

          tempNewMenu.forEach(function (item, index) {
            // Check if the same MO exists
            let existing = newMenu.filter(function (v, i) {
              return v.label == item.label;
            });

            if (existing.length > 0) {
              let existingIndex = newMenu.indexOf(existing[0]);

              if (newMenu[existingIndex].operation_no > item.operation_no) {
                newMenu[existingIndex] = {
                  ...newMenu[existingIndex],
                  ...item,
                };
              }

              newMenu[existingIndex].operationData = newMenu[
                existingIndex
              ].operationData.concat({
                operationNo: item.operationNo,
                operationNoChar: item.operationNoChar,
                routingDescription: item.routingDescription,
                quantity: item.quantity,
                part_count: item.part_count,
                hspop_no: item.hspop_no,
                component_no_str: item.component_no_str,
              });
            } else {
              newMenu.push({
                ...item,
                operationData: [
                  {
                    operationNo: item.operationNo,
                    operationNoChar: item.operationNoChar,
                    routingDescription: item.routingDescription,
                    quantity: item.quantity,
                    part_count: item.part_count,
                    hspop_no: item.hspop_no,
                    component_no_str: item.component_no_str,
                  },
                ],
              });
            }
          });

          // Sort operationData
          newMenu = newMenu.map((el) => {
            el.operationData.sort((a, b) =>
              Number(a.operationNo) > Number(b.operationNo) ? 1 : -1
            );
            return el;
          });

          // Set number of menu items to show
          const maxItems = config.moLimit;
          if (maxItems > 0) {
            newMenu = newMenu.slice(0, maxItems);
          }

          if (
            isArrayEqual(newMenu, this.state.menuItems) &&
            newMenu.length > 0
          ) {
          } else {
            //Set the new state of menuItems
            this.setState({ slideMenuLoading: true });
            this.setState({ menuItems: newMenu, slideMenuLoading: false });
          }
        }
      })
      .catch((error) => console.log(`GetMOByWorkCenter Client - ${error}`));
  }

  changeMO(e) {
    this.setState({ currentMO: e.label });

    console.log(e);

    this.props.MOHandler(e.label, e.operationData, e.customer_name);

    this.props.onRevertSupervisorPermission();
  }

  renderHeader() {
    return (
      <div
        style={{
          background: "#212529",
          color: "white",
          padding: "1rem 0",
          height: "100%",
        }}
      >
        <h5
          className="p-m-0"
          style={{
            textAlign: "center",
            fontSize: "2.8rem",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Select MO#
        </h5>
      </div>
    );
  }

  handleSelectionChange = (e) => {
    let scrollPos = document
      .getElementById("MODatatable")
      .getElementsByClassName("p-datatable-wrapper")[0].scrollTop;

    sessionStorage.setItem("scrollPos", scrollPos);

    const selectedValue = e.value;
    if (
      this.state.selectedMO !== selectedValue &&
      this.props.progressStatus === 3 &&
      this.props.workcenterpausetype === 0 &&
      this.props.user?.length > 0
    ) {
      confirmDialog({
        header: <span style={{ fontSize: "2rem" }}>Confirm</span>,
        message: (
          <p style={{ fontSize: "3rem", fontWeight: "500" }}>
            {`Do you want to pause MO ${this.state.currentMO}?`}
          </p>
        ),
        accept: async () => {
          await this.props.passEndJobCode(2);
          this.props.nextMOHandler(e.value);
          //   this.setState({ selectedMO: e.value });
          //  this.changeMO(e.value);
        },
        reject: () => {
          this.setState({ selectedMO: e.value });
          this.changeMO(e.value);
        },
        maskClassName: "zIndex2200",
      });
    } else {
      this.setState({ selectedMO: e.value });
      this.changeMO(e.value);
    }
  };

  MOBodyTemplate(rowData) {
    return (
      <div style={{ width: "100%" }}>
        <h1 style={{ color: "white", fontWeight: "bold" }}>{rowData.label}</h1>
        <h3 style={{ color: "white" }}>{"Item #: " + rowData.subLabel}</h3>
        <h4 style={{ color: "white" }}>{rowData.customer_name}</h4>
      </div>
    );
  }

  render() {
    return (
      <>
        <div
          style={{
            height: "100%",
          }}
        >
          {this.state.slideMenuLoading ? (
            <div style={{ height: "100%", width: "100%" }}>
              {this.props.workcenter.workcenter_name !== "" ? (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                  }}
                >
                  <ProgressSpinner
                    style={{ width: "100px", height: "100px" }}
                    strokeWidth="8"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
              ) : (
                <h1 style={{ color: "red" }}>
                  CHOOSE A WORK CENTER TO CONTINUE
                </h1>
              )}
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {this.renderHeader()}
              <DataTable
                id="MODatatable"
                responsiveLayout="scroll"
                selection={this.state.selectedMO}
                value={this.state.menuItems}
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: "2.6rem",
                  fontWeight: "bold",
                }}
                scrollable
                scrollHeight={"75vh"}
                selectionMode="single"
                dataKey="label"
                onSelectionChange={(e) => {
                  this.handleSelectionChange(e);
                }}
              >
                <Column
                  body={this.MOBodyTemplate}
                  style={{
                    textAlign: "center",
                  }}
                ></Column>
              </DataTable>
              <h1
                style={{
                  fontSize: "1em",
                  textAlign: "center",
                  marginTop: "0.4em",
                  fontWeight: "bold",
                  opacity: "0.7",
                }}
              >
                V{this.props.version}
              </h1>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.users,
  workcenter: state.workCenter,
  workcenterpausetype: state.workCenter.workcenterpausetype,
  version: state.appConfig.version,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  MOSidebarContent
);
