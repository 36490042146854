import React from "react";
import "./datagrid.css";
import {
  Input,
  Modal,
  Form,
  Button,
  Descriptions,
  Collapse,
  Alert,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import Success from "../../../assets/success.png";
import Error from "../../../assets/error.png";
import { CONST_API_URL, DEV_MODE } from "../../../appconfig";
const { TextArea } = Input;
const { Panel } = Collapse;
class POCErrorModal extends React.Component {
  formRef = React.createRef();
  state = {
    isLoading: false,
    emailModalVisible: false,
    emailSubject: "",
    emailBody: "",
    keyEmail1: 0,
    isSending: false,
    helpdeskemail: DEV_MODE
      ? "jilee@alpacasys.com"
      : "chrisbrown@havensteel.com",
  };

  handleCloseModalMessage = () => {
    this.props.onCancel();
  };

  showModalEmail = () => {
    this.setState({ isSending: true });
    let emailbody =
      "User Name : " +
      this.props.data.userFullName +
      "\r" +
      "MO No : " +
      this.props.data.MO_No +
      "\r" +
      "Part Number : " +
      this.props.data.PartNumber +
      " , " +
      "Part Description : " +
      this.props.data.PartDescription +
      "\r" +
      "Item No: " +
      this.props.data.CompItemNo +
      " , " +
      "Heat Number: " +
      this.props.data.LotNo +
      ", " +
      "Total Quantity : " +
      this.props.data.TotalQty +
      ", " +
      "Cut Length: " +
      this.props.data.CutLen +
      ", " +
      "Cut Qty: " +
      this.props.data.CutQty +
      ", " +
      "Scrap Qty: " +
      this.props.data.ScrapQty +
      "\r" +
      //Batch error implementation
      // this.unpackErrors(this.props.data.errorMessages);
      "Error : " +
      this.props.data.errorMessage +
      "\r\r\r" +
      "Thank You" +
      "\r" +
      this.props.data.userFullName;
    const sub =
      "ERROR: Haven-consumption, MO:" + this.props.data.MO_No + ", Post failed";
    this.props.setEmailSent(0);
    this.props.setEmailSuccessful(0);
    this.props.setErrorModalVisible(false);
    this.setState({
      emailBody: emailbody,
      emailSubject: sub,
      keyEmail1: 1,
    });
    const emailObject = {
      Recipient: this.state.helpdeskemail,
      Subject: sub,
      Message: emailbody.replace(/[\r\n]/g, "<br/>"),
    };
    this.sendEmail(emailObject);
  };

  handleCloseModalEmail = (e) => {
    this.setState({ keyEmail1: 0, emailModalVisible: false });
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  };

  onReset = (e) => {
    this.formRef.current.resetFields();
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  };

  onFinish = (values) => {
    var Message = values["Message"].replace(/[\r\n]/g, "<br/>");
    values = { ...values, Message };
    this.sendEmail(values);
  };

  sendEmail = (values) => {
    this.props.setEmailSent(0);
    this.setState({ isSending: true }, () => {
      axios
        .post(`${CONST_API_URL}/consumption/sendEmail`, values)
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({
              isSending: false,
            });
            this.props.setEmailSent(1);
            this.props.setEmailSuccessful(0);
            //this.formRef.current.resetFields();
            this.handleCloseModalEmail();
          } else {
            this.setState({
              isSending: false,
            });
            this.props.setEmailSent(1);
            this.props.setEmailSuccessful(1);
          }
        });
    });
  };

  //Batch error implementation

  // unpackErrors = (array) => {
  //   var errorString = "";
  //   if (array) {
  //     for (var i = 0; i < array.length; i++) {
  //       errorString += array[i].msg + "\n\n";
  //     }
  //   }
  //   console.log(errorString);
  //   return errorString;
  // };
  // unpackErrorsHTML = (array) => {
  //   return (
  //     <>
  //       {array
  //         ? array.map((el, index) => {
  //             return (
  //               <div style={{ marginBottom: "10px" }}>
  //                 <Alert
  //                   message={`Lot#: ${el.lot}`}
  //                   description={el.msg}
  //                   type="error"
  //                 />
  //               </div>
  //             );
  //           })
  //         : ""}
  //     </>
  //   );
  // };

  render() {
    return (
      <>
        <Modal
          centered
          destroyOnClose
          //zIndex={2010}
          mask
          visible={this.props.visible}
          okText="Email Error"
          onOk={this.showModalEmail}
          onCancel={this.handleCloseModalMessage}
          maskClosable={false}
          width={window.innerWidth * 0.8}
          okButtonProps={{
            style: {
              fontSize: "2rem",
              height: "fit-content",
              background: "#ff4d4f",
              border: "none",
            },
          }}
          cancelButtonProps={{
            style: { fontSize: "2rem", height: "fit-content" },
          }}
        >
          <div style={{ padding: "2rem" }}>
            <Descriptions
              title={
                <div style={{ display: "inline-flex", gap: "5px" }}>
                  <h2>
                    <ExclamationCircleOutlined style={{ color: "red" }} />
                  </h2>
                  <h1 style={{ fontWeight: "bold" }}> Error</h1>
                </div>
              }
              colon={false}
              bordered
            >
              {/* <Descriptions.Item
                label={<h1 style={{ fontWeight: "bold" }}>MO</h1>}
              >
                <h1>{this.props.data.MO_No}</h1>
              </Descriptions.Item>
              <Descriptions.Item
                label={<h1 style={{ fontWeight: "bold" }}>Part</h1>}
              >
                <h1>{this.props.data.PartNumber}</h1>
              </Descriptions.Item>
              <Descriptions.Item
                label={<h1 style={{ fontWeight: "bold" }}>Item</h1>}
              >
                <h1>{this.props.data.ComponentItemNo}</h1>
              </Descriptions.Item> */}
              <Descriptions.Item>
                <h1>An error has occurred while posting to NAV.</h1>
                <h1>Please email troubleshooting for further assistance.</h1>
                {this.props.pFooter && <>{this.props.pFooter}</>}
              </Descriptions.Item>
            </Descriptions>
            <Collapse>
              <Panel header="Error Message(s)" key="1">
                {/*Batch Error implementation */}
                {/* <p>{this.unpackErrorsHTML(this.props.data.errorMessages)}</p> */}
                <p>{this.props.data.errorMessage}</p>
              </Panel>
            </Collapse>
            {/* <table width="90%">
              <tbody>
                <tr>
                  <td width="25%">
                    <b style={{ fontSize: "2em" }}>MO:</b>
                  </td>
                  <td width="75%">{this.props.data.MO_No}</td>
                </tr>
                <tr>
                  <td width="25%">
                    <b style={{ fontSize: "2em" }}> Part:</b>
                  </td>
                  <td width="75%">{this.props.data.PartNumber} </td>
                </tr>
                <tr>
                  <td width="25%">
                    <b style={{ fontSize: "2em" }}> Item:</b>
                  </td>
                  <td width="75%">{this.props.data.ComponentItemNo} </td>
                </tr>
                <tr>
                  <td width="25%">
                    <b style={{ fontSize: "2em" }}>Error:</b>
                  </td>
                  <td width="75%"> {this.props.data.errorMessage}</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </Modal>
        <Modal
          key={this.state.keyEmail1}
          centered
          className="fullModal"
          visible={this.state.emailModalVisible}
          width="80vw"
          maskClosable={false}
          bodyStyle={{ fontSize: "14px", height: "90%" }}
          onCancel={this.handleCloseModalEmail}
          footer={null}
        >
          <div>
            <span>
              {this.props.emailSent === 0 ? (
                ""
              ) : this.props.emailSentSuccessful === 0 ? (
                <div>
                  <img
                    src={Success}
                    alt="Succuess"
                    height="20px"
                    width="20px"
                  />{" "}
                  <b>Email Sent Successfully..</b>
                </div>
              ) : (
                <div>
                  <img src={Error} alt="Error" height="20px" width="20px" />
                  <b> Email Not Sent! TRY Again..</b>
                </div>
              )}
            </span>

            <Form
              ref={this.formRef}
              name="control-ref"
              layout="vertical"
              initialValues={{
                Recipient: this.state.helpdeskemail,
                Subject: this.state.emailSubject,
                Message: this.state.emailBody,
              }}
              onFinish={this.onFinish}
            >
              <Form.Item
                name="Recipient"
                label="To"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="Cc" label="Cc">
                <Input />
              </Form.Item>
              <Form.Item name="Bcc" label="Bcc">
                <Input />
              </Form.Item>
              <Form.Item name="Subject" label="Subject">
                <Input disabled />
              </Form.Item>
              <Form.Item name="Message" label="Message">
                <TextArea
                  placeholder="textarea with clear icon"
                  rows={25}
                  cols={15}
                />
              </Form.Item>

              <Form.Item style={{ float: "right" }}>
                <Button
                  type="primary"
                  className="m-2"
                  htmlType="submit"
                  loading={this.state.isSending ? true : false}
                >
                  {this.state.isSending ? "Sending" : "Send Email"}
                </Button>
                <Button
                  htmlType="button"
                  className="m-2"
                  disabled={this.state.isSending ? true : false}
                  onClick={this.onReset}
                  onTouchEnd={this.onReset}
                >
                  Reset
                </Button>
                <Button
                  htmlType="button"
                  className="m-2"
                  disabled={this.state.isSending ? true : false}
                  onClick={this.handleCloseModalEmail}
                  onTouchend={this.handleCloseModalEmail}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}

export default POCErrorModal;
