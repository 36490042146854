import React from "react";
import {
  Form,
  Button,
  Modal,
  Divider,
  Input,
  Select,
  notification,
  Descriptions,
  Spin,
} from "antd";
const { Option } = Select;
import config from "../../../config";
import InputNumPad from "../../../formElements/input-numpad/input-numpad.component";
import "../consumptionTable.styles.css";
import BarcodeScanner from "../barcode/barcodescanner.component";

class ConsumptionSecondStep extends React.Component {
  state = {
    heatnoVisible: false,
    lotNo: "",
    location: "",
    quantity: 0,
    options: [],
    inputQty:
      Math.round(
        (Number(
          this.props.quantityPer *
            this.props.inputPartCount *
            (1 + this.props.componentScrapPercent / 100)
        ) +
          Number.EPSILON) *
          100000
      ) / 100000,
    scrapQty: 0,
    totalQty:
      Math.round(
        (Number(
          this.props.quantityPer *
            this.props.inputPartCount *
            (1 + this.props.componentScrapPercent / 100)
        ) +
          Number.EPSILON) *
          100000
      ) / 100000,
    palletNumber: "",
    displayedLotNo: "",
    displayedLotLocation: "",
    displayedQuantity: 0,
    heatRadioButtonBoolean: true,
    loading: false,
    uoms: [],
    selectedUOM: "HOPPER",
  };

  onClickOpenHeatModal = async (e) => {
    this.setState({ heatnoVisible: true });
  };

  handleRadioChange = (e) => {
    this.setState({ heatRadioButtonBoolean: e.target.value });
  };

  handleChange = (value, allValue) => {
    const selectedLot = allValue.wholeobject;
    this.setState({
      lotNo: selectedLot.value,
      location: selectedLot.location_code,
      quantity: selectedLot.total_available_quantity,
    });
  };

  onClickSave = (e) => {
    this.setState({ displayedLotNo: this.state.lotNo });
    this.setState({ displayedLotLocation: this.state.location });
    this.setState({ displayedQuantity: this.state.quantity });
    this.setState({ heatnoVisible: false });
  };
  onClickCancel = (e) => {
    this.setState({ heatnoVisible: false });
  };

  //Render heat selector
  renderHeatContent = () => {
    return (
      <>
        <div style={{ padding: "1rem" }}>
          <Form name="scrapForm" size={"large"} colon={false}>
            <Descriptions bordered={true} colon={false} column={1}>
              {this.state.heatRadioButtonBoolean === true ? (
                <Descriptions.Item
                  label={<h2>Choose LOT</h2>}
                  labelStyle={{ width: "30%" }}
                >
                  <Form.Item style={{ margin: 0 }} name={"heatnum"}>
                    <Select
                      labelInValue
                      onChange={this.handleChange}
                      style={{ width: "100%" }}
                    >
                      {this.props.lotInfo.map((item, index) => {
                        return (
                          <Option key={index} wholeobject={item}>
                            <h2 style={{ marginTop: "10px" }}>
                              {item.value + "-" + item.location_code + " "}
                              <span style={{ opacity: "0.7" }}>
                                {`(Qty: ${
                                  item.total_available_quantity ||
                                  this.state.quantity
                                })`}
                              </span>
                            </h2>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
              ) : (
                <Descriptions.Item label={<h2>Barcode</h2>} style={{}}>
                  <Form.Item style={{ margin: 0 }} name={"heatnum"}>
                    <Input
                      labelInValue
                      onChange={this.handleChange}
                      style={{ width: "100%" }}
                    ></Input>
                  </Form.Item>
                </Descriptions.Item>
              )}
              <Descriptions.Item
                labelStyle={{ width: "30%" }}
                label={<h2>Lot#</h2>}
              >
                <Form.Item name={"avaiqty"}>
                  <h2 style={{ marginLeft: "15px", marginTop: "10px" }}>
                    {!!this.state.lotNo ? this.state.lotNo : ""}
                    {/* {this.state.lotNo === "undefined" ? "" : this.state.lotNo} */}
                  </h2>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: "30%" }}
                label={<h2>Available Qty</h2>}
              >
                <Form.Item name={"avaiqty"}>
                  <h2 style={{ marginLeft: "15px", marginTop: "10px" }}>
                    {this.state.quantity}
                  </h2>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: "30%" }}
                label={<h2>Lot Location</h2>}
              >
                <Form.Item name={"selectedlot"}>
                  <h2 style={{ marginLeft: "15px", marginTop: "10px" }}>
                    {this.state.location}
                  </h2>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Form>
        </div>
      </>
    );
  };

  onFormClickSave = () => {
    console.log("onFormClickSave");
    if (
      this.state.quantity < this.state.inputQty &&
      !!this.state.displayedLotNo
    ) {
      notification.error({
        message: "Error",
        description: "Your input Quantity is larger than the quantity in lot",
        duration: 5,
        style: {
          width: "700px",
        },
      });
    } else if (this.props.lotMandatory && !!!this.state.displayedLotNo) {
      notification.error({
        message: "Error ",
        description: "You need to select a lot number",
        duration: 5,
        style: {
          width: "700px",
        },
      });
    } else if (this.state.totalQty === 0) {
      notification.error({
        message: "Error ",
        description: "You need consume at least one ",
        duration: 5,
        style: {
          width: "700px",
        },
      });
    } else {
      const postQuantity = {
        palletNumber: this.state.palletNumber,
        ComponentItemNo: this.props.name,
        InputQuantity: this.state.inputQty,
        ScrapQuantity: this.state.scrapQty,
        TotalQuantity: this.state.totalQty,
        SelectedUOM: this.state.selectedUOM,
        Location: !!this.state.displayedLotLocation
          ? this.state.displayedLotLocation
          : "",
        LotNumber: !!this.state.displayedLotNo ? this.state.displayedLotNo : "",
      };
      console.log("Posting", postQuantity);
      notification.success({
        message: "Success",
        description: "The component was added successfully",
        duration: 5,
        style: {
          width: "700px",
        },
      });
      this.props.addToTable(postQuantity);
      this.props.sendBack(postQuantity, this.props.index);
    }
  };

  onFormClickCancel = () => {
    console.log("Cancelled");
    this.props.cancelStepForm();
  };

  modalTitleHeat = () => {
    return <h1 style={{ fontWeight: "bold" }}>Select Lot Number</h1>;
  };

  //Return the footer of the modal
  modalFooter = () => {
    return [
      <Button
        key="ModalCancelButton"
        type="primary"
        style={{
          fontSize: "2.5rem",
          height: "fit-content",
          backgroundColor: config.branding.primaryColor,
          borderColor: config.branding.primaryColor,
        }}
        onClick={(e) => this.onClickCancel(e)}
        onTouchEnd={(e) => this.onClickCancel(e)}
      >
        Cancel{" "}
      </Button>,
      <Button
        key="ModalSaveButton"
        type="primary"
        style={{
          fontSize: "2.5rem",
          height: "fit-content",
          backgroundColor: config.branding.primaryColor,
          borderColor: config.branding.primaryColor,
        }}
        onClick={(e) => this.onClickSave(e)}
        onTouchEnd={(e) => this.onClickSave(e)}
      >
        {" "}
        Save{" "}
      </Button>,
    ];
  };

  onScan = (barcode) => {
    console.log("onScan", barcode);
    let tempLotInfo = this.props.lotInfo;
    let tempLotInfofilter = tempLotInfo.filter(function (lot) {
      return lot.label == barcode;
    });
    if (tempLotInfofilter.length > 0) {
      this.setState({
        lotNo: tempLotInfofilter[0].label,
        location: tempLotInfofilter[0].location_code,
        quantity: tempLotInfofilter[0].total_available_quantity,
      });
    } else {
      //no match
      notification.error({
        message: "Error",
        description:
          "Your input barcode does not match any barcodes in the system",
        duration: 5,
        style: {
          width: "700px",
        },
      });
    }
  };

  FormFooter = () => {
    return (
      <div style={{ float: "right", marginTop: "10px" }}>
        {" "}
        <Button
          key="ModalCancelButton"
          type="primary"
          style={{
            fontSize: "2.5rem",
            height: "fit-content",
            backgroundColor: config.branding.primaryColor,
            borderColor: config.branding.primaryColor,
            marginRight: "10px",
          }}
          onClick={(e) => this.onFormClickCancel(e)}
          onTouchEnd={(e) => this.onFormClickCancel(e)}
        >
          {" "}
          Back{" "}
        </Button>
        <Button
          key="ModalSaveButton"
          type="primary"
          style={{
            fontSize: "2.5rem",
            height: "fit-content",
            backgroundColor: config.branding.primaryColor,
            borderColor: config.branding.primaryColor,
          }}
          onClick={(e) => this.onFormClickSave()}
          onTouchEnd={(e) => {
            e.preventDefault();
            this.onFormClickSave();
          }}
          width={window.innerWidth > 1280 ? "80vw" : "90vw"}
        >
          {" "}
          Save{" "}
        </Button>
      </div>
    );
  };

  handleOnChange = (e) => {
    console.log("Handling change: ", e);
    var realInputQty = e.inputQty ? e.inputQty : this.state.inputQty;
    var realScrapQty = e.scrapQty ? e.scrapQty : this.state.scrapQty;
    var tempPalletNumber = e.palletNumber
      ? e.palletNumber
      : this.state.palletNumber;
    var totalQty = Number(realInputQty) + Number(realScrapQty);
    var realUOM = e.uom ? e.uom : this.state.selectedUOM;
    this.setState({
      inputQty: realInputQty,
      scrapQty: realScrapQty,
      totalQty: totalQty,
      palletNumber: String(tempPalletNumber),
      selectedUOM: realUOM,
    });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <div className="modal-container">
            <Spin size="large" tip="Loading"></Spin>
          </div>
        ) : (
          <>
            <BarcodeScanner
              onScan={this.onScan}
              active={this.state.heatnoVisible}
            />
            <h1
              className="animate__animated animate__fadeIn"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                background: "#fafafa",
                padding: "10px",
                border: "1px solid #f0f0f0",
                background: "#1e1e1e",
                color: "white",
              }}
            >
              Step 2: Input Quantity
            </h1>
            <Divider />
            <Form
              className="animate__animated animate__fadeIn"
              initialValues={{
                name1: this.props.name,
                // uom: "HOPPER",
                inputQty:
                  Math.round(
                    (Number(
                      this.props.quantityPer *
                        this.props.inputPartCount *
                        (1 + this.props.componentScrapPercent / 100)
                    ) +
                      Number.EPSILON) *
                      100000
                  ) / 100000,
                scrapQty: 0,
                totalQty: 0,
              }}
              onValuesChange={(e) => this.handleOnChange(e)}
              colon={false}
            >
              <Modal
                title={this.modalTitleHeat()}
                visible={this.state.heatnoVisible}
                width="90vw"
                style={{ maxHeight: "100%" }}
                onCancel={() => {
                  this.setState({ heatnoVisible: false });
                }}
                getContainer={false}
                centered
                forceRender={true}
                footer={this.modalFooter()}
              >
                {this.renderHeatContent()}
              </Modal>

              <Form.Item
                label={
                  <h1
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      background: "#fafafa",
                      padding: "10px",
                      border: "1px solid #f0f0f0",
                    }}
                  >
                    {this.props.name} | Unit: {this.props.unit} | Qty Per:{" "}
                    {this.props.quantityPer} |
                  </h1>
                }
                name={this.props.name}
                key={this.props.name}
              ></Form.Item>

              {this.props.lotMandatory ? (
                <Button
                  className="button-glow"
                  key="ModalCancelButton1"
                  style={{
                    fontSize: "2rem",
                    width: "100%",
                    height: "fit-content",
                    backgroundColor: config.branding.primaryColor,
                    borderColor: config.branding.primaryColor,
                    marginBottom: "10px",
                  }}
                  onClick={(e) => this.onClickOpenHeatModal(e)}
                  onTouchEnd={(e) => this.onClickOpenHeatModal(e)}
                  type="primary"
                >
                  {!!this.state.displayedLotNo ? (
                    <div>
                      Selected Lot#: {this.state.displayedLotNo}
                      <br></br>
                      Location: {this.state.displayedLotLocation}
                    </div>
                  ) : (
                    <div>
                      <span>Select Lot Number</span>
                    </div>
                  )}
                </Button>
              ) : (
                ""
              )}
              <Descriptions bordered={true} colon={false} column={1}>
                <Descriptions.Item label={<h3>Consumed Quantity</h3>}>
                  <Form.Item name={"inputQty"} style={{ margin: 0 }}>
                    <InputNumPad allowDecimals={true} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<h3>Scrap Quantity</h3>}>
                  <Form.Item name={"scrapQty"} style={{ margin: 0 }}>
                    <InputNumPad allowDecimals={true} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<h3>Total Quantity</h3>}>
                  <h3 style={{ fontSize: "2.4rem", marginLeft: "8px" }}>
                    {Number(this.state.totalQty)}
                  </h3>
                </Descriptions.Item>
              </Descriptions>

              {this.FormFooter()}
            </Form>
          </>
        )}
      </>
    );
  }
}

export default ConsumptionSecondStep;
