import axios from "axios";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { CONST_API_URL } from "../../../appconfig";

const binarytoBlob = (bytes) => {
  let out = Uint8Array.from(bytes);
  return new Blob([out], { type: "application/pdf" });
};

const DrawingDialog = (props) => {
  const { visible, onHide, title, customerCode, itemNo } = props;
  const [PDFData, setPDFData] = useState(null);
  const [PDFDataLoading, setPDFDataLoading] = useState(false);
  const [blobURL, setBlobURL] = useState(null);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    async function fetchPDF(customerCode, itemNo) {
      setPDFDataLoading(true);

      const response = await axios
        .post(
          `${CONST_API_URL}/getDrawing`,
          {
            customerCode,
            itemNo,
          },
          { cancelToken: source.token }
        )
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("get drawing call is canceled");
          } else {
            console.log("ERROR - get Drawing", error);
          }
        });
      if (response.data.binarypdf?.data) {
        // make random url with current base 64 data
        const url = window.URL.createObjectURL(
          binarytoBlob(response.data.binarypdf.data)
        );
        console.log("TEST:", props.customerCode, props.itemNo);
        setBlobURL(url);
      } else {
        //No PDF Drawing found
        setBlobURL(null);
      }
      setPDFDataLoading(false);
    }

    if (visible && customerCode && itemNo && !blobURL) {
      fetchPDF(customerCode, itemNo);
    }
  }, [visible, customerCode, itemNo]);

  useEffect(() => {
    // Reset existing axios API call
    console.log("Customer Code or item No has been changed");
    source.cancel("Customer Code or item No has been changed");
    // Reset PDF Data whenever either customerCode or itemNo is changed.
    setBlobURL(null);
  }, [customerCode, itemNo]);

  return (
    <Dialog
      visible={visible}
      style={{ width: "90vw", height: "90vh" }}
      onHide={onHide}
      dismissableMask={true}
      blockScroll={true}
      header={
        <h1 style={{ fontSize: "2em", color: "#d32f2f", fontWeight: "bold" }}>
          {title}
        </h1>
      }
    >
      <div style={{ width: "100%", height: "100%" }}>
        {PDFDataLoading ? (
          <Spin spinning={true} style={{ width: "100%" }} />
        ) : (
          <>
            {blobURL ? (
              <embed src={blobURL} width="100%" height="100%"></embed>
            ) : (
              <p
                style={{
                  fontSize: "2em",
                  color: "#d32f2f",
                }}
              >
                No PDF Drawing found
              </p>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default DrawingDialog;
