import { Table, Divider } from "antd";

import "../lot-selector.styles.css";

const { Column } = Table;

const LotSelectorPageTwo = (props) => {
  function filterBlocked(data, blockList) {
    const filtered = [];

    for (var i = 0; i < data.length; i++) {
      if (
        blockList.some(
          (e) =>
            e.lotNumber === data[i].lotNumber && e.location === data[i].location
        )
      ) {
        //Do nothing
      } else {
        filtered.push(data[i]);
      }
    }

    return filtered;
  }

  function changeSelectedLocations(entry, targetQuantity) {
    if (!props.selectedLocations.includes(entry)) {
      //Check if exceeding target quantity and location quantity needs to be adjusted
      if (getTotalQuantity() + entry.quantity > targetQuantity) {
        var newQuantity = targetQuantity - getTotalQuantity();

        entry.originalQuantity = entry.quantity;
        entry.quantity = newQuantity;
      }

      props.setSelectedLocations([...props.selectedLocations, entry]);

      //Enable ability to go to next page
      props.setNextDisabled(false);
    } else {
      var index = props.selectedLocations.findIndex(
        (x) => x.location === entry.location
      );

      //Reset quantity to original when option is de-selected
      entry.quantity = entry.originalQuantity;

      console.log(index);
      var clone = [...props.selectedLocations];

      //Disable ability to go to next page
      if (clone.length === 1) props.setNextDisabled(true);

      clone.splice(index, 1);
      props.setSelectedLocations(clone);
    }
  }

  function getTotalQuantity() {
    var total = 0;
    for (var i = 0; i < props.selectedLocations.length; i++) {
      total += props.selectedLocations[i].quantity;
    }
    return total;
  }

  function getTotalQuantityColour(totalQuantity, targetQuantity) {
    var colour = "black";

    if (totalQuantity < targetQuantity) {
      colour = "#ffd04d";
    } else {
      colour = "#4dff56";
    }

    return colour;
  }

  function getRemainingQuantityColour(totalQuantity, targetQuantity) {
    if (targetQuantity - totalQuantity > 0) {
      return "#ffd04d";
    } else {
      return "#4dff56";
    }
  }

  function getRowSelectClass(totalQuantity, targetQuantity) {
    if (totalQuantity < targetQuantity) {
      return "row__select--yellow";
    } else {
      return "row__select--green";
    }
  }

  function renderQuantityRemaining(record, selectedLocations) {
    if (selectedLocations.filter((e) => e.id === record.id).length > 0) {
      return (record.originalQuantity - record.quantity).toFixed(5);
    } else {
      return "-";
    }
  }

  function renderQuantityRequested(record, selectedLocations) {
    if (selectedLocations.filter((e) => e.id === record.id).length > 0) {
      return record.quantity.toFixed(5);
    } else {
      return "-";
    }
  }

  //Main Render
  return (
    <div>
      {props.lotNumber !== "" ? (
        <div
          className="select__location animate__animated animate__fadeIn"
          style={{ margin: "20px" }}
        >
          <Table
            pagination={false}
            dataSource={filterBlocked(
              props.locationSelections,
              props.selectedLots
            )}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  changeSelectedLocations(record, props.targetQuantity);
                }, // click row
              };
            }}
            style={{ minHeight: "300px" }}
            rowClassName={(record, index) =>
              props.selectedLocations.includes(record)
                ? getRowSelectClass(getTotalQuantity(), props.targetQuantity)
                : "row__deselect"
            }
          >
            <Column
              width="20px"
              render={(value, record) => {
                return (
                  <h1>
                    {props.selectedLocations.includes(record) ? (
                      <i
                        className="pi pi-check-square animate__animated animate__fadeIn"
                        style={{ fontSize: "32px" }}
                      ></i>
                    ) : (
                      <i
                        className="pi pi-stop animate__animated animate__fadeIn"
                        style={{ fontSize: "32px" }}
                      ></i>
                    )}
                  </h1>
                );
              }}
            ></Column>
            <Column
              title={<h2 style={{ fontWeight: "700" }}>Location Code</h2>}
              dataIndex="location"
              key="location"
              render={(value) => {
                return <h2>{value}</h2>;
              }}
            ></Column>
            <Column
              title={
                <h2 style={{ fontWeight: "700" }}>Quantity at Location</h2>
              }
              dataIndex="quantity"
              key="quantity"
              render={(value, record) => {
                return <h2>{record.originalQuantity.toFixed(5)}</h2>;
              }}
            ></Column>
            <Column
              title={<h2 style={{ fontWeight: "700" }}>Quantity Requested</h2>}
              dataIndex="quantityRequested"
              key="quantityRequested"
              render={(value, record) => {
                return (
                  <h2>
                    {renderQuantityRequested(record, props.selectedLocations)}
                  </h2>
                );
              }}
            ></Column>
            <Column
              title={<h2 style={{ fontWeight: "700" }}>Quantity Remaining</h2>}
              dataIndex="quantityRemaining"
              key="quantityRemaining"
              render={(value, record) => {
                return (
                  <h2>
                    {renderQuantityRemaining(record, props.selectedLocations)}
                  </h2>
                );
              }}
            ></Column>
          </Table>

          <div style={{ marginTop: "20px" }}>
            <h1 className="animate__animated animate__fadeIn">
              Target quantity: {props.targetQuantity.toFixed(5)}
            </h1>
            <h1>
              Current total from location(s):{" "}
              <span
                key={getTotalQuantity()}
                className="animate__animated animate__fadeIn"
                style={{
                  color: getTotalQuantityColour(
                    getTotalQuantity(),
                    props.targetQuantity
                  ),
                }}
              >
                {getTotalQuantity().toFixed(5)}
              </span>
            </h1>
            <h1>
              Remaining target quantity:{" "}
              <span
                key={getTotalQuantity()}
                className="animate__animated animate__fadeIn"
                style={{
                  color: getRemainingQuantityColour(
                    getTotalQuantity(),
                    props.targetQuantity
                  ),
                }}
              >
                {props.targetQuantity - getTotalQuantity() >= 0
                  ? (props.targetQuantity - getTotalQuantity()).toFixed(5)
                  : 0}
              </span>
            </h1>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LotSelectorPageTwo;
