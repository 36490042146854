import React from "react";
import moment from "moment";
import { Button } from "primereact/button";
import {
  GetProgressStatusAndProgressTimeService,
  UpdateProgressStatusAndTimeService,
  updateInitialStartTime,
  updateInitialSetupStartTime,
  UpdateProgressTimeService,
  updatePause,
  updatePauseEnd,
  pauseAllOperations,
} from "./JobTimer.service";
import "./JobTimer.styles.css";

import { connect } from "react-redux";

class JobTimer extends React.Component {
  constructor(props) {
    super(props);
    this.timerController = new Map([
      [0, { status: "Start Setup", operation: this.startSetup }],
      // [1, { status: "End Setup", operation: this.endSetup}],
      [1, { status: "Pause Setup", operation: this.pauseSetup }],
      [
        2,
        {
          status: "Start Job",
          operation: () => {
            if (!this.state.buttonDisabled) {
              this.props.requestSupervisorApproval();
            }
          },
        },
      ],
      [3, { status: "Pause Job", operation: this.pauseJob }],
      [4, { status: "Resume Job", operation: this.resumeJob }],
      [5, { status: "Job done", operation: this.finishJob }],
      [
        6,
        {
          status: "Resume Setup",
          operation: this.resumeSetup,
        },
      ],
    ]);

    this.state = {
      timerStatus: 0, // value must be between 0 and 4
      startTimer: null,
      endTimer: null,
      jobId: null,
      timeElapsed: 0,
      buttonDisabled: false,
      currentMO: "",
      currentOperationNo: "",
      buttonVisible: true,
    };

    this.timer = null;
    this.timerForUpdate = null;
    this.renderTimer = this.renderTimer.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.updateTimerStatusAndTime = this.updateTimerStatusAndTime.bind(this);
    this.handlePauseState = this.handlePauseState.bind(this);
  }

  componentWillUnmount() {
    this.clearAllTimers();
  }

  componentDidMount() {}

  clearAllTimers() {
    clearInterval(this.timer);
    clearInterval(this.timerForUpdate);
    this.timer = null;
    this.timerForUpdate = null;
  }

  receiveSupervisorApproval = (supervisorApproved) => {
    this.startJob();
  };

  componentDidUpdate(prevProps, prevStates) {
    const { supervisorApproved } = this.props;
    if (
      supervisorApproved !== prevProps.supervisorApproved &&
      supervisorApproved === true
    ) {
      this.receiveSupervisorApproval(supervisorApproved);
      this.props.resetSupervisorApproved();
    }

    // if (
    //   this.props.finishJobTimerFlag !== prevProps.finishJobTimerFlag &&
    //   this.props.finishJobTimerFlag === true
    // ) {
    //   this.props.passFinishJobTimerFlag(false);
    //   this.finishJob();
    // }

    if (this.props.endJobCode !== 0) {
      this.props.passEndJobCode(0); // reset the code to 0
      if (this.props.endJobCode === 2 && this.state.timerStatus === 3) {
        this.pauseJob();
      }
    }

    if (
      prevProps.MO !== this.props.MO ||
      prevProps.operation !== this.props.operation
    ) {
      this.setState({ timeElapsed: 0, timerStatus: 0 }, () =>
        this.props.passProgressStatus(this.state.timerStatus)
      );
      this.clearAllTimers();
      this.setState({
        currentOperationNo: this.props.operation,
        currentMO: this.props.MO,
      });
      if (this.props.MO && this.props.operation) {
        GetProgressStatusAndProgressTimeService(
          this.props.MO,
          this.props.operation
        ).then((res) => {
          if (res.data[0]) {
            this.updateTimerStatusAndTime(res.data[0]);
          }
        });
      }
    }

    // --- when all users logged out from the current work center, all timers in the work center will be paused and need to reload current timer ---
    if (
      prevProps.workcenter?.workcenter_no ===
        this.props.workcenter?.workcenter_no &&
      prevProps.workcenter?.users.length > 0 &&
      this.props.workcenter?.users.length === 0
    ) {
      if (this.props.MO && this.props.operation) {
        GetProgressStatusAndProgressTimeService(
          this.props.MO,
          this.props.operation
        ).then((res) => {
          if (res.data[0]) {
            this.updateTimerStatusAndTime(res.data[0]);
          }
        });
      }
    }

    if (this.state.timerStatus !== prevStates.timerStatus) {
      this.props.passProgressStatus(this.state.timerStatus);
      if (this.state.timerStatus === 3) {
        this.props.blockedUIHandler(false);
      } else {
        this.props.blockedUIHandler(true);
      }
    }
  }

  /*
   *
   * Table: <Dev_Dev_Scheduling_Items>
   * Column: [progress_status], [progress_setup_time], [progress_run_time]
   * Status Code:  0/NULL - Awaiting
   *               1 - Setup in progress
   *               2 - Setup done
   *               3 - Job in progress
   *               4 - Job paused
   *               5 - Job done (should not be - moving on to next operation with status code 0 when the job is done)
   */
  updateTimerStatusAndTime(data) {
    let status = data.progress_status;
    let runTime = Number.isNaN(data.progress_run_time)
      ? 0
      : data.progress_run_time;
    let runSetupTime = Number.isNaN(data.progress_run_time)
      ? 0
      : data.progress_setup_time;
    let progressStartTime = moment.utc(data.progress_start_time);
    let currentUtcTime = moment.utc();
    let gapTime_ms = currentUtcTime.diff(progressStartTime, "milliseconds");
    if (status === 0) {
      this.setState({ buttonVisible: true });
      // this.props.blockedUIHandler(true);
    } else if (status === 1) {
      this.setState(
        {
          timerStatus: 1,
          startTimer: moment().format("YYYY-MM-DD HH:mm:ss:SSS"),
          timeElapsed: runSetupTime + gapTime_ms,
          buttonVisible: true,
        },
        () => {
          this.updateTimer("progress_setup_time");
        }
      );
    } else if (status === 2) {
      this.setState(
        {
          timerStatus: 2,
          buttonVisible: true,
        },
        () => this.clearAllTimers()
      );
    } else if (status === 3) {
      this.setState(
        {
          timerStatus: 3,
          startTimer: moment().format("YYYY-MM-DD HH:mm:ss:SSS"),
          timeElapsed: runTime + gapTime_ms,
          buttonVisible: true,
        },
        () => {
          this.updateTimer("progress_run_time");
        }
      );
    } else if (status === 4) {
      this.setState(
        {
          timerStatus: 4,
          startTimer: moment().format("YYYY-MM-DD HH:mm:ss:SSS"),
          timeElapsed: runTime,
          buttonVisible: true,
        },
        () => this.clearAllTimers()
      );
    } else if (status === 5) {
      this.setState({
        timeElapsed: runTime,
        buttonVisible: false,
      });
    } else if (status === 6) {
      this.setState(
        {
          timerStatus: 6,
          startTimer: moment().format("YYYY-MM-DD HH:mm:ss:SSS"),
          timeElapsed: runSetupTime,
          buttonVisible: true,
        },
        () => this.clearAllTimers()
      );
    }
  }

  // Adds an extra zero if the digit is single (e.g. 4 becomes 04)
  convertClockDigit(value) {
    const zero = "0";
    return /^\d$/.test(value) ? zero.concat(value) : value;
  }

  updateTimer(localStorageKey) {
    const { timeElapsed } = this.state;
    this.timer = setInterval(() => {
      var currentDate = moment().format("YYYY-MM-DD HH:mm:ss:SSS");

      this.setState({
        timeElapsed:
          Number(timeElapsed) +
          Number(
            moment(currentDate, "YYYY-MM-DD HH:mm:ss:SSS").diff(
              moment(this.state.startTimer, "YYYY-MM-DD HH:mm:ss:SSS")
            )
          ),
      });

      localStorage.setItem(
        localStorageKey, // "runTime",
        Number(timeElapsed) +
          Number(
            moment(currentDate, "YYYY-MM-DD HH:mm:ss:SSS").diff(
              moment(this.state.startTimer, "YYYY-MM-DD HH:mm:ss:SSS")
            )
          )
      );
      localStorage.setItem("startTimer", this.state.startTimer);
    }, 1000);
  }

  renderTimer() {
    const { timeElapsed, timerStatus } = this.state;

    const date = Math.floor(timeElapsed / (3600000 * 24));
    const hour = this.convertClockDigit(
      Math.floor((timeElapsed % (3600000 * 24)) / 3600000)
    );
    const minute = this.convertClockDigit(
      Math.floor((timeElapsed % 3600000) / 60000)
    );
    const second = this.convertClockDigit(
      Math.floor(((timeElapsed % 3600000) % 60000) / 1000)
    );

    const activeClass =
      timerStatus === 1 || timerStatus === 3 ? "active-timer" : "";
    const stopClass = timerStatus === 2 ? "stop-timer" : "";

    return (
      <div className={`digital digital-container `}>
        <div className={`timer-digitals ${activeClass} ${stopClass}`}>
          {date} Day {hour}:{minute}:{second}
        </div>
      </div>
    );
  }

  startSetup = async (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
    if (this.state.buttonDisabled) {
      return;
    }

    try {
      this.setState({ buttonDisabled: true });
      const currentUtcTime = moment.utc();
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");

      const promise1 = updateInitialSetupStartTime(
        this.state.currentMO,
        this.state.currentOperationNo
      );

      const promise2 = UpdateProgressStatusAndTimeService(
        this.state.currentMO,
        this.state.currentOperationNo,
        "1",
        currentUtcTime,
        null,
        this.props.hspop,
        this.props.workcenter.workcenter_no,
        false,
        true //write to db?
      ).then(() => {
        this.setState(
          {
            timerStatus: this.state.timerStatus + 1,
            startTimer: currentTime,
          },
          () => {
            this.updateTimer("progress_setup_time");
          }
        );
      });
      await Promise.all([promise1, promise2]);
      // setTimeout(() => this.setState({ disabled: false }), 1000);
    } catch (error) {
      console.log("ERROR - startSetup", error);
    } finally {
      this.setState({ buttonDisabled: false });
    }
  };

  //New Pause setup feature
  pauseSetup = (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
    if (this.state.buttonDisabled) {
      return;
    }

    this.props.handlePauseModal(true);
    // this.props.blockedUIHandler(false);
    setTimeout(() => {
      this.props.handlePauseModal(false);
    }, 120000);
  };

  resumeSetup = async (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
    if (this.state.buttonDisabled) {
      return;
    }

    try {
      this.setState({ buttonDisabled: true });
      const currentUtcTime = moment.utc();
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");

      //Update db
      const promise1 = updatePauseEnd(
        this.props.workcenter.workcenter_no,
        this.props.operation,
        this.props.MO,
        currentUtcTime
      );

      const promise2 = UpdateProgressStatusAndTimeService(
        this.state.currentMO,
        this.state.currentOperationNo,
        "1",
        currentUtcTime,
        null,
        this.props.hspop,
        this.props.workcenter.workcenter_no,
        false,
        false //write to db?
      ).then(() => {
        this.clearAllTimers();

        this.setState(
          {
            timerStatus: 1,
            startTimer: currentTime,
          },
          () => {
            this.updateTimer("progress_setup_time");
          }
        );
      });
      await Promise.all([promise1, promise2]);
    } catch (error) {
      console.log("ERROR - resumeSetup", error);
    } finally {
      this.setState({ buttonDisabled: false });
    }

    // setTimeout(() => this.setState({ disabled: false }), 1000);
  };

  endSetup = async (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
    if (this.state.buttonDisabled) {
      return;
    }

    try {
      this.setState({ buttonDisabled: true });
      const currentUtcTime = moment.utc();
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");

      await UpdateProgressStatusAndTimeService(
        this.state.currentMO,
        this.state.currentOperationNo,
        "2",
        undefined,
        currentUtcTime,
        this.props.hspop,
        this.props.workcenter.workcenter_no,
        false,
        true //write to db?
      ).then(() => {
        this.clearAllTimers();

        this.setState(
          {
            endTimer: currentTime,
            timerStatus: 2,
          },
          () => {
            UpdateProgressTimeService(
              this.state.currentMO,
              this.state.currentOperationNo,
              "progress_setup_time",
              this.state.timeElapsed,
              this.props.workcenter.workcenter_no
            );
          }
        );

        this.props.passProgressSetupTime(this.state.timeElapsed);
      });
      // setTimeout(() => this.setState({ disabled: false }), 1000);
    } catch (error) {
      console.log("ERROR - endSetup", error);
    } finally {
      this.setState({ buttonDisabled: false });
    }
  };

  startJob = async (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
    if (this.state.buttonDisabled) {
      return;
    }

    try {
      this.setState({ buttonDisabled: true });
      const currentUtcTime = moment.utc();
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");

      const promise1 = updateInitialStartTime(
        this.state.currentMO,
        this.state.currentOperationNo
      );

      const promise2 = UpdateProgressStatusAndTimeService(
        this.state.currentMO,
        this.state.currentOperationNo,
        "3",
        currentUtcTime,
        null,
        this.props.hspop,
        this.props.workcenter.workcenter_no,
        false,
        true //write to db?
      ).then(() => {
        this.clearAllTimers();

        this.setState(
          {
            timeElapsed:
              this.state.timerStatus === 4 ? this.state.timeElapsed : 0,
            timerStatus:
              this.state.timerStatus === 4 ? 3 : this.state.timerStatus + 1,
            startTimer: currentTime,
          },
          () => {
            this.updateTimer("progress_run_time");
          }
        );
      });
      await Promise.all([promise1, promise2]);
      //Disabled button for 1 second before next touch or click
      // setTimeout(() => this.setState({ buttonDisabled: false }), 1000);
    } catch (error) {
      console.log("ERROR - startJob", error);
    } finally {
      this.setState({ buttonDisabled: false });
    }
  };

  resumeJob = async (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
    if (this.state.buttonDisabled) {
      return;
    }

    try {
      this.setState({ buttonDisabled: true });
      const currentUtcTime = moment.utc();
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");

      //Update db
      const promise1 = updatePauseEnd(
        this.props.workcenter.workcenter_no,
        this.props.operation,
        this.props.MO,
        currentUtcTime
      );

      const promise2 = UpdateProgressStatusAndTimeService(
        this.state.currentMO,
        this.state.currentOperationNo,
        "3",
        currentUtcTime,
        null,
        this.props.hspop,
        this.props.workcenter.workcenter_no,
        true,
        false //write to db?
      ).then(() => {
        this.clearAllTimers();

        this.setState(
          {
            timeElapsed:
              this.state.timerStatus === 4 ? this.state.timeElapsed : 0,
            timerStatus:
              this.state.timerStatus === 4 ? 3 : this.state.timerStatus + 1,
            startTimer: currentTime,
          },
          () => {
            this.updateTimer("progress_run_time");
          }
        );
      });
      // setTimeout(() => this.setState({ buttonDisabled: false }), 1000);
      await Promise.all([promise1, promise2]);
    } catch (error) {
      console.log("ERROR - resumeJob", error);
    } finally {
      this.setState({ buttonDisabled: false });
    }
  };

  pauseJob = (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }

    this.props.handlePauseModal(true);
    setTimeout(() => {
      this.props.handlePauseModal(false);
    }, 120000);
  };

  finishJob = (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }

    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");
    this.clearAllTimers();
    this.setState(
      {
        endTimer: currentTime,
        timerStatus: 5,
        buttonVisible: false,
      },
      () => {
        UpdateProgressTimeService(
          this.state.currentMO,
          this.state.currentOperationNo,
          "progress_run_time",
          this.state.timeElapsed,
          this.props.workcenter.workcenter_no
        );
      }
    );

    // --- Update ProgressStatusAndTime is combined to /FinishCurrentMO ---
    // UpdateProgressStatusAndTimeService(
    //   this.state.currentMO,
    //   this.state.currentOperationNo,
    //   "5",
    //   this.props.hspop,
    //   this.props.workcenter.workcenter_no,
    //   false
    // ).then(() => {
    //   this.clearAllTimers();

    //   this.setState(
    //     {
    //       endTimer: currentTime,
    //       timerStatus: 5,
    //       buttonVisible: false,
    //     },
    //     () => {
    //       this.props.blockedUIHandler(true);
    //       this.props.passProgressStatus(this.state.timerStatus);
    //       UpdateProgressTimeService(
    //         this.state.currentMO,
    //         this.state.currentOperationNo,
    //         "progress_run_time",
    //         this.state.timeElapsed,
    //         this.props.workcenter.workcenter_no
    //       );
    //     }
    //   );
    // });
  };

  handlePauseState = (code, pauseAllOperationsFlag) => {
    const currentUtcTime = moment.utc();
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss:SSS");
    const currentDate = new Date();

    if (pauseAllOperationsFlag) {
      this.setState({ buttonDisabled: true });

      for (var i = 0; i < this.props.allOperations.length; i++) {
        //Update Routing_Pause table in DB
        updatePause(
          this.props.workcenter.workcenter_no,
          this.props.allOperations[i],
          this.props.MO,
          currentUtcTime,
          currentDate,
          code
        );
      }

      if (this.state.timeElapsed) {
        this.clearAllTimers();
        if (this.state.timerStatus === 1) {
          //Pausing setup
          this.setState({
            endTimer: currentTime,
            timerStatus: 6,
          });
        } else {
          //Pausing job
          this.setState({
            endTimer: currentTime,
            timerStatus: 4,
          });
        }
      }

      pauseAllOperations(
        this.props.workcenter.workcenter_no,
        this.props.MO,
        code,
        currentUtcTime,
        this.state.timeElapsed,
        this.props.operation
      );
    } else {
      //pause current operation
      this.setState({ buttonDisabled: true });

      //Update Routing_Pause table in DB
      updatePause(
        this.props.workcenter.workcenter_no,
        this.props.operation,
        this.props.MO,
        currentUtcTime,
        currentDate,
        code
      );

      if (this.state.timeElapsed) {
        this.clearAllTimers();

        //If pausing setup
        if (this.state.timerStatus === 1) {
          this.setState({
            endTimer: currentTime,
            timerStatus: 6,
          });
          UpdateProgressStatusAndTimeService(
            this.state.currentMO,
            this.state.currentOperationNo,
            "6",
            undefined,
            currentUtcTime,
            this.props.hspop,
            this.props.workcenter.workcenter_no,
            false,
            false //write to db?
          );
          UpdateProgressTimeService(
            this.state.currentMO,
            this.state.currentOperationNo,
            "progress_setup_time",
            this.state.timeElapsed,
            this.props.workcenter.workcenter_no
          );
        } else {
          //Pausing job
          this.setState({
            endTimer: currentTime,
            timerStatus: 4,
          });
          UpdateProgressStatusAndTimeService(
            this.state.currentMO,
            this.state.currentOperationNo,
            "4",
            undefined,
            currentUtcTime,
            this.props.hspop,
            this.props.workcenter.workcenter_no,
            false,
            false //write to db?
          );
          UpdateProgressTimeService(
            this.state.currentMO,
            this.state.currentOperationNo,
            "progress_run_time",
            this.state.timeElapsed,
            this.props.workcenter.workcenter_no
          );
        }
      }
    }

    setTimeout(() => this.setState({ buttonDisabled: false }), 1000);
  };

  render() {
    const currentTimer = this.timerController.get(this.state.timerStatus);
    const displayTimer = this.renderTimer();
    return (
      <>
        {displayTimer}
        {currentTimer && this.state.buttonVisible && (
          <Button
            style={{ marginTop: "5px", width: "100%" }}
            className="timer-button p-button-danger"
            label={currentTimer.status}
            onClick={currentTimer.operation}
            onTouchEnd={currentTimer.operation}
            disabled={this.state.buttonDisabled}
          />
        )}
        {(this.state.timerStatus === 1 || this.state.timerStatus === 6) && (
          <Button
            style={{ marginTop: "5px", width: "100%" }}
            className="timer-button p-button-danger"
            label={"End Setup"}
            onClick={(e) => {
              this.endSetup(e);
            }}
            onTouchEnd={(e) => {
              this.endSetup(e);
            }}
          ></Button>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.users,
  workcenter: state.workCenter,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  JobTimer
);
