import axios from "axios";
import moment from "moment";

import { CONST_API_URL } from "../../appconfig";
import { workCenterActions } from "../../store/work-center-slice";
import store from "../../store";

export function attemptLoginService(pin, division) {
  return axios
    .post(`${CONST_API_URL}/GetUserByPin`, { pin, division })
    .then((res) => res)
    .catch((error) => console.log(`GetUsers Client - ${error}`));
}

export function checkAdminService(pin) {
  return axios
    .post(`${CONST_API_URL}/getPositionByPin`, { pin })
    .then((res) => res.data[0])
    .catch((error) => console.log(`checkAdminService Client - ${error}`));
}

export function userLoginLogoutService(
  user,
  userArray,
  workcenter,
  supervisorApproved = false
) {
  const currentDateTime = moment().utc().format("YYYY-MM-DD HH:mm:ss:SSS");
  if (!userArray) {
    userArray = [];
  }

  if (userArray.some((e) => e.ID === user.ID)) {
    let signOutUser = userArray.find((e) => e.ID === user.ID);
    const userLogoutData = {
      user_id: user.ID,
      log_out: currentDateTime,
      log_id: signOutUser.log_id,
      work_center: workcenter,
      supervisorApproved,
    };

    return axios
      .post(`${CONST_API_URL}/RecordUserLogout`, { userLogoutData })
      .then((res) => {
        const updatedUsers = userArray.filter((e) => e.ID !== user.ID);
        store.dispatch(workCenterActions.updateUsers(updatedUsers));
        return res;
      });
  } else {
    const userLoginData = {
      user_id: user.ID,
      log_in: currentDateTime,
      work_center: workcenter,
    };

    return axios
      .post(`${CONST_API_URL}/RecordUserLogin`, { userLoginData })
      .then((res) => {
        const newUser = { ...res.data, ...user };
        const updatedUsers = userArray.concat(newUser);
        store.dispatch(workCenterActions.updateUsers(updatedUsers));
        return res;
      });
  }
}
