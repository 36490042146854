import { Select, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { notification } from "antd";

const { Option } = Select;
const { Column } = Table;

const LotSelectorPageOne = (props) => {
  function getQuantityColour(quantity) {
    if (quantity > 0) {
      return "red";
    } else {
      return "green";
    }
  }

  function isSelectDisabled(quantity) {
    if (quantity > 0) {
      return false;
    } else {
      return true;
    }
  }

  function removeDuplicates(array) {
    var noDuplicates = Array.from(new Set(array.map((a) => a.label))).map(
      (label) => {
        return array.find((a) => a.label === label);
      }
    );
    return noDuplicates;
  }

  //Function to filter out selected lots from the lot selection array
  function removeIfSelected(array, selectedArray) {
    const filtered = [];

    for (var i = 0; i < array.length; i++) {
      if (
        selectedArray.some(
          (e) =>
            e.lotNumber === array[i].label &&
            e.location === array[i].location_code
        )
      ) {
        //Do nothing
      } else {
        filtered.push(array[i]);
      }
    }

    return filtered;
  }

  //Function used to sort by lot number
  function compareLot(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  function cleanArray(array, selectedArray) {
    var filterSelected = removeIfSelected(array, selectedArray);
    var noDuplicates = removeDuplicates(filterSelected);
    var arrayWithTotals = getTotals(noDuplicates, array);
    var sortedArray = arrayWithTotals.sort((a, b) => {
      return b.totalAmongLocations - a.totalAmongLocations;
    });

    return sortedArray;
  }

  function getTotals(array, unfilteredArray) {
    var clone = [...array];
    for (var i = 0; i < clone.length; i++) {
      console.log(clone[i]);
      var totalQuantity = 0;
      for (var j = 0; j < unfilteredArray.length; j++) {
        if (clone[i].label === unfilteredArray[j].label) {
          totalQuantity += unfilteredArray[j].total_available_quantity;
        }
      }
      console.log(totalQuantity);
      clone[i].totalAmongLocations = totalQuantity.toFixed(5);
    }
    return clone;
  }

  //Main Render
  return (
    <div className="select__location animate__animated animate__fadeIn">
      <div className="select__lot" style={{ margin: "20px" }}>
        <div style={{ marginBottom: "20px" }}>
          <h1>
            Remaining quantity:{" "}
            <span style={{ color: getQuantityColour(props.targetQuantity) }}>
              {props.targetQuantity > 0 ? props.targetQuantity.toFixed(5) : 0}
            </span>
          </h1>
        </div>
        <div className="antd_select--custom">
          <Select
            style={{
              width: "75vw",
              border: "2px black solid",
            }}
            value={props.lotNumber}
            disabled={isSelectDisabled(props.targetQuantity)}
            onClick={() => {
              if (isSelectDisabled(props.targetQuantity) === true) {
                notification.warning({
                  message: "Warning",
                  description: `Remaining quantity has already been fufilled.`,
                  style: {
                    width: "40vw",
                  },
                });
              }
            }}
            onSelect={(value, allValue) => {
              props.handleStateChange(value, "lotNumber");
              props.nextPage();
            }}
            listHeight={400}
          >
            {cleanArray(props.dataSource, props.selectedLots).map((item) => {
              return (
                <Option
                  style={{ fontSize: "32px" }}
                  key={item.value + item.location_code}
                  value={item.value}
                  label={item.label}
                  actual_value={item.value}
                  heat_lot_no_required={item.heat_lot_no_required}
                  location_code={item.location_code}
                  total_available_quantity={item.total_available_quantity}
                >
                  <span
                    style={{
                      float: "left",
                      padding: "10px 0",
                    }}
                  >
                    {item.value}
                  </span>
                  <span
                    style={{
                      float: "right",
                      padding: "10px 0",
                      opacity: "0.6",
                    }}
                  >
                    {item.totalAmongLocations}
                  </span>
                </Option>
              );
            })}
          </Select>
        </div>
        <div
          key={props.selectedLots}
          className="history__table animate__animated animate__fadeIn"
          style={{ marginTop: "20px" }}
        >
          <Table
            pagination={false}
            dataSource={props.selectedLots}
            style={{ minHeight: "250px" }}
          >
            <Column
              title={<h3 style={{ fontWeight: "700" }}>Lot #</h3>}
              dataIndex="lotNumber"
              key="lotNumber"
              render={(value) => {
                return <h3>{value}</h3>;
              }}
            ></Column>
            <Column
              title={<h3 style={{ fontWeight: "700" }}>Location Code</h3>}
              dataIndex="location"
              key="location"
              render={(value) => {
                return <h3>{value}</h3>;
              }}
            ></Column>
            <Column
              title={<h3 style={{ fontWeight: "700" }}>Quantity</h3>}
              dataIndex="quantity"
              key="quantity"
              render={(value, record) => {
                return <h3>{value.toFixed(5)}</h3>;
              }}
            ></Column>
            <Column
              render={(value, record, index) => {
                return (
                  <div
                    onClick={() => {
                      props.handleStateChange("", "lotNumber");
                      props.deleteFromSelectedLots(index);
                      props.setLotsSelected(false);
                    }}
                  >
                    <h3 style={{ textAlign: "center", color: "red" }}>
                      <DeleteOutlined />
                    </h3>
                  </div>
                );
              }}
            ></Column>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default LotSelectorPageOne;
