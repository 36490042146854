import axios from "axios";
import { CONST_API_URL } from "../../appconfig";

export function checkSupervisorService(pin, division) {
  return axios
    .post(`${CONST_API_URL}/getTitleByPin`, { pin, division })
    .then((res) => res.data[0]);
  // .catch((error) => console.log(`checkSupervisorService Client - ${error}`));
}

export function checkSupervisorApprovalRequired(workcenter, hspopNo) {
  return axios
    .post(`${CONST_API_URL}/checkSupervisorApprovalRequired`, {
      workcenter,
      hspopNo,
    })
    .then((res) => res.data);
}
