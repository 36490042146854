import { Checkbox, Divider } from "antd";
import { connect } from "react-redux";

import { deviceConfigActions } from "../../../store/device-config.slice";
import { useState } from "react";
import "./workStationFilter.styles.css";

const WorkStationFilter = ({
  items,
  hiddenWorkCenters,
  updateHiddenWorkCenters,
}) => {
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (checkedValues) => {
    updateHiddenWorkCenters(
      items.filter((item) => !checkedValues.includes(item))
    );
  };

  const visibleItems = () => {
    if (!items || !hiddenWorkCenters) return [];

    let filtered = items.filter((elem) => {
      return !hiddenWorkCenters.includes(elem);
    });

    return filtered;
  };

  const checkboxOptions = () => {
    let options = items.map((item) => {
      return { label: item, value: item };
    });
    return options;
  };

  const onCheckAllChange = (e) => {
    updateHiddenWorkCenters(e.target.checked ? [] : items);
    setCheckAll(e.target.checked);
  };

  return (
    <div className="work-station-filter__wrapper">
      <Checkbox onChange={onCheckAllChange} checked={checkAll}>
        <div style={{ padding: "10px" }}>
          <h1>Check All</h1>
        </div>
      </Checkbox>

      <Divider style={{ margin: "4px 0" }} />

      <Checkbox.Group
        value={visibleItems()}
        onChange={onChange}
        style={{
          width: "100%",
        }}
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox value={item}>
                <div style={{ padding: "10px" }}>
                  <h1>{item}</h1>
                </div>
              </Checkbox>
            </div>
          );
        })}
      </Checkbox.Group>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hiddenWorkCenters: state.deviceConfig.hiddenWorkCenters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateHiddenWorkCenters: (hiddenWorkCenters) =>
      dispatch(deviceConfigActions.updateHiddenWorkCenters(hiddenWorkCenters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkStationFilter);
