import React from "react";
import Modal from "antd/lib/modal/Modal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import html2canvas from "html2canvas";
import { Select, notification } from "antd";
import config from "../../config";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import "./HelpScreenshotButton.styles.css";
import { Dropdown } from "primereact/dropdown";
import { CONST_API_URL } from "../../../appconfig";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const { Option } = Select;

class HelpScreenshotButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helpModalVisible: false,
      helpSelectedReason: "",
      screenshotData: null,
      NAVCompany: "",
    };
  }

  componentDidMount() {
    axios
      .post(`${CONST_API_URL}/GetNavCompany`)
      .then((res) => {
        this.setState({ NAVCompany: res.data });
      })
      .catch((error) => console.log(error));
  }

  openNotification = () => {
    notification.open({
      message: "Email Sent",
      description:
        "A screenshot has been sent to support to troubleshoot the issue.",
      icon: <MailOutlined style={{ color: "#108ee9" }} />,
      style: {
        width: "40vw",
      },
    });
  };

  generateScreenshot = (modalHandler, screenshotDataHandler) => {
    html2canvas(document.body).then(function (canvas) {
      var data = canvas.toDataURL();
      modalHandler(true);
      screenshotDataHandler(data);
    });
  };

  sendEmail = () => {
    let reason = this.state.helpSelectedReason;
    var data = this.state.screenshotData;
    var company = this.state.NAVCompany;
    var recipient = config.emailRecipients;
    var mail = "";

    this.openNotification();

    mail += "Client: ";
    mail += "Reason: " + reason;
    mail += "NAV Company: " + company;
    var parameters = {
      mail: mail,
      recipient: recipient,
      base64Screenshot: data,
    };

    axios
      .post(`${CONST_API_URL}/sendEmail`, parameters)
      .then((res) => {})
      .catch((error) => console.log(error));
  };

  onHelpCancel = (e) => {
    this.setState({ helpModalVisible: false });
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  };

  onHelpSend = (e) => {
    this.setState({ helpModalVisible: false }, () => {
      //this.sendEmail();
    });
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  };

  helpFooter = () => {
    return [
      <Button
        className="helpModalButton"
        key="1"
        onClick={(e) => this.onHelpCancel(e)}
        onTouchEnd={(e) => this.onHelpCancel(e)}
      >
        {" "}
        Cancel{" "}
      </Button>,
      <Button
        className="helpModalButton"
        key="2"
        type="primary"
        onClick={(e) => this.onHelpSend(e)}
        onTouchEnd={(e) => this.onHelpSend(e)}
      >
        {" "}
        Send{" "}
      </Button>,
    ];
  };

  helpTitle = () => {
    return (
      <div style={{ fontSize: "3rem" }}>
        Do you want to send a screenshot to the help desk?
      </div>
    );
  };

  handleReasonChange = (value) => {
    this.setState({ helpSelectedReason: value });
  };

  selectedReasonTemplate = (option, props) => {
    if (option) {
      return (
        <div>
          <h1 className="selectedReason">{option.value}</h1>
        </div>
      );
    }

    return <h1 className="selectedReason">{props.placeholder}</h1>;
  };

  reasonOptionTemplate = (option) => {
    return (
      <div>
        <h1 className="reasonItem">{option.label}</h1>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Dialog
          id="helpModal"
          header="Help Request"
          visible={this.state.helpModalVisible}
          style={{ width: "80vw" }}
          onHide={() => this.setState({ helpModalVisible: false })}
          dismissableMask={true}
          footer={this.helpFooter}
        >
          <div
            style={{
              border: "1px dashed red",
              marginBottom: "1rem",
              padding: "0.5rem 1rem",
              fontSize: "30px",
            }}
          >
            <p>
              ※ If you would like to change your division, please contact
              supervisor
            </p>
          </div>
          <Dropdown
            id="reasonDropdown"
            value={this.state.helpSelectedReason}
            valueTemplate={this.selectedReasonTemplate}
            itemTemplate={this.reasonOptionTemplate}
            optionLabel="label"
            panelStyle={{ zIndex: "3000" }}
            options={[
              { label: "Can't find MO", value: "Can't find MO" },
              {
                label: "Can't find WORKCENTER",
                value: "Can't find WORKCENTER",
              },
              { label: "Other", value: "Other" },
            ]}
            onChange={(e) => {
              this.handleReasonChange(e.value);
            }}
            placeholder="Select a Reason"
          />
          <h1>
            {"Company: " + this.state.NAVCompany}
            <br />
            {`Division: ${this.props.division}`}
          </h1>
        </Dialog>
        {/*<Modal
          center
          title={this.helpTitle()}
          footer={this.helpFooter()}
          onCancel={() => this.setState({ helpModalVisible: false })}
          onHide={() => this.setState({ helpModalVisible: false })}
          destroyOnClose
          visible={this.state.helpModalVisible}
          maskClosable={false}
          width={window.innerWidth > 1280 ? "80vw" : "90vw"}
        >

                    <p style={{ fontSize: "3rem" }}>Reason</p>
          <Select
            listHeight={window.innerHeight * 0.7}
            style={{
              width: window.innerWidth > 1280 ? "80vw" : "90vw",
              fontSize: "3rem",
            }}
            onChange={(value) => this.handleReasonChange(value)}
          >
            <Option style={{ fontSize: "3rem" }} value="Can't find MO">
              Can't find MO
            </Option>
            <Option style={{ fontSize: "3rem" }} value="Can't find WORKCENTER">
              Can't find WORKCENTER
            </Option>
            <Option style={{ fontSize: "3rem" }} value="Others">
              Others
            </Option>
          </Select>
          <h1>{"Company: " + this.state.NAVCompany}</h1> 
        </Modal> */}

        <Button
          className="p-button-danger"
          style={{ fontSize: "3rem", background: "#d32f2f", border: "none" }}
          size="lg"
          onClick={() => {
            this.generateScreenshot(
              (value) => {
                this.setState({ helpModalVisible: value });
              },
              (value) => {
                this.setState({ screenshotData: value });
              }
            );
          }}
          onTouchEnd={(e) => {
            this.generateScreenshot(
              (value) => {
                this.setState({ helpModalVisible: value });
              },
              (value) => {
                this.setState({ screenshotData: value });
              }
            );
            e.preventDefault();
          }}
        >
          <h1 style={{ color: "white" }}>Help</h1>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  division: state.appConfig.division,
});

export default connect(mapStateToProps)(withRouter(HelpScreenshotButton));
