import React from "react";
import axios from "axios";
import "primeflex/primeflex.css";
import "./ObjectCounter.styles.css";
import NumPad from "../../numpad/NumPad.component";
import { notification, Row, Col } from "antd";
import { connect } from "react-redux";
import { CONST_API_URL } from "../../../appconfig";
import { RedoOutlined } from "@ant-design/icons";

import SupervisorPermission from "../../SupervisorPermissionModal/SupervisorPermission.component";

function concatenateString(data) {
  return data.first_name + " " + data.last_name;
}
class ObjectCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      objectType: this.props.objectType,
      count: this.props.count,
      visible: false,
      loading: false,
      pin: "",
      showSupervisorPopup: false,
      selectedPostingUser: null,
      supervisorApproved: true,
      value: 0,
    };
    this.takeNumPadInput = this.takeNumPadInput.bind(this);
    this.loadingHandler = this.loadingHandler.bind(this);
    this.pinHandler = this.pinHandler.bind(this);
  }

  componentDidUpdate(prevProps) {}

  onModalCancel = () => {
    this.setState({ visible: false });
    this.setState({ loading: false });
  };

  loadingHandler = (value) => {
    this.setState({ loading: value });
  };

  pinHandler = (value) => {
    this.setState({ pin: value });
  };

  checkRestrictions = (value) => {
    var upperBound = Math.ceil(this.props.total * 1.05);
    var lowerBound = Math.floor(this.props.total * 0.95);

    var value = value + this.props.count;

    console.log(value);

    console.log("Upper Bound: ", upperBound);
    console.log("Lower Bound: ", lowerBound);

    if (this.props.isStartingOperation === false) {
      upperBound = this.props.total;
      console.log("Upper Bound updated: ", upperBound);
    }

    if (value > upperBound) {
      return false;
    } else {
      this.props.onClick();
      return true;
    }
  };

  takeNumPadInput = (value, user) => {
    this.loadingHandler(true);
    const parsedInputValue = parseInt(value);

    if (parsedInputValue <= 0) {
      this.loadingHandler(false);
      return notification.error({
        message: "Failed",
        description: "Posting 0 quantity is not allowed.",
        duration: 5,
        style: {
          width: "40vw",
        },
      });
    }

    this.setState({ value: value });
    var checkResult = this.checkRestrictions(parsedInputValue);

    if (!checkResult) {
      if (this.props.isStartingOperation === false) {
        this.loadingHandler(false);
        return notification.error({
          message: "Failed",
          description:
            "Posting request exceeding the total quantity is not allowed.",
          duration: 5,
          style: {
            width: "40vw",
          },
        });
      }
      console.log("CANCEL");
      this.setState({
        selectedPostingUser: user,
        supervisorApproved: false,
        showSupervisorPopup: true,
      });
      return;
    }
    this.afterNumPadInput(parsedInputValue, value, user);
  };

  afterNumPadInput = async (parsedInputValue, value, user) => {
    try {
      if (this.props.workcentertype === 0 || !this.props.booleanCheck) {
        this.props.setLoading?.(true);
        console.log("facility: ", this.props.division);
        const res = await axios.get(
          `${CONST_API_URL}/nav/getReleasedProductionOrderLine`,
          {
            params: {
              order_no: this.props.prodOrdNo,
            },
          }
        );

        if (res && res.data) {
          const prodOrderLines = res.data.value;
          let tempScrapQty =
            this.state.objectType === "scrap" ? Number(value) : 0;
          let tempOutputQty =
            this.state.objectType === "part" ? Number(value) : 0;
          let tempSetupTimeHr = 0;
          if (this.state.objectType === "part") {
            tempSetupTimeHr = Number(
              (this.props.total / tempOutputQty) *
                (this.props.progressSetupTime / 3600000)
            );
          } else {
            tempSetupTimeHr = Number(
              (this.props.total / tempScrapQty) *
                (this.props.progressSetupTime / 3600000)
            );
          }
          const res2 = await axios.post(
            `${CONST_API_URL}/consumption/updateRoutingQty`,
            {
              prodOrdNo: this.props.prodOrdNo,
              prodOrdLineNo: prodOrderLines[0].Line_No, //res.data[0].MO_Line_No,
              prodOrderItemNo: this.props.prodOrderItemNo,
              operationNo: this.props.operationNoChar,
              workCenterNo: this.props.workcenter?.workcenter_no,
              // lot_Heat_No: "",
              routingReferenceNo: prodOrderLines[0].Line_No, //res.data[0].RoutingReferenceNo,
              scrapQty: tempScrapQty,
              outputQty: tempOutputQty,
              webUserID: concatenateString(user),
              userId: user.ID,
              routingNo: prodOrderLines[0].Routing_No, //res.data[0].RoutingNo,
              hspopNo: this.props.hspop,
              facility: this.props.division,
            }
          );

          if (!res2.data.type) {
            notification.error({
              message: "Failed",
              description: res2.data.msg,
              duration: 10,
              style: {
                width: "40vw",
              },
            });
            this.setState({ loading: false, visible: false });
            this.props.setLoading?.(false);
          } else {
            if (this.props.objectType === "part") {
              //update part_count in sql but this is not in use because of pulling part count directly from NAV
              await axios.post(`${CONST_API_URL}/AdjustPartCount`, {
                MO: this.props.prodOrdNo,
                OperationNo: this.props.operationNo,
                count: parseInt(this.props.count) + parsedInputValue,
              });
              this.props.operationScrollerMOHandler(
                this.props.operationNo,
                this.props.operationNoChar,
                this.props.hspop
              );
              this.onModalCancel();
            } else {
              await axios.post(`${CONST_API_URL}/AdjustScrapCount`, {
                MO: this.props.prodOrdNo,
                OperationNo: this.props.operationNo,
                count: parseInt(this.props.count) + parsedInputValue,
              });
              this.props.refreshPartScrapTotalCount();
              this.onModalCancel();
            }
          }
        }
      } else {
        if (this.state.objectType === "part") {
          this.props.onUpdatePOCPartCount(parsedInputValue);
          this.props.onUpdateSeletedUserForPOC(user);
          //this.props.onClick();
          this.onModalCancel();
          this.props.onUpdateConditionalPartCount(
            parseInt(this.props.count) + parsedInputValue
          );
        } else {
          const res = await axios.post(
            `${CONST_API_URL}/consumption/ComponentItems`,
            {
              MO: this.props.prodOrdNo,
            }
          );

          if (res && res.data) {
            let tempScrapQty =
              this.state.objectType === "scrap" ? Number(value) : 0;
            let tempSetupTimeHr = Number(
              (this.props.total / tempScrapQty) *
                (this.props.progressSetupTime / 3600000)
            );

            const res2 = await axios.post(
              `${CONST_API_URL}/consumption/updateRoutingQty`,
              {
                prodOrdNo: this.props.prodOrdNo,
                prodOrdLineNo: res.data[0].MO_Line_No,
                prodOrderItemNo: this.props.prodOrderItemNo,
                operationNo: this.props.operationNoChar,
                workCenterNo: this.props.workcenter?.workcenter_no,
                // lot_Heat_No: "",
                scrapQty: tempScrapQty,
                outputQty: 0,
                webUserID: concatenateString(user),
                userId: user.ID,
                routingNo: res.data[0].RoutingNo,
                hspopNo: this.props.hspop,
                facility: this.props.division,
                routingReferenceNo: res.data[0].RoutingReferenceNo,
              }
            );

            if (!res2.data.type) {
              notification.error({
                message: "Failed",
                description: res2.data.msg,
                duration: 10,
                style: {
                  width: "40vw",
                },
              });
              this.setState({ loading: false, visible: false });
              this.props.setLoading?.(false);
            } else {
              axios
                .post(`${CONST_API_URL}/AdjustScrapCount`, {
                  MO: this.props.prodOrdNo,
                  OperationNo: this.props.operationNo,
                  count: parseInt(this.props.count) + parsedInputValue,
                })
                .then((res) => {
                  this.onModalCancel();
                });
              this.props.onUpdateCurrentScrapCount(
                parseInt(this.props.count) + parsedInputValue
              );
            }
          }
        }
      }
    } catch (error) {
      console.log("ERROR - afterNumPadInput", error);
      this.props.setLoading?.(false);
    } finally {
      // this.props.setLoading?.(false); // -> unflagging should be done by fetchAllTest
    }
  };

  onSuccessSupervisorPopup = (supervisor) => {
    this.props.onClick();
    this.setState(
      {
        showSupervisorPopup: false,
        supervisorApproved: true,
      },
      notification.success({
        message: "Access Granted",
        description: `Your permission was granted by ${supervisor}. Please wait.`,
        style: {
          width: "40vw",
        },
      })
    );
    this.afterNumPadInput(
      parseInt(this.state.value),
      this.state.value,
      this.state.selectedPostingUser
    );
  };

  render() {
    const title = (
      <h1
        style={{
          textAlign: "left",
          fontSize: this.props.objectType === "part" ? "4rem" : "3rem",
          fontWeight: "bold",
          lineHeight: "4rem",
          width: "100%",
        }}
      >
        {this.state.title ? this.state.title : "Placeholder Title"}:
      </h1>
    );
    return (
      <>
        <SupervisorPermission
          showModal={this.state.showSupervisorPopup}
          type={"Posting Check - Upper Limit Exceeded"}
          onCancel={() => {
            this.setState({
              showSupervisorPopup: false,
              supervisorApproved: false,
              visible: false,
              loading: false,
            });
          }}
          onSuccess={this.onSuccessSupervisorPopup}
        ></SupervisorPermission>
        <NumPad
          visible={this.state.visible}
          onCancel={this.onModalCancel}
          onPost={this.takeNumPadInput}
          loading={this.state.loading}
          // loadingHandler={this.loadingHandler}
          pin={this.state.pin}
          pinHandler={this.pinHandler}
          remainingQty={this.props.remainingQty}
          MO={this.props.prodOrdNo}
          operationNo={this.props.operationNo}
          // heatNumberArray={this.props.heatNumberArray}
        />
        <div
          className=""
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Row style={{ maxWidth: "100%" }}>
            <Col span={8} style={{ margin: "auto 0" }}>
              <div className=" p-mr-2">{title}</div>
            </Col>
            <Col
              style={{ flex: 1, display: "flex", alignItems: "center" }}
              {...(this.props.subcontractorMode !== true && {
                onClick: () => {
                  this.props.total > 0
                    ? this.setState({ visible: true })
                    : notification.error({
                        message: "Error",
                        description:
                          "Cannot post to MO with total quantity = 0",
                        duration: 10,
                        style: {
                          width: "40vw",
                        },
                      });
                },
                onTouchEnd: (e) => {
                  e.preventDefault();
                  this.props.total > 0
                    ? this.setState({ visible: true })
                    : notification.error({
                        message: "Cannot post to MO with total quantity = 0",
                        description: res.data.msg,
                        duration: 10,
                        style: {
                          width: "40vw",
                        },
                      });
                },
              })}
            >
              <Row>
                <h1
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize:
                      this.props.objectType === "part" ? "6rem" : "3rem",
                    marginLeft: "1vw",
                  }}
                >
                  <span
                    style={{
                      border: "2px solid black",
                      padding: "10px",
                    }}
                  >
                    {this.props.count ? this.props.count.toFixed() : 0}
                  </span>
                  {/* {this.props.total ? " of " : ""} */}
                  <span> of </span>
                  {this.props.total ? this.props.total.toFixed() : 0}
                </h1>
              </Row>
            </Col>
            {this.props.objectType === "part" && (
              <Col
                span={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <RedoOutlined
                  style={{ fontSize: "5em" }}
                  onClick={() => this.props.refreshPartScrapTotalCount()}
                />
              </Col>
            )}
          </Row>
          {this.props.objectType === "part" && (
            <Row>
              <div
                className=" p-mr-2"
                style={{
                  width: "26%",
                  clear: "left",
                }}
              >
                &nbsp;
              </div>

              <h1
                style={{
                  marginLeft: "0vw",
                  fontWeight: "bold",
                  fontSize: "3em",
                  lineHeight: "1.8em",
                }}
              >
                QTY Remaining: &nbsp;
              </h1>
              <h1
                style={{
                  fontSize: "4.4rem",
                  fontWeight: "bold",
                }}
              >
                {/* {this.props.total - this.props.count} */}
                {Math.round(
                  ((this.props.total - this.props.count) * 100000) / 100000
                )}
              </h1>
            </Row>
          )}
        </div>
        <div></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.user,
  workcentertype: state.workCenter.workcenter_type,
  workcenter: state.workCenter,
  division: state.appConfig.division,
});

export default connect(mapStateToProps)(ObjectCounter);
