import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  version: "",
};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: initialState,
  reducers: {
    updateDivision(state, action) {
      state.division = action.payload;
    },
    updateVersion(state, action) {
      state.version = action.payload;
    },
  },
});

export const appConfigActions = appConfigSlice.actions;
export default appConfigSlice;
