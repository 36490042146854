export function notDoubleDecimal(numbersEntered, input) {
  return !(hasDecimal(numbersEntered) && input === ".");
}

export function notExceededMax(numbersEntered, max) {
  const decimals = countDecimals(numbersEntered);
  return decimals < max;
}

export function hasDecimal(number) {
  return !number ? false : number.toString().includes(".");
}

function countDecimals(number) {
  if (!hasDecimal(number)) return 0;
  return number.toString().split(".")[1].length;
}

function countWhole(number) {
  if (number !== null && number !== undefined) {
    if (hasDecimal(number)) {
      return number.toString().split(".")[0].length;
    } else {
      return number.toString().length;
    }
  } else {
    return 0;
  }
}

export function notExceededMaxWhole(numbersEntered, max) {
  console.log("numbers entered: ", numbersEntered);
  console.log(" max: ", max);
  const wholeNumbers = countWhole(numbersEntered);
  console.log("whole number count: ", wholeNumbers);
  return wholeNumbers < max;
}
