import React, { useState } from "react";

import "./UserSelector.styles.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function UserSelector(props) {
  const userBodyTemplate = (rowData) => {
    return (
      <div>
        <h1 className="userBodyName">
          {rowData.first_name + " "}
          {rowData.last_name}
        </h1>
      </div>
    );
  };

  const userSelectionChange = (user) => {
    props.handleSelectedUser(user);
  };

  return (
    <div id="userSelectorContainer">
      <div className="userHeaderContainer">
        <h5 className="userHeaderText">Select User</h5>
      </div>
      <DataTable
        id="UserDatatable"
        selection={props.selectedUser}
        value={props.users}
        scrollable
        selectionMode="single"
        dataKey="ID"
        responsiveLayout="scroll"
        onSelectionChange={(e) => {
          userSelectionChange(e.value);
        }}
        header={null}
        style={{
          width: "100%",
          height: "100%",
          fontSize: "2.6rem",
          fontWeight: "bold",
        }}
      >
        <Column
          header={null}
          body={userBodyTemplate}
          style={{
            width: "100%",
            textAlign: "center",
          }}
        ></Column>
      </DataTable>
    </div>
  );
}

export default UserSelector;
