import "./loading-spinner.styles.css";

const LoadingSpinner = () => {
  return (
    <div class="loading__container">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
