import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { CONST_API_URL } from "../../../appconfig";

class PauseModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pauseReasons: [],
    };

    this.onReasonClick = this.onReasonClick.bind(this);
  }

  componentDidMount() {
    //Get pause reasons from DB
    axios.post(`${CONST_API_URL}/GetPauseReasons`, {}).then((res) => {
      this.setState({
        pauseReasons: res.data,
      });
    });
  }

  onReasonClick(code) {
    //Update scheduling items DB with reason for pause
    axios
      .post(`${CONST_API_URL}/UpdateSchedulingItemsOnPause`, {
        pause_reason: code,
        operation_no: this.props.operation_no,
        order_no: this.props.order_no,
      })
      .then((res) => {});

    //If reason=33 (Shift Change), then log out all users
    if (code === 33) {
      axios
        .post(`${CONST_API_URL}/LogoutUserByWorkCenter`, {
          workcenter: this.props.workcenter?.workcenter_no,
        })
        .then((res) => {});
    }

    if (code === 34) {
      this.props.handlePauseStateFromModal(code, true); //Continue pause process
    } else {
      this.props.handlePauseStateFromModal(code, false); //Continue pause process
    }
    //this.props.handlePauseStateFromModal(code, this.state.pauseAllOperations); //Continue pause process
    this.props.handlePauseModal(false); //Close pause reason modal
  }

  render() {
    return (
      <div style={{ height: "100%", background: "black" }}>
        <div style={{ display: "inline-block" }}>
          <div className="row h-50">
            {this.state.pauseReasons.map((reason) => {
              return (
                <div className="col-md-3 p-4" key={reason.reason_code}>
                  <Button
                    key={reason.reason_no}
                    className="p-button-danger"
                    style={{ width: "100%", height: "100%", fontSize: "4rem" }}
                    label={reason.reason_code}
                    onClick={() => {
                      this.onReasonClick(reason.reason_no);
                    }}
                    onTouchEnd={(e) => {
                      this.onReasonClick(reason.reason_no);
                      e.preventDefault();
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* <div>
          {" "}
          <Checkbox
            style={{ color: "white", fontSize: "30px", marginRight: "100px" }}
            checked={this.state.pauseAllOperations}
            onChange={(e) => {
              this.setState({ pauseAllOperations: e.target.checked });
            }}
          >
            <span>Pause All Jobs?</span>
          </Checkbox>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.user,
  workcenter: state.workCenter,
});

export default connect(mapStateToProps)(PauseModalContent);
