import React from "react";
import { connect } from "react-redux";
import { checkSupervisorService } from "./SupervisorPermission.service";
import { notification, Modal } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

const SupervisorPopupController = new Map([
  ["Start Job", { title: "Permission to Start Job" }],
  ["QC Inspection", { title: "Quality Control Inspection" }],
  ["Supervisor Approval", { title: "Supervisor Approval Required" }],
  [
    "Posting Check - Upper Limit Exceeded",
    { title: "Posting Check - Upper Limit Exceeded" },
  ],
  [
    "Posting Check - Lower Limit Exceeded",
    { title: "Posting Check - Lower Limit Exceeded" },
  ],
]);

class SupervisorPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
    };
  }

  addDigit(digit, limit = 12, e) {
    const { pin } = this.state;
    if (pin.length < limit) {
      const newPin = pin + digit;
      this.setState({ pin: newPin });
    }
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  }

  clearDigits(e) {
    this.setState({ pin: "", label: "Please Enter Your Pin" });
    let currentPin = this.state.pin;
    if (currentPin === "") {
      this.props.onCancel();
    }
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  }

  renderPin() {
    return this.state.pin.replace(/.(?=.{1,}$)/g, "*");
  }

  render() {
    const title = SupervisorPopupController.get(this.props.type).title;
    return (
      <div className="supervisor-permission__modal">
        <Modal
          visible={this.props.showModal}
          onHide={() => this.props.onCancel()}
          onCancel={() => this.props.onCancel()}
          width={this.props.dialogWidth ?? "85vw"}
          zIndex={10000}
          footer={null}
          centered
        >
          <div
            className="keypad-background"
            style={{
              ...(this.props.bgColor && {
                backgroundColor: this.props.bgColor,
              }),
            }}
          >
            <div className="keypad-container">
              <div
                style={{
                  color: "black",
                  fontSize: "35px",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  marginBottom: "15px",
                }}
              >
                {title}
              </div>
              {this.props.message && (
                <div className="keypad-message">{this.props.message}</div>
              )}
              <div className="keypad keypad-grid">
                <div className="keypad-trackpad">
                  <span className="keypad-label">
                    Please Enter Supervisor Pin
                  </span>
                  <div className="keypad-pin">{this.renderPin()}</div>
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(1, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(1, undefined, e)}
                >
                  1
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(2, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(2, undefined, e)}
                >
                  2
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(3, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(3, undefined, e)}
                >
                  3
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(4, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(4, undefined, e)}
                >
                  4
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(5, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(5, undefined, e)}
                >
                  5
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(6, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(6, undefined, e)}
                >
                  6
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(7, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(7, undefined, e)}
                >
                  7
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(8, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(8, undefined, e)}
                >
                  8
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(9, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(9, undefined, e)}
                >
                  9
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.clearDigits(e)}
                  onTouchEnd={(e) => this.clearDigits(e)}
                >
                  {this.state.pin === "" ? (
                    <RollbackOutlined />
                  ) : (
                    <CloseOutlined className="keypad-icon" />
                  )}
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(0, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(0, undefined, e)}
                >
                  0
                </div>
                <div
                  className="keypad-block"
                  onClick={() => this.attemptGrantAccess()}
                >
                  <CheckOutlined className="keypad-icon" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  attemptGrantAccess() {
    if (this.state.pin) {
      checkSupervisorService(this.state.pin, this.props.division)
        .then((res) => {
          this.clearDigits();

          if (!res) {
            this.props.onCancel();
            return notification.warning({
              message: "Access Denied",
              description: "Your pin is invalid.",
              style: {
                width: "40vw",
              },
            });
          } else if (!res?.active) {
            this.props.onCancel();
            return notification.warning({
              message: "Access Denied",
              description:
                "Your pin is deactivated, please contact your supervisor.",
              style: {
                width: "40vw",
              },
            });
          } else if (res?.title?.toUpperCase() !== "SUPERVISOR") {
            this.props.onCancel();
            return notification.warning({
              message: "Access Denied",
              description: "Your pin does not have supervisor permission.",
              style: {
                width: "40vw",
              },
            });
          }

          const supervisorName = `${res.firstName.toUpperCase()} ${res.lastName.toUpperCase()}`;
          this.props.onSuccess(supervisorName);
        })
        .catch((error) => {
          console.log("ERROR - attemptGrantAccess", error);
          this.clearDigits();
          this.props.onCancel();
          notification.error({
            message: "Error",
            description:
              "Something wrong occurred with the network. Please try again or contact support.",
            style: {
              width: "40vw",
            },
          });
        });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.user,
  workcenter: state.workCenter,
  division: state.appConfig.division,
});

export default connect(mapStateToProps)(SupervisorPermission);
