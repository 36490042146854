import { connect } from "react-redux";
import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import "./deviceSetup.styles.css";
import moment from "moment";
import { deviceConfigActions } from "../../../store/device-config.slice";
import axios from "axios";
import { CONST_API_URL } from "../../../appconfig";

const [MODE_LOAD, MODE_SAVE, MODE_DELETE] = ["load", "save", "delete"];

const convertUtcDateToLocaleDateStr = (date) =>
  date ? moment(date).format("YYYY-MM-DD HH:mm") : null;

const DeviceSetup = ({ deviceConfig, overwriteDeviceConfig }) => {
  const [mode, setMode] = useState(MODE_LOAD);
  const [savedConfigs, setSavedConfigs] = useState([]);
  const [selectedConfigId, setSelectedConfigId] = useState();
  const [displayMessage, setDisplayMessage] = useState("");
  const [saveModeValue, setSaveModeValue] = useState();
  const [apiLoading, setApiLoading] = useState(false);
  const [deleteSelectedIds, setDeleteSelectedIds] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchConfigData();
  }, []);

  const fetchConfigData = async () => {
    const res = await axios.get(`${CONST_API_URL}/devices/getDeviceConfig`);
    if (res?.data) {
      const labelledData = res.data.map((obj) => ({
        ...obj,
        value: obj.id,
        key: obj.id,
        label: `${obj.name} (Last updated: ${
          convertUtcDateToLocaleDateStr(obj.modified_datetime_utc) ||
          convertUtcDateToLocaleDateStr(obj.created_datetime_utc)
        })`,
      }));
      setSavedConfigs(labelledData);
    }
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
    setSelectedConfigId();
    setDisplayMessage();
  };
  const onChangeSelect = (value) => setSelectedConfigId(value);
  const onChangeSaveMode = (e) => setSaveModeValue(e.target.value);

  const onFinishNewConfig = async (values) => {
    if (apiLoading) {
      return;
    }

    try {
      setApiLoading(true);
      const res = await axios.post(
        `${CONST_API_URL}/devices/saveDeviceConfig`,
        {
          name: values.name,
          jsonData: deviceConfig,
        }
      );
      form.resetFields();
      fetchConfigData();
      setDisplayMessage(<p className="text-success">Successfully saved.</p>);
    } catch (error) {
      console.log("ERROR - onFinishNewConfig", error);
      setDisplayMessage(
        <p className="text-danger">
          Unexpected error occurred, please contact to supervisor if this error
          persists.
        </p>
      );
    } finally {
      setApiLoading(false);
    }
  };

  const onFinishOverwriteConfig = async () => {
    if (apiLoading) {
      return;
    } else if (!!!selectedConfigId) {
      setDisplayMessage(
        <p className="text-danger">Please select the target config</p>
      );
      return;
    }

    try {
      setApiLoading(true);
      const res = await axios.post(
        `${CONST_API_URL}/devices/saveDeviceConfig`,
        {
          id: selectedConfigId,
          jsonData: deviceConfig,
        }
      );
      setDisplayMessage(
        <p className="text-success">Successfully overwritted.</p>
      );
      fetchConfigData();
    } catch (error) {
      console.log("ERROR - onFinishOverwriteConfig", error);
      setDisplayMessage(
        <p className="text-danger">
          Unexpected error occurred, please contact to supervisor if this error
          persists.
        </p>
      );
    } finally {
      setApiLoading(false);
    }
  };

  const loadAppConfig = () => {
    try {
      if (!!!selectedConfigId) {
        setDisplayMessage(
          <p className="text-danger">Please select the target config</p>
        );
        return;
      }
      const deviceConfig = savedConfigs.find(
        (obj) => obj.id === selectedConfigId
      );

      const parsedConfig = JSON.parse(deviceConfig?.config_json_stringified);

      const result = overwriteDeviceConfig(parsedConfig);
      setDisplayMessage(
        <p className="text-success">
          Successfully completed loading the device config, the browser will be
          refreshed automatically in 3 seconds.
        </p>
      );

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.log("ERROR - loadAppConfig", error);
      setDisplayMessage(
        <p className="text-danger">
          Unexpected error occurred, please contact to supervisor if this error
          persists.
        </p>
      );
    }
  };

  const onChangeDeleteCheckBox = (selectedRowKeys, selectedRows) => {
    setDeleteSelectedIds(selectedRowKeys);
  };

  const deleteSelectedConfigs = async () => {
    if (apiLoading) {
      return;
    }

    try {
      setApiLoading(true);
      const res = await axios.post(
        `${CONST_API_URL}/devices/deleteDeviceConfig`,
        {
          idList: deleteSelectedIds,
        }
      );
      setDisplayMessage(<p className="text-success">Successfully deleted.</p>);
      fetchConfigData();
    } catch (error) {
      console.log("ERROR - onFinishOverwriteConfig", error);
      setDisplayMessage(
        <p className="text-danger">
          Unexpected error occurred, please contact to supervisor if this error
          persists.
        </p>
      );
    } finally {
      setApiLoading(false);
    }
  };

  const deleteTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created at",
      dataIndex: "created_datetime_utc",
      render: (date) => convertUtcDateToLocaleDateStr(date),
    },
    {
      title: "Last updated at",
      dataIndex: "modified_datetime_utc",
      render: (date) => convertUtcDateToLocaleDateStr(date),
    },
  ];

  const renderForm = () => {
    switch (mode) {
      case MODE_LOAD:
        return (
          <>
            <label>Select target config:</label>
            <Select onChange={onChangeSelect}>
              {savedConfigs.map((config) => {
                return (
                  <Select.Option
                    style={{
                      fontSize: "2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={`savedConfig-${config.id}`}
                    value={config.value}
                  >
                    {config.label}
                  </Select.Option>
                );
              })}
            </Select>

            <div>
              {displayMessage}
              <Button
                type="primary"
                style={{ fontSize: "2rem", height: "fit-content" }}
                onClick={() => loadAppConfig()}
              >
                Load to this device
              </Button>
            </div>
          </>
        );

      case MODE_SAVE:
        return (
          <>
            <Radio.Group onChange={onChangeSaveMode} value={saveModeValue}>
              <Radio value={1}>Save as new config</Radio>
              <Radio value={2}>Overwrite existing config</Radio>
            </Radio.Group>

            {saveModeValue === 1 ? (
              <>
                <Form form={form} onFinish={onFinishNewConfig}>
                  <Form.Item
                    label="Config name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input a name for this config",
                      },
                      {
                        max: 50,
                        message: "Config name must be less than 50 characters",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>

                  {displayMessage}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ fontSize: "2rem", height: "fit-content" }}
                    loading={apiLoading}
                  >
                    Save
                  </Button>
                </Form>
              </>
            ) : saveModeValue === 2 ? (
              <>
                <label>Select target config:</label>
                <Select onChange={onChangeSelect}>
                  {savedConfigs.map((config) => {
                    return (
                      <Select.Option
                        style={{
                          fontSize: "2rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={`savedConfig-${config.id}`}
                        value={config.value}
                      >
                        {config.label}
                      </Select.Option>
                    );
                  })}
                </Select>

                <div>
                  {displayMessage}
                  <Button
                    type="primary"
                    style={{ fontSize: "2rem", height: "fit-content" }}
                    onClick={onFinishOverwriteConfig}
                    loading={apiLoading}
                  >
                    Overwrite & Save
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        );

      case MODE_DELETE:
        return (
          <>
            <Table
              rowSelection={{
                type: "checkbox",
                onChange: onChangeDeleteCheckBox,
              }}
              columns={deleteTableColumns}
              dataSource={savedConfigs}
              pagination={false}
            />
            <div>
              {displayMessage}
              <Button
                type="danger"
                style={{ fontSize: "2rem", height: "fit-content" }}
                onClick={deleteSelectedConfigs}
                loading={apiLoading}
                disabled={deleteSelectedIds.length < 1}
              >
                Delete
              </Button>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="device-setup__wrapper">
      <Radio.Group value={mode} onChange={handleModeChange} size="large">
        <Radio.Button value={MODE_LOAD}>Load</Radio.Button>
        <Radio.Button value={MODE_SAVE}>Save</Radio.Button>
        <Radio.Button value={MODE_DELETE}>Delete</Radio.Button>
      </Radio.Group>
      <Divider style={{ margin: "4px 0" }} />
      {renderForm()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  deviceConfig: state.deviceConfig,
});

const mapDispatchToProps = (dispatch) => ({
  overwriteDeviceConfig: (deviceConfig) =>
    dispatch(deviceConfigActions.overwriteDeviceConfig(deviceConfig)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSetup);
