import { Switch, Route } from "react-router-dom";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

import "./App.css";
import MTUUserPage from "./pages/mtu/mtu.component";
import AdminPage from "./pages/admin/admin.component";

function App() {
  return (
    <Switch>
      <Route exact path="/" render={() => <MTUUserPage />}></Route>
      {/* <Route exact path="/admin" render={() => <AdminPage />}></Route> */}
    </Switch>
  );
}

export default App;
