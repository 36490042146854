import axios from "axios";
import { CONST_API_URL } from "../../../appconfig";

export function GetProgressStatusAndProgressTimeService(orderNo, operationNo) {
  return axios
    .post(`${CONST_API_URL}/GetProgressStatusAndProgressTime`, {
      orderNo,
      operationNo,
    })
    .then((res) => res)
    .catch((error) =>
      console.log(`GetProgressStatusAndProgressTime Client - ${error}`)
    );
}

export function UpdateProgressStatusAndTimeService(
  orderNo,
  operationNo,
  progressStatus,
  startTime = undefined,
  endTime = undefined,
  hspopNo,
  workcenter,
  resumeJobFlag = false,
  writeToDb = false
) {
  return axios
    .post(`${CONST_API_URL}/UpdateProgressStatus`, {
      orderNo,
      operationNo,
      progressStatus,
      startTime: startTime,
      endTime: endTime,
      hspopNo: hspopNo,
      workcenter: workcenter,
      resumeJobFlag: resumeJobFlag,
      writeToDb: writeToDb,
    })
    .then()
    .catch((error) => console.log(`UpdateProgressStatus Client - ${error}`));
}

export async function updateInitialStartTime(order_no, op_no) {
  return axios
    .post(`${CONST_API_URL}/updateInitialStartTime`, {
      order_no: order_no,
      op_no: op_no,
    })
    .catch((error) => console.log(`UpdateInitialStartTime Client - ${error}`));
}

export async function updateInitialSetupStartTime(order_no, op_no) {
  return axios
    .post(`${CONST_API_URL}/updateInitialSetupStartTime`, {
      order_no: order_no,
      op_no: op_no,
    })
    .catch((error) =>
      console.log(`UpdateInitialSetupStartTime Client - ${error}`)
    );
}

export function UpdateProgressTimeService(
  orderNo,
  operationNo,
  column,
  time,
  workcenter
) {
  return axios
    .post(`${CONST_API_URL}/UpdateProgressTime`, {
      orderNo,
      operationNo,
      column,
      time,
      workcenter,
    })
    .then()
    .catch((error) => console.log(`UpdateProgressTime Client - ${error}`));
}
export function startSetupService(startSetupData) {
  return axios
    .post(`${CONST_API_URL}/StartJobSetup`, { startSetupData })
    .then((res) => res)
    .catch((error) => console.log(`StartSetup Client - ${error}`));
}

export function endSetupService(setupEnd, jobId) {
  return axios
    .post(`${CONST_API_URL}/EndJobSetup`, { setupEnd, jobId })
    .then((res) => res)
    .catch((error) => console.log(`EndSetup Client - ${error}`));
}

export function startJobTimerService(jobStart, jobId) {
  return axios
    .post(`${CONST_API_URL}/StartJobTimer`, { jobStart, jobId })
    .then(() => {
      return axios.post(`${CONST_API_URL}/GetJobTimersById`, { jobId });
    })
    .then((res) => res)
    .catch((error) => console.log(`StartJob Client - ${error}`));
}

export function updatePause(
  workcenter,
  operation_no,
  order_no,
  pause_start,
  date,
  code
) {
  return axios
    .post(`${CONST_API_URL}/UpdatePause`, {
      workcenter,
      operation_no,
      order_no,
      pause_start,
      date,
      code,
    })
    .then((res) => res)
    .catch((error) => console.log(`updatePause Client - ${error}`));
}

export function updatePauseEnd(workcenter, operation_no, order_no, pause_end) {
  return axios
    .post(`${CONST_API_URL}/UpdatePauseEnd`, {
      workcenter,
      operation_no,
      order_no,
      pause_end,
    })
    .then((res) => res)
    .catch((error) => console.log(`updatePause Client - ${error}`));
}

export function pauseAllOperations(
  workcenter,
  order_no,
  pause_reason,
  pause_start,
  time_elapsed,
  operation_no
) {
  return axios
    .post(`${CONST_API_URL}/pauseAllOperations`, {
      workcenter,
      order_no,
      pause_reason,
      pause_start,
      time_elapsed,
      operation_no,
    })
    .then((res) => res)
    .catch((error) => console.log(`pauseAllOperations Client - ${error}`));
}
