import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workcenter_name: "",
  workcenter_no: "",
  workcenter_type: 0,
  workcenterpausetype: 0,
  workcenterNonProductiveGroup: null,
  workcenterLoginSupervisorRequired: null,
  subcontractor_no: null,
  users: [],
};

const workCenterSlice = createSlice({
  name: "workCenter",
  initialState: initialState,
  reducers: {
    updateWorkCenter(state, action) {
      const {
        workcenter_name,
        workcenter_no,
        workcenter_type,
        workcenterpausetype,
        workcenterNonProductiveGroup,
        workcenterLoginSupervisorRequired,
        subcontractor_no,
        users,
      } = action.payload;
      state.workcenter_name = workcenter_name;
      state.workcenter_no = workcenter_no;
      state.workcenter_type = workcenter_type;
      state.workcenterpausetype = workcenterpausetype;
      state.workcenterNonProductiveGroup = workcenterNonProductiveGroup;
      state.workcenterLoginSupervisorRequired =
        workcenterLoginSupervisorRequired;
      state.subcontractor_no = subcontractor_no;
      state.users = users ? users : state.users;
    },
    updateUsers(state, action) {
      state.users = action.payload;
    },
  },
});

export const workCenterActions = workCenterSlice.actions;
export default workCenterSlice;
