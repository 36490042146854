import React from "react";
import "./terminal.styles.css";
import { notification, Col, Row, Descriptions, Spin } from "antd";
import ObjectCounter from "../ObjectCounter/ObjectCounter.component";
import { connect } from "react-redux";
import JobTimer from "../JobTimer/JobTimer.component";
import SupervisorPermission from "../../SupervisorPermissionModal/SupervisorPermission.component";
import QcInspectionButton from "../QcInspectionButton/QcInspectionButton.component";
import MOSidebarContent from "../MOSidebar/MOSidebarContent.component";
import PauseModalContent from "../PauseModal/PauseModalContent.component";
import { Modal } from "antd";
import OperationsDataScroller from "../OperationsDataScroller/OperationsDataScroller.component";
import { Card } from "primereact/card";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import axios from "axios";
import POCButton from "../ConsumptionModal/openButton";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import moment from "moment";
import { checkReqSupervisorStartUp } from "../../SupervisorPermissionModal/SupervisorPermission.function";
import DrawingDialog from "./drawingDialog";
import { CONST_API_URL } from "../../../appconfig";
import NonProductiveBody from "../nonProd.component";
import _ from "lodash";
import ConsumptionModal from "../MultipleConsumptionModal/consumption.component";
import { getComponentItems } from "../MultipleConsumptionModal/consumption.service";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { checkSupervisorApprovalRequired } from "../../SupervisorPermissionModal/SupervisorPermission.service";
import { GetProgressStatusAndProgressTimeService } from "../JobTimer/JobTimer.service";

const PAUSECODENO_FULLQTY = 35;

class Boxes extends React.Component {
  constructor(props) {
    super(props);
    this.POCButtonRef = React.createRef();
    this.JobTimerRef = React.createRef();
    this.MOSidebarRef = React.createRef();

    this.state = {
      //Current values to display on UI
      currentHspop: "",
      currentComponent: "",
      currentOperation: "",
      currentOperationChar: "",
      currentRoutingDescription: "",
      currentItemNo: "",
      currentMO: "",
      currentPartCount: 0,
      currentScrapCount: 0,
      selectedUserForPOC: "",
      currentFinishedOperations: 0,

      //Arrays to store values from backend fetches
      hspops: [],
      boms: [],
      operations: [],
      operationsChar: [],
      routingDescriptions: [],
      OperationNoChar: [],
      operationsMerged: [],
      heatNumberArray: [],

      //States to store values from backend fetches
      requiredQuantity: "",
      nextWorkCenter: "",
      isStartingOperation: false,

      //States to determine whether to show modals/popups or not
      displayBasic: false,
      displayMOModal: false,
      displayPauseModal: false,
      showSupervisorPopup: false,
      showDrawingModal: false,
      loadingNextWorkCenter: false,
      displayConsumptionModal: false,
      displayComponentDetailModal: false,
      displayHeatNoListModal: false,
      componentDataLoading: false,
      componentTableData: [],
      fetchDataLoading: false,
      operationsMergedLoading: false,

      //States to determine whether to show UI as blocked or not
      blockedPanel: true,
      blockedPanel2: true,
      finishBlockedPanel: true,
      blockIfNotSignedIn: !this.props.user || this.props.user.length === 0,
      blockIfNoSupervisorPermission: true,

      //States used during the inspection process
      supervisorApproved: false,
      inspectionCompleted: false,
      supervisorPopupType: "Start Job",
      inspectionEnabled: false,

      //States used by the job timer
      finishJobTimerFlag: false,
      getRunTimeFlag: false,
      progressSetupTime: 0,
      progressStatus: 0,
      jobTimerId: null,
      endJobCode: 0, //1: Job keep running, 2: pause job
      partCount: 0,

      //States used by the consumption modal
      scrapCount: 0,
      POCPartCount: 0,
      conditionalPartCount: 0,

      //State used by MOSidebar for redirecting after pause
      nextMO: "",
    };
    this.initialState = { ...this.state };

    //Bound Job Timer Handlers
    this.showFinishJob = this.showFinishJob.bind(this);

    //Bound Operation Scroller Handlers
    this.operationScrollerMOHandler =
      this.operationScrollerMOHandler.bind(this);

    //Bound multi-functional handler for click events
    this.onClick = this.onClick.bind(this);

    //Bound multi-functional handler for modals
    this.onHide = this.onHide.bind(this);

    //Bound Consumption Dialog Handlers
    this.onUpdateSeletedUserForPOC = this.onUpdateSeletedUserForPOC.bind(this);
    this.fetchSelectedHeatNumber = this.fetchSelectedHeatNumber.bind(this);
    this.handlePauseStateFromModal = this.handlePauseStateFromModal.bind(this); //Bound handler for actions from pause reason modal

    //Bound Modal Visibillity Handlers
    this.handlePauseModal = this.handlePauseModal.bind(this);

    //Bound Order Handlers
    this.MOHandler = this.MOHandler.bind(this);
    this.nextMOHandler = this.nextMOHandler.bind(this);

    //Bound Counter Handlers
    this.onUpdateCurrentPartCount = this.onUpdateCurrentPartCount.bind(this);
    this.onUpdatePOCPartCount = this.onUpdatePOCPartCount.bind(this);
    this.scrapCountHandler = this.scrapCountHandler.bind(this);
    this.partCountHandler = this.partCountHandler.bind(this);
    this.onUpdateConditionalPartCount =
      this.onUpdateConditionalPartCount.bind(this);

    //Bound UI Blocker Functions
    this.blockedUIHandler = this.blockedUIHandler.bind(this);
  }

  showConfirmationDialog(override = false, nextWorkCenter) {
    if (override) {
      confirmDialog({
        message: (
          <div style={{ width: "100%" }}>
            <Descriptions bordered={true} colon={false} column={1}>
              <Descriptions.Item
                label={
                  <h2>
                    <b>Quantity Complete</b>
                  </h2>
                }
              >
                <h2>{this.state.requiredQuantity}</h2>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <h2>
                    <b>Quantity Remaining</b>
                  </h2>
                }
              >
                <h2>
                  {parseInt(this.state.requiredQuantity) -
                    parseInt(this.state.currentPartCount)}
                </h2>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <h2>
                    <b>Part Count</b>
                  </h2>
                }
              >
                <h2>{Math.round(this.state.currentPartCount)}</h2>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <h2>
                    <b>Scrap Count</b>
                  </h2>
                }
              >
                <h2>{this.state.currentScrapCount}</h2>
              </Descriptions.Item>
            </Descriptions>
          </div>
        ),
        header:
          nextWorkCenter === "" ? (
            <div>
              {" "}
              <p
                style={{
                  fontSize: "3rem",
                  margin: "0",
                  padding: "0",
                  color: "#D32F2F",
                }}
              >
                This is the last workcenter.
              </p>
              <span style={{ fontSize: "3rem" }}>
                Do you want to finish MO {`[${this.state.currentMO}]`}?
              </span>
            </div>
          ) : (
            <span style={{ fontSize: "3rem" }}>
              Are you sure you want to move MO {`[${this.state.currentMO}]`} to
              Work Center {`[${nextWorkCenter}]`}?
            </span>
          ),
        accept: () => {
          if (this.props.user?.length < 1) {
            return notification.error({
              message: "Failed",
              description: "Login required to finish a job.",
              duration: 10,
              style: {
                width: "40vw",
              },
            });
          } else if (
            !!!this.props.workcenter?.subcontractor_no &&
            this.state.progressStatus !== 3
          ) {
            return notification.error({
              message: "Failed",
              description:
                "Operation can only be finished when job is running.",
              duration: 10,
              style: {
                width: "40vw",
              },
            });
          }

          this.compareQuantity();
        },
      });
    } else {
      if (
        Number(this.state.requiredQuantity) > 0 &&
        Number(this.state.requiredQuantity) <=
          Number(this.state.currentPartCount)
      ) {
        confirmDialog({
          message: (
            <p style={{ fontSize: "2rem", fontWeight: "500" }}>
              Quantity Complete: {this.state.requiredQuantity}
              <br />
              Quantity Remaining:{" "}
              {parseInt(this.state.requiredQuantity) -
                parseInt(this.state.currentPartCount)}
              <br />
              Part Count: {Math.round(this.state.currentPartCount)}
              <br />
              Scrap Count: {this.state.currentScrapCount}
              <br />
            </p>
          ),
          header: (
            <span style={{ fontSize: "3rem" }}>
              Would you like to finish MO {this.state.currentMO}?
            </span>
          ),
          accept: () => {
            if (this.props.user?.length < 1) {
              return notification.error({
                message: "Failed",
                description: "Login required to finish a job.",
                duration: 10,
                style: {
                  width: "40vw",
                },
              });
            } else if (
              !!!this.props.workcenter?.subcontractor_no &&
              this.state.progressStatus !== 3
            ) {
              return notification.error({
                message: "Failed",
                description:
                  "Operation can only be finished when job is running.",
                duration: 10,
                style: {
                  width: "40vw",
                },
              });
            }

            this.compareQuantity();
          },
        });
      }
    }
  }

  clearAllState = () => {
    let tempInitialState = this.initialState;
    delete tempInitialState.blockIfNotSignedIn;
    delete tempInitialState.blockedPanel2;
    delete tempInitialState.blockIfNoSupervisorPermission;
    delete tempInitialState.finishBlockedPanel;
    this.setState(tempInitialState);
  };

  componentDidUpdate(prevProps, prevStates) {
    if (this.state.progressSetupTime !== prevStates.progressSetupTime) {
      // If progressSetupTime changed, then block
      this.checkInspection();
    }

    if (
      !_.isEqual(
        this.props.workcenter?.workcenter_no,
        prevProps.workcenter?.workcenter_no
      )
    ) {
      this.clearAllState();
      this.setState({ blockedPanel: true });
      //  this.setState({ blockIfNotSignedIn: true });
    }

    if (
      this.state.currentPartCount !== prevStates.currentPartCount &&
      this.state.currentMO === prevStates.currentMO &&
      this.state.currentOperation === prevStates.currentOperation &&
      this.state.operationsMerged?.length > 1
    ) {
      this.reloadQuantityForOperationsMerged();
    }

    if (
      this.state.currentPartCount !== prevStates.currentPartCount ||
      this.state.currentMO !== prevStates.currentMO ||
      this.state.currentOperation !== prevStates.currentOperation
    ) {
      //Update OperationsMerged Array
      let operationsMergedCopy = this.state.operationsMerged;

      let target = operationsMergedCopy.find(
        (element) => element.opNo === this.state.currentOperation
      );
      let indexOfTarget = operationsMergedCopy.indexOf(target);

      if (target) {
        target.part_count = this.state.currentPartCount;
        operationsMergedCopy[indexOfTarget] = target;
        this.setState({ operationsMerged: operationsMergedCopy });
      }

      if (
        this.state.currentPartCount &&
        this.state.currentPartCount >= this.state.requiredQuantity
      ) {
        //Perform another operation array check
        let operationsCompleted = 0;
        for (let i = 0; i < this.state.operationsMerged.length; i++) {
          if (
            this.state.operationsMerged[i].quantity !== 0 &&
            this.state.operationsMerged[i].part_count >=
              this.state.operationsMerged[i].quantity
          ) {
            operationsCompleted++;
          }
        }
        this.setState({ currentFinishedOperations: operationsCompleted });

        // Last operation in the current workcenter
        let allOperationsDone = true;
        let operations = this.state.operationsMerged;
        for (let i = 0; i < operations.length; i++) {
          if (
            operations[i].part_count === 0 ||
            operations[i].part_count < operations[i].quantity
          ) {
            allOperationsDone = false;
          }
        }
        if (
          allOperationsDone &&
          this.state.progressStatus === 3 &&
          this.props.user?.length > 0
        ) {
          console.log("*** CONFIRM ***");
          this.showConfirmationDialog();
        } else if (
          !allOperationsDone &&
          this.state.timerState === 3 &&
          this.state.currentPartCount !== prevStates.currentPartCount &&
          this.state.currentMO === prevStates.currentMO &&
          this.state.currentOperation === prevStates.currentOperation
        ) {
          // Pause current job when it hits total quantity and not the last operation
          console.log("*** PAUSE ***");
          this.handlePauseStateFromModal(PAUSECODENO_FULLQTY, false);
        }
      }
    }

    // User Changes
    if (prevProps.user !== this.props.user) {
      if (!this.props.user || this.props.user.length === 0) {
        this.setState({ blockIfNotSignedIn: true });
      } else {
        this.setState({ blockIfNotSignedIn: false });
      }
    }
  }

  onClick(name, position) {
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      };
    }

    this.setState(state);
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false,
    });
  }

  blockPanel = () => this.setState({ blockedPanel: true });
  unblockPanel = () => this.setState({ blockedPanel: false });

  blockedUIHandler(value) {
    if (value !== this.state.blockedPanel2) {
      this.setState({ blockedPanel2: value });
    }
  }

  getCurrentDate() {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + " " + time;

    return dateTime;
  }

  async MOHandler(MO, operationData, customerCode) {
    try {
      console.log("MOHandler", { MO, operationData, customerCode });
      let operationMerged = [];
      let completedOperations = 0;

      this.setState({ fetchDataLoading: true });

      const _operationData = _.cloneDeep(operationData);
      const promises = [];
      for (let i = 0; i < _operationData.length; i++) {
        const index = i; // i is already end of length when the responses are back
        const promise = axios
          .post(`${CONST_API_URL}/GetPartScrapCount`, {
            MO: MO,
            OperationNo: _operationData[index].operationNoChar,
          })
          .then((res) => {
            operationMerged.push({
              opNo: _operationData[index].operationNo,
              opNoChar: _operationData[index].operationNoChar,
              description: _operationData[index].routingDescription,
              part_count:
                res.data.currPartCount ?? _operationData[index].part_count,
              quantity: res.data.totalCount ?? _operationData[index].quantity,
              hspop: _operationData[index].hspop_no,
              component: _operationData[index].component_no_str,
            });
          });

        promises.push(promise);
      }
      await Promise.all(promises);

      //Check for completed operations & Sort (due to async posted quantity data loading for each operation)
      operationMerged.forEach((obj) => {
        if (obj.quantity !== 0 && obj.part_count >= obj.quantity) {
          completedOperations++;
        }
      });
      operationMerged.sort((a, b) =>
        Number(a.opNo) > Number(b.opNo) ? 1 : -1
      );

      const newState = {
        currentMO: MO,
        displayMOModal: false,
        routingDescriptions: _operationData.map((el) => el.routingDescription),
        hspops: _operationData.map((el) => el.hspop_no),
        component: _operationData.map((el) => el.component_no_str),
        currentOperation: _operationData[0].operationNo,
        currentOperationChar: _operationData[0].operationNoChar,
        currentRoutingDescription: _operationData[0].routingDescription,
        currentHspop: _operationData[0].hspop_no,
        currentComponent: _operationData[0].component_no_str,
        operations: _operationData.map((el) => el.operationNo),
        OperationNoChar: _operationData.map((el) => el.operationNoChar),
        operationsMerged: operationMerged,
        blockedPanel: false,
        blockedPanel2: true,
        supervisorApproved: false,
        inspectionCompleted: false,
        currentFinishedOperations: completedOperations,
        customerCode: customerCode,
      };
      await this.fetchAllTest(
        MO,
        _operationData[0].operationNo,
        newState,
        _operationData[0].operationNoChar
      );
    } finally {
      this.setState({ fetchDataLoading: false });
    }
  }

  nextMOHandler(value) {
    this.setState({ nextMO: value });
  }

  operationScrollerMOHandler = async (
    operationNo,
    operationNoChar,
    hspopChar
  ) => {
    await this.fetchAllTest(
      this.state.currentMO,
      operationNo,
      {
        currentOperation: operationNo,
        currentHspop: hspopChar,
      },
      operationNoChar
    );
  };

  reloadQuantityForOperationsMerged = async () => {
    try {
      this.setState({ operationsMergedLoading: true });
      const promises = [];
      const _operationsMerged = _.cloneDeep(this.state.operationsMerged);

      for (let i = 0; i < _operationsMerged.length; i++) {
        const promise = axios
          .post(`${CONST_API_URL}/GetPartScrapCount`, {
            MO: this.state.currentMO,
            OperationNo: _operationsMerged[i].opNoChar,
          })
          .then((res) => {
            if (res.status === 200) {
              _operationsMerged[i].part_count = res.data.currPartCount;
              _operationsMerged[i].quantity = res.data.totalCount;
            }
          });

        promises.push(promise);
      }

      await Promise.all(promises);
      this.setState({ operationsMerged: _operationsMerged });
    } catch (error) {
      console.log("ERROR - reloadQuantityForOperationsMerged", error);
    } finally {
      this.setState({ operationsMergedLoading: false });
    }

    // try {
    //   this.setState({ fetchDataLoading: true, operationsMergedLoading: true });
    //   const promises = [];
    //   const _operationsMerged = _.cloneDeep(this.state.operationsMerged);

    //   let currentPartCount = this.state.currentPartCount;
    //   let currentScrapCount = this.state.currentScrapCount;
    //   let requiredQuantity = this.state.requiredQuantity;

    //   for (let i = 0; i < _operationsMerged.length; i++) {
    //     const promise = axios
    //       .post(`${CONST_API_URL}/GetPartScrapCount`, {
    //         MO: this.state.currentMO,
    //         OperationNo: _operationsMerged[i].opNoChar,
    //       })
    //       .then((res) => {
    //         if (res.status === 200) {
    //           if (
    //             _operationsMerged[i].opNoChar ===
    //             this.state.currentOperationChar
    //           ) {
    //             currentPartCount = res.data.currPartCount;
    //             currentScrapCount = res.data.currScrapCount;
    //             requiredQuantity = res.data.totalCount;
    //           }
    //           _operationsMerged[i].part_count = res.data.currPartCount;
    //           _operationsMerged[i].quantity = res.data.totalCount;
    //         }
    //       });

    //     promises.push(promise);
    //   }

    //   await Promise.all(promises);
    //   this.setState({
    //     operationsMerged: _operationsMerged,
    //     currentPartCount: currentPartCount,
    //     currentScrapCount: currentScrapCount,
    //     requiredQuantity: requiredQuantity,
    //   });
    // } catch (error) {
    //   console.log("ERROR - reloadQuantityForOperationsMerged", error);
    // } finally {
    //   this.setState({ operationsMergedLoading: false });
    // }
  };

  convertClockDigit(value) {
    const zero = "0";
    return /^\d$/.test(value) ? zero.concat(value) : value;
  }

  renderSetupTime(value) {
    const hour = this.convertClockDigit(Math.floor(value / 3600000));
    const minute = this.convertClockDigit(
      Math.floor((value % 3600000) / 60000)
    );
    const second = this.convertClockDigit(
      Math.floor(((value % 3600000) % 60000) / 1000)
    );
    return hour + ":" + minute + ":" + second;
  }

  async fetchAllTest(
    MO,
    OperationNo,
    stateToUpdateTogether = undefined,
    operationNoChar
  ) {
    try {
      console.log("fetchAllTest", {
        MO,
        OperationNo,
        stateToUpdateTogether,
        operationNoChar,
      });
      this.setState({ fetchDataLoading: true });

      let currentPartCount,
        currentScrapCount,
        progressSetupTime,
        heatNumberArray,
        currentItemNo,
        currentRoutingDescription,
        requiredQuantity,
        isStartingOperation,
        timerState = 0;
      const promise1 = axios
        .post(`${CONST_API_URL}/GetPartScrapCount`, {
          MO: MO,
          // OperationNo: OperationNo,
          OperationNo: operationNoChar,
        })
        .then((res) => {
          if (res.status === 200) {
            currentPartCount = res.data.currPartCount;
            currentScrapCount = res.data.currScrapCount;
            requiredQuantity = res.data.totalCount;
            isStartingOperation = res.data.isStartingOperation;
          }
        })
        .catch((error) => console.log(`GetPartScrapCount - ${error}`));

      const promise2 = axios
        .post(`${CONST_API_URL}/GetProgressSetupTime`, {
          MO: MO,
          OperationNo: OperationNo,
        })
        .then((res) => {
          if (res.status === 200) {
            progressSetupTime = res.data[0].progress_setup_time;
          }
        })
        .catch((error) => console.log(`GetProgressSetupTime - ${error}`));

      const promise3 = axios
        .post(
          `${CONST_API_URL}/consumption/GetLastPostHeatNumberAndLocationCode`,
          {
            MO: MO,
            operationNo: OperationNo,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            heatNumberArray = res.data;
          }
        })
        .catch((error) => console.log(`GetSelectedHeatNumber - ${error}`));

      const promise4 = axios
        .post(`${CONST_API_URL}/GetItemNumber`, {
          MO: MO,
        })
        .then((res) => {
          currentItemNo = res.data[0].item_no;
        })
        .catch((error) => console.log(`GetItemNumber - ${error}`));

      const promise5 = axios
        .post(`${CONST_API_URL}/GetRoutingDescription`, {
          MO: MO,
          OperationNo: OperationNo,
        })
        .then((res) => {
          if (res.status === 200) {
            currentRoutingDescription = res.data[0].routing_description;
          }
        })
        .catch((error) => console.log(`GetRoutingDescription - ${error}`));

      // To load the correct Timer status (progress_status) when switching operation in order to catch the state changes correctly
      const promise6 = GetProgressStatusAndProgressTimeService(MO, OperationNo)
        .then((res) => {
          if (res?.data) {
            timerState = res.data[0].progress_status ?? 0;
          }
        })
        .catch((error) =>
          console.log(`GetProgressStatusAndProgressTimeService - ${error}`)
        );

      // const promise6 = axios
      //   .post(`${CONST_API_URL}/GetRequiredQuantity`, {
      //     MO: MO,
      //     OperationNo: OperationNo,
      //   })
      //   .then((res) => {
      //     requiredQuantity = res.data[0].quantity;
      //   })
      //   .catch((error) => console.log(`GetRequiredQuantity - ${error}`));

      // const promise7 = axios
      //   .post(`${CONST_API_URL}/getNextWorkcenter`, {
      //     orderNo: MO,
      //     operationNoChar: operationNoChar,
      //   })
      //   .then((res) => {
      //     console.log("DATAAAAAAAAA: ", res.data);
      //     nextWorkCenter = res.data;
      //   })
      //   .catch((error) => {
      //     console.log(`getNextWorkcenter - ${error}`);
      //   });

      await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        // promise7,
      ]);

      if (stateToUpdateTogether) {
        this.setState({
          ...stateToUpdateTogether,
          currentPartCount,
          currentScrapCount,
          progressSetupTime,
          heatNumberArray,
          currentItemNo,
          currentRoutingDescription,
          requiredQuantity,
          isStartingOperation,
          timerState,
        });
      } else {
        this.setState({
          currentPartCount,
          currentScrapCount,
          progressSetupTime,
          heatNumberArray,
          currentItemNo,
          currentRoutingDescription,
          requiredQuantity,
          isStartingOperation,
          timerState,
        });
      }
    } catch (error) {
      console.log("ERROR - fetchAllTest", error);
    } finally {
      this.setState({ fetchDataLoading: false });
    }
  }

  fetchSelectedHeatNumber(MO) {
    //console.log(MO);
    axios
      .post(
        `${CONST_API_URL}/consumption/GetLastPostHeatNumberAndLocationCode`,
        {
          MO: MO,
          operationNo: this.state.currentOperation,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // const heatNoArr = res.data.map((obj) => obj.heat_no);
          this.setState({
            heatNumberArray: res.data,
            // selectedHeatNumbers: res.data
            //   .map((obj) => obj.heat_no)
            //   .filter((obj) => !!obj),
          });
        }
      })
      .catch((error) => console.log(`GetSelectedHeatNumber - ${error}`));
  }

  fetchComponentData = async () => {
    try {
      this.setState({ componentDataLoading: true });
      const res = await axios.get(`${CONST_API_URL}/componentItem`, {
        params: { order_no: this.state.currentMO },
      });
      if (res?.data?.value) {
        this.setState({ componentTableData: res.data.value });
      }
    } finally {
      this.setState({ componentDataLoading: false });
    }
  };

  partCountHandler(count) {
    this.setState({ partCount: count });
  }

  scrapCountHandler(count) {
    this.setState({ scrapCount: count });
  }

  compareQuantity() {
    axios
      .post(`${CONST_API_URL}/GetRequiredQuantity`, {
        MO: this.state.currentMO.toString(),
        OperationNo: this.state.currentOperation.toString(),
      })
      .then((res) => {
        if (res.status === 200) {
          // const jobEnd = moment().utc().format("YYYY-MM-DD HH:mm:ss:SSS");

          //Finish current MO
          this.FinishCurrentMO();
        }
      })
      .catch((error) => console.log(`GetRequiredQuantity - ${error}`));
  }

  showFinishJob(jobId) {
    this.setState({ jobTimerId: jobId });
  }

  FinishCurrentMO() {
    axios
      .post(CONST_API_URL + "/FinishCurrentMO", {
        MO: this.state.currentMO,
        OperationNo: this.state.OperationNoChar.at(-1),
        workcenter: this.props.workcenter?.workcenter_no,
        quantity:
          this.state.currentPartCount > this.state.requiredQuantity
            ? this.state.currentPartCount
            : 0,
        location: this.props.division,
      })
      .then((res) => {
        if (res.data.error === true) {
          notification.error({
            message: "Failed",
            description: res.data.message,
            duration: 10,
            style: {
              width: "40vw",
            },
          });
        } else {
          window.location.reload();
        }
      });
  }

  POCButtonOnClick = async () => {
    if (
      this.props.workcenter.workcenter_name !== "" &&
      this.props.workcentertype === 1
    ) {
      try {
        const res = await axios.post(
          `${CONST_API_URL}/consumption/ComponentItems`,
          {
            MO: this.state.currentMO,
            // workCenter: this.props.workcenter,
            // partNo: this.state.partNo,
          }
        );

        if (res.data?.length > 1) {
          this.setState({ displayConsumptionModal: true });
        } else {
          this.POCButtonRef.current.handleButtonClick();
        }
      } catch (error) {
        console.log("ERROR - POCButtonOnClick", error);
      }
    }
  };

  handlePauseStateFromModal(code, pauseAllOperations) {
    this.JobTimerRef.current.handlePauseState(code, pauseAllOperations);
  }

  passProgressSetupTime(value) {
    this.setState({ progressSetupTime: value });
  }

  passProgressStatus(value) {
    this.setState({ progressStatus: value });
  }

  onUpdateCurrentPartCount(value) {
    this.setState({ currentPartCount: value });
  }

  refreshPartScrapTotalCount = async () => {
    try {
      this.setState({ fetchDataLoading: true });
      const res = await axios.get(`${CONST_API_URL}/getPartCountData`, {
        params: {
          order_no: this.state.currentMO,
          operation_no_char: this.state.currentOperationChar,
        },
      });
      if (res?.data) {
        const { currPartCount, currScrapCount, totalCount } = res.data;
        this.setState({
          currentPartCount: currPartCount,
          currentScrapCount: currScrapCount,
          requiredQuantity: totalCount,
        });
      }
    } catch (error) {
      console.log("ERROR - refreshPartScrapTotalCount", error);
    } finally {
      this.setState({ fetchDataLoading: false });
    }
  };

  onUpdatePOCPartCount(value) {
    this.setState({ POCPartCount: value });
  }

  onUpdateSeletedUserForPOC(value) {
    this.setState({ selectedUserForPOC: value });
  }

  onUpdateConditionalPartCount(value) {
    this.setState({ conditionalPartCount: value });
  }

  requestSupervisorApproval = async () => {
    // let result = await checkReqSupervisorStartUp(
    //   this.props.workcenter?.workcenter_no,
    //   this.state.currentHspop
    // );
    // if (!result) {
    //   this.setState({
    //     showSupervisorPopup: true,
    //     supervisorPopupType: "Start Job",
    //   });
    // } else {
    //   this.setState({
    //     supervisorApproved: true,
    //   });
    // }
    try {
      const result = await checkSupervisorApprovalRequired(
        this.props.workcenter?.workcenter_no,
        this.state.currentHspop
      );
      if (result) {
        // Supervisor approval required
        this.setState({
          showSupervisorPopup: true,
          supervisorPopupType: "Start Job",
        });
      } else {
        // Supervisor approval is not required
        this.setState({
          supervisorApproved: true,
        });
      }
    } catch (error) {
      console.log("ERROR - requestSupervisorApproval", error);
      notification.error({
        message: "Error",
        description: "Unexpected network issue occurred, please try again",
        style: {
          width: "40vw",
        },
      });
    }
  };

  checkInspection = () => {
    const { progressSetupTime } = this.state;
    this.setState({
      inspectionEnabled: progressSetupTime ? true : false,
      supervisorPopupType: "QC Inspection",
    });
  };

  onSuccessSupervisorPopup = (supervisor) => {
    const { supervisorPopupType } = this.state;
    if (supervisorPopupType === "Start Job") {
      this.setState(
        {
          showSupervisorPopup: false,
          supervisorApproved: true,
          supervisorPopupType: "QC Inspection",
        },
        notification.success({
          message: "Access Granted",
          description: `Your permission was granted by ${supervisor}`,
          style: {
            width: "40vw",
          },
        })
      );
    } else if (supervisorPopupType === "QC Inspection") {
      this.setState({
        showSupervisorPopup: false,
        inspectionCompleted: true,
      });
      notification.success({
        message: "Inspection Completed",
        description: `Your QC Inspection was approved by ${supervisor}`,
        style: {
          width: "40vw",
        },
      });
    } else if (supervisorPopupType === "Posting Check - Lower Limit Exceeded") {
      this.moveOrderToNextWorkCenter();
      this.setState({
        showSupervisorPopup: false,
        inspectionCompleted: true,
      });
      notification.success({
        message: "Access Granted",
        description: `Your permission was granted by ${supervisor}`,
        style: {
          width: "40vw",
        },
      });
    }
  };

  handlePassEndJobCode = (value) => {
    return new Promise((resolve, reject) => {
      this.setState({ endJobCode: value }, () => {
        resolve(true);
      });
    });
  };

  fetchInspectionStatus = (order_no, operation_no) => {
    axios
      .post(`${CONST_API_URL}/getInspectionStatus`, { order_no, operation_no })
      .then((res) => {})
      .catch(() => {
        notification.error({
          message: "Server Error",
          description: "Unabled to fetch Inspection Status.",
          style: {
            width: "40vw",
          },
        });
      });
  };

  updateInspectionStatus = (order_no, operation_no, name) => {
    axios
      .post(`${CONST_API_URL}/updateInspectionStatus`, {
        order_no,
        operation_no,
      })
      .then(() => {
        notification.success({
          message: "Inspection Verified",
          description: `Your inspection completed by ${name}`,
          style: {
            width: "40vw",
          },
        });
      })
      .catch(() => {
        notification.error({
          message: "Server Error",
          description: "Unable to update Inspection Status.",
          style: {
            width: "40vw",
          },
        });
      });
  };

  handlePauseModal(value) {
    let next = this.state.nextMO;

    this.setState({
      displayPauseModal: value,
    });
    //Switch if necessary
    if (value === false && next !== "") {
      // console.log("handlePauseModal TEST", { next });
      this.MOHandler(next.label, next.operationData, next.customer_name);

      //Change selectedMO in MOSidebar
      this.MOSidebarRef.current.handleSelectedMOChange(next);

      this.setState({ nextMO: "" });
    }
  }

  async moveOrderToNextWorkCenter(skipCheckFg = false) {
    //Check if all operations were posted to
    let operations = this.state.operationsMerged;
    let postedToAll = true;
    let operationsNotPosted = [];

    this.setState({ loadingNextWorkCenter: true });

    axios
      .post(`${CONST_API_URL}/getNextWorkcenter`, {
        orderNo: this.state.currentMO,
        operationNoChar: this.state.currentOperationChar,
      })
      .then((res) => {
        this.setState({ loadingNextWorkCenter: false });
        console.log("DATAAAAAAAAA: ", res.data);
        let nextWorkCenter = res.data;

        for (let i = 0; i < operations.length; i++) {
          console.log(operations[i].part_count);
          if (operations[i].part_count !== operations[i].quantity) {
            if (operations[i].part_count < 1) {
              postedToAll = false;
              operationsNotPosted.push(operations[i].hspop);
            }
          }
        }
        if (postedToAll || skipCheckFg) {
          this.showConfirmationDialog(true, nextWorkCenter);
        } else {
          console.log(operationsNotPosted);
          notification.error({
            message: "Error",
            description: (
              <>
                <p>
                  Cannot move to next workcenter until all operations have been
                  posted to.
                </p>
                <p>
                  Check the following operations: <br></br>
                  {operationsNotPosted.map((operation) => {
                    return (
                      <span style={{ color: "red" }}>
                        #{operation}
                        <br></br>
                      </span>
                    );
                  })}
                </p>
              </>
            ),
            duration: 15,
            style: {
              width: "40vw",
            },
          });
        }
      })
      .catch((error) => {
        console.log(`getNextWorkcenter - ${error}`);
        this.setState({ loadingNextWorkCenter: false });
        // notification.error({
        //   message: "Server Error",
        //   description: "Unable to get next work center.",
        //   style: {
        //     width: "40vw",
        //   },
        // });
        this.showConfirmationDialog();
      });
  }

  handleOnClickCompleteOperation = () => {
    if (!this.state.loadingNextWorkCenter) {
      //Check if supervisor required
      // if (this.props.workcentertype === 1) {
      let lowerBound = Math.floor(this.state.requiredQuantity * 0.95);

      if (this.state.currentPartCount < lowerBound) {
        console.log("Opening supervisor modal");
        return this.setState({
          showSupervisorPopup: true,
          supervisorPopupType: "Posting Check - Lower Limit Exceeded",
        });
      }
      // }

      this.moveOrderToNextWorkCenter();
    }
  };

  renderHeatNoList = (heatNoList) => {
    if (!Array.isArray(heatNoList) || heatNoList.length === 0) {
      return "TBD";
    }
    const heatNoStrList = heatNoList.map((obj) => obj.heat_no);

    const stringifiedList =
      heatNoList.length <= 3
        ? heatNoStrList.join(", ")
        : `${heatNoStrList.slice(0, 3).join(", ")}, ...`;

    return (
      <>
        {stringifiedList}
        {heatNoList.length > 3 && (
          <>
            <span
              style={{
                color: "#d32f2f",
                marginLeft: "0.4em",
                fontWeight: "500",
              }}
              onClick={() => {
                this.onClick("displayHeatNoListModal");
              }}
              onTouchEnd={(e) => {
                e.preventDefault();
                this.onClick("displayHeatNoListModal");
              }}
            >
              {`See All`}
              <i className="pi pi-angle-down"></i>
            </span>

            <Dialog
              visible={this.state.displayHeatNoListModal}
              style={{ minWidth: "50vw" }}
              onHide={() => {
                this.onHide("displayHeatNoListModal");
              }}
              dismissableMask={true}
              header={
                <h1
                  style={{
                    fontSize: "2em",
                    color: "#d32f2f",
                    fontWeight: "bold",
                  }}
                >
                  Used Heat Numbers
                </h1>
              }
            >
              <div>
                <DataTable
                  value={heatNoList}
                  style={{ fontSize: "4rem" }}
                  autoLayout
                >
                  <Column
                    header="#"
                    headerStyle={{ width: "3rem" }}
                    body={(data, options) => options.rowIndex + 1}
                  ></Column>
                  <Column field="heat_no" header="Heat Number" />
                  <Column field="location_no" header="Location" />
                </DataTable>
              </div>
            </Dialog>
          </>
        )}
      </>
    );
  };

  render() {
    window.oncontextmenu = () => false;

    return (
      <>
        <DrawingDialog
          visible={this.state.showDrawingModal}
          onHide={() => this.setState({ showDrawingModal: false })}
          title={"Drawing"}
          customerCode={this.state.customerCode}
          itemNo={this.state.currentItemNo}
        />
        <Modal
          visible={this.state.displayPauseModal}
          onHide={() => this.setState({ displayPauseModal: false })}
          onCancel={() => {
            this.setState({ displayPauseModal: false });
          }}
          width={window.innerWidth}
          closable={false}
          modal={false}
          footer={null}
          wrapClassName="zIndex2200"
          bodyStyle={{ height: window.innerHeight * 0.7, zIndex: 3001 }}
        >
          <PauseModalContent
            // workcenter={this.props.workcenter}
            operation_no={this.state.currentOperation}
            order_no={this.state.currentMO}
            handlePauseModal={this.handlePauseModal}
            handlePauseStateFromModal={this.handlePauseStateFromModal}
          />
        </Modal>
        <Dialog
          className="dialogBox"
          visible={this.state.displayBasic}
          style={{ width: "50vw" }}
          onHide={() => this.onHide("displayBasic")}
          dismissableMask={true}
          blockScroll={true}
          header={
            <h1
              style={{
                fontSize: "3em",
                color: "#d32f2f",
                fontWeight: "bold",
              }}
            >
              Routing Description:
            </h1>
          }
        >
          <div
            className="routingDescription"
            //  onClick={() => {
            //     this.onHide("displayBasic");
            //  }}
          >
            <p
              style={{
                fontSize: "2em",
                color: "black",
                whiteSpace: "pre-line",
              }}
            >
              {" "}
              {this.state.currentRoutingDescription
                ? this.state.currentRoutingDescription
                : ""}
            </p>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.displayComponentDetailModal}
          style={{ minWidth: "50vw" }}
          onHide={() => {
            this.onHide("displayComponentDetailModal");
            this.setState({ componentTableData: [] });
          }}
          dismissableMask={true}
          // blockScroll={true}
          header={
            <h1
              style={{
                fontSize: "2em",
                color: "#d32f2f",
                fontWeight: "bold",
              }}
            >
              Material/Component Detail
            </h1>
          }
        >
          <div>
            <DataTable
              value={this.state.componentTableData}
              style={{ fontSize: "2em" }}
              autoLayout
              loading={this.state.componentDataLoading}
            >
              <Column field="ComponentItemNo" header="No" />
              <Column field="ComponentItemDescription" header="Description" />
            </DataTable>
          </div>
        </Dialog>
        <POCButton
          progressSetupTime={this.state.progressSetupTime}
          hspop={this.state.currentHspop}
          operationNo={this.state.currentOperation}
          operationNoChar={this.state.currentOperationChar}
          workcenterNo={this.props.workcenter?.workcenter_no}
          MO={this.state.currentMO}
          user={this.state.selectedUserForPOC}
          partCount={this.state.POCPartCount}
          scrapCount={this.state.scrapCount}
          ref={this.POCButtonRef}
          // onClick={() => this.POCButtonOnClick()}
          onSubmit={() => this.showConfirmationDialog()}
          onUpdateCurrentPartCount={this.onUpdateCurrentPartCount}
          refreshPartScrapTotalCount={this.refreshPartScrapTotalCount}
          fetchSelectedHeatNumber={this.fetchSelectedHeatNumber}
          currentPartCount={this.state.currentPartCount}
          conditionalPartCount={this.state.conditionalPartCount}
        />

        <ConsumptionModal
          operationNo={this.state.currentOperation}
          hspop={this.state.currentHspop}
          operationNoChar={this.state.currentOperationChar}
          onUpdateCurrentPartCount={this.onUpdateCurrentPartCount}
          refreshPartScrapTotalCount={this.refreshPartScrapTotalCount}
          fetchSelectedHeatNumber={this.fetchSelectedHeatNumber}
          parentVisible={this.state.displayConsumptionModal}
          Mo={this.state.currentMO}
          terminalPartCount={this.state.currentPartCount}
          workCenter={this.props.workcenter}
          partNo={this.state.currentItemNo}
          partCount={this.state.POCPartCount}
          progressSetupTime={this.state.progressSetupTime}
          setParentVisible={(visible) =>
            this.setState({ displayConsumptionModal: visible })
          }
          user={this.state.selectedUserForPOC}
        ></ConsumptionModal>
        <Spin size="large" spinning={this.state.fetchDataLoading}>
          <Row style={{ height: "100%" }}>
            {this.props.workcenterNonProductiveGroup ? (
              <Col span={24} style={{ height: "100%" }}>
                <NonProductiveBody />
              </Col>
            ) : (
              <>
                <Col span={5} style={{ height: "100%" }}>
                  <Card
                    className="MOSidebar_card"
                    style={{
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <MOSidebarContent
                      ref={this.MOSidebarRef}
                      style={{ height: "100%" }}
                      MOHandler={this.MOHandler}
                      nextMOHandler={this.nextMOHandler}
                      scrapCountHandler={this.scrapCountHandler}
                      partCountHandler={this.partCountHandler}
                      currentMO={this.state.currentMO}
                      showCloseIcon={false}
                      // workcenter={this.props.workcenter}
                      onRevertSupervisorPermission={() =>
                        this.setState({ blockIfNoSupervisorPermission: true })
                      }
                      progressStatus={this.state.progressStatus}
                      passEndJobCode={this.handlePassEndJobCode}
                      blockedUIHandler={this.blockedUIHandler}
                    />
                  </Card>
                </Col>

                <Col span={19} style={{ height: "100%" }}>
                  <BlockUI
                    blocked={
                      this.state.blockIfNotSignedIn || this.state.blockedPanel
                    }
                    style={{ minHeight: "100%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <Card
                        className="MOHeader_card"
                        style={{
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <Row style={{ alignItems: "start" }}>
                          <h1
                            style={{
                              fontSize: "3.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.currentMO
                              ? "MO: " + this.state.currentMO
                              : "Choose a MO"}
                          </h1>
                          <h1
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "bold",
                              marginLeft: "auto",
                            }}
                          >
                            {"Heat#: "}
                            {this.renderHeatNoList(this.state.heatNumberArray)}
                          </h1>
                          <h1
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "bold",
                              marginLeft: "auto",
                            }}
                          >
                            {"OP#: " +
                              (this.state.currentOperation &&
                              this.state.currentHspop
                                ? this.state.currentHspop
                                : "TBD")}
                          </h1>
                        </Row>
                        <Row style={{ alignItems: "start" }}>
                          <h1
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "bold",
                              marginLeft: "auto",
                            }}
                          >
                            {"Setup: " +
                              (this.state.progressSetupTime ||
                              this.state.progressSetupTime === 0
                                ? moment
                                    .utc(this.state.progressSetupTime)
                                    .format("HH:mm:ss")
                                : "TBD")}
                          </h1>
                          <h1
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "bold",
                              marginLeft: "auto",
                            }}
                          >
                            {"Material#: "}
                            {this.state.currentOperation &&
                            this.state.currentComponent ? (
                              <>
                                {this.state.currentComponent}
                                <span
                                  style={{
                                    color: "#d32f2f",
                                    marginLeft: "0.4em",
                                    fontWeight: "500",
                                  }}
                                  onClick={() => {
                                    this.onClick("displayComponentDetailModal");
                                    this.fetchComponentData();
                                  }}
                                  onTouchEnd={(e) => {
                                    e.preventDefault();
                                    this.onClick("displayComponentDetailModal");
                                    this.fetchComponentData();
                                  }}
                                >
                                  {`Detail`}
                                  <i className="pi pi-angle-down"></i>
                                </span>
                              </>
                            ) : (
                              "TBD"
                            )}
                          </h1>
                          <h1
                            style={{
                              fontSize: "2.2rem",
                              fontWeight: "bold",
                              marginLeft: "auto",
                            }}
                          >
                            {this.state.operations.length < 2
                              ? ""
                              : "OPs Completed: " +
                                (this.state.currentFinishedOperations
                                  ? this.state.currentFinishedOperations +
                                    "/" +
                                    this.state.operations.length
                                  : "TBD")}
                          </h1>
                        </Row>
                        {this.state.currentRoutingDescription ? (
                          <div
                            /*Allow clicking of description to open expanded view if more than one line  */
                            onClick={() => {
                              if (
                                this.state.currentRoutingDescription.includes(
                                  "\n"
                                )
                              )
                                this.onClick("displayBasic");
                            }}
                            onTouchEnd={(e) => {
                              if (
                                this.state.currentRoutingDescription.includes(
                                  "\n"
                                )
                              ) {
                                this.onClick("displayBasic");
                              }
                              e.preventDefault();
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <p style={{ fontSize: "2em", display: "flex" }}>
                                {/*Display first line of routing description */}
                                {this.state.currentRoutingDescription
                                  ? this.state.currentRoutingDescription.includes(
                                      "\n"
                                    )
                                    ? this.state.currentRoutingDescription.substring(
                                        0,
                                        this.state.currentRoutingDescription.indexOf(
                                          "\n"
                                        )
                                      )
                                    : this.state.currentRoutingDescription
                                  : " "}{" "}
                                {this.state.currentRoutingDescription.includes(
                                  "\n"
                                ) ? (
                                  <span
                                    style={{
                                      color: "#d32f2f",
                                      marginLeft: "auto",
                                      marginRight: "0px",
                                    }}
                                  >
                                    Expand<i className="pi pi-angle-down"></i>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Card>
                      <Row
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <Col span={17}>
                          <BlockUI
                            blocked={
                              this.state.blockedPanel2 &&
                              !this.props.workcenter?.subcontractor_no // Subcontractor work centers disable this block UI - Jin, 221017
                            }
                            style={{ minHeight: "100%" }}
                          >
                            <Card>
                              <ObjectCounter
                                booleanCheck={
                                  this.state.OperationNoChar.at(-1) ===
                                  this.state.currentOperationChar
                                    ? true
                                    : false
                                }
                                hspop={this.state.currentHspop}
                                progressSetupTime={this.state.progressSetupTime}
                                prodOrdNo={this.state.currentMO}
                                prodOrderItemNo={this.state.currentItemNo}
                                operationNo={this.state.currentOperation}
                                operationNoChar={
                                  this.state.currentOperationChar
                                }
                                // workcenterNo={this.props.workcenter}
                                // scrapQty={this.state.currentScrapCount}
                                // outputQty={this.state.currentPartCount}
                                // webUserID={this.props.user}
                                count={this.state.currentPartCount}
                                title="Part Count"
                                objectType="part"
                                // MO={this.state.currentMO}
                                total={this.state.requiredQuantity}
                                remainingQty={
                                  this.state.requiredQuantity -
                                  this.state.currentPartCount
                                }
                                // heatNumberArray={this.state.heatNumberArray}
                                onClick={() => this.POCButtonOnClick()}
                                refreshPartScrapTotalCount={
                                  this.refreshPartScrapTotalCount
                                }
                                onUpdatePOCPartCount={this.onUpdatePOCPartCount}
                                onUpdateSeletedUserForPOC={
                                  this.onUpdateSeletedUserForPOC
                                }
                                operationScrollerMOHandler={
                                  this.operationScrollerMOHandler
                                }
                                onUpdateConditionalPartCount={
                                  this.onUpdateConditionalPartCount
                                }
                                setLoading={(bool) =>
                                  this.setState({ fetchDataLoading: bool })
                                }
                                isStartingOperation={
                                  this.state.isStartingOperation
                                }
                                {...(this.props.workcenter
                                  ?.subcontractor_no && {
                                  subcontractorMode: true,
                                })}
                              />
                            </Card>
                          </BlockUI>
                        </Col>

                        <Col span={7}>
                          <Card style={{ height: "100%", marginLeft: "10px" }}>
                            <div>
                              {this.props.workcenter?.subcontractor_no ? (
                                <div
                                  style={{
                                    color: "#d32f2f",
                                    textAlign: "center",
                                    border: "2px dotted",
                                    padding: "5px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "2em",
                                      fontWeight: 500,
                                      margin: 0,
                                    }}
                                  >
                                    Subcontractor workcenter
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "1.2em",
                                      color: "#212529",
                                    }}
                                  >
                                    Complete operation is only available
                                  </p>
                                </div>
                              ) : (
                                <JobTimer
                                  ref={this.JobTimerRef}
                                  MO={this.state.currentMO}
                                  hspop={this.state.currentHspop}
                                  operation={this.state.currentOperation}
                                  blockedUIHandler={this.blockedUIHandler}
                                  passProgressSetupTime={(value) =>
                                    this.passProgressSetupTime(value)
                                  }
                                  passProgressStatus={(value) =>
                                    this.passProgressStatus(value)
                                  }
                                  endJobCode={this.state.endJobCode}
                                  passEndJobCode={(value) =>
                                    this.setState({ endJobCode: value })
                                  }
                                  requestSupervisorApproval={() =>
                                    this.requestSupervisorApproval()
                                  }
                                  supervisorApproved={
                                    this.state.supervisorApproved
                                  }
                                  resetSupervisorApproved={() =>
                                    this.setState({ supervisorApproved: false })
                                  }
                                  handlePauseModal={this.handlePauseModal}
                                  allOperations={this.state.operations}
                                />
                              )}
                            </div>
                            {(this.state.progressStatus === 3 ||
                              this.props.workcenter?.subcontractor_no) && (
                              <div>
                                <Button
                                  label={
                                    this.state.loadingNextWorkCenter ? (
                                      "Loading..."
                                    ) : (
                                      <>
                                        <span>Complete Operation </span>
                                      </>
                                    )
                                  }
                                  className="p-button-danger"
                                  style={{
                                    width: "100%",
                                    fontSize: "24px",
                                    padding: "4px",
                                    marginTop: "10px",
                                  }}
                                  onClick={() =>
                                    this.handleOnClickCompleteOperation()
                                  }
                                  onTouchEnd={(e) => {
                                    e.preventDefault();
                                    this.handleOnClickCompleteOperation();
                                  }}
                                />
                              </div>
                            )}
                          </Card>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <Col span={17}>
                          <BlockUI
                            blocked={
                              this.state.blockedPanel2 &&
                              !this.props.workcenter?.subcontractor_no
                            }
                            style={{ minHeight: "100%" }}
                          >
                            <Card style={{ width: "100%" }}>
                              <ObjectCounter
                                booleanCheck={
                                  this.state.OperationNoChar.at(-1) ===
                                  this.state.currentOperationChar
                                    ? true
                                    : false
                                }
                                hspop={this.state.currentHspop}
                                progressSetupTime={this.state.progressSetupTime}
                                prodOrdNo={this.state.currentMO}
                                prodOrderItemNo={this.state.currentItemNo}
                                operationNo={this.state.currentOperation}
                                operationNoChar={
                                  this.state.currentOperationChar
                                }
                                // workcenterNo={this.props.workcenter}
                                // scrapQty={this.state.currentScrapCount}
                                // outputQty={this.state.currentPartCount}
                                // webUserID={this.props.user}
                                count={this.state.currentScrapCount}
                                title="Scrap"
                                objectType="scrap"
                                // MO={this.state.currentMO}
                                total={this.state.requiredQuantity}
                                // heatNumberArray={this.state.heatNumberArray}
                                onClick={() => this.POCButtonOnClick()}
                                onUpdateCurrentPartCount={
                                  this.onUpdateCurrentPartCount
                                }
                                refreshPartScrapTotalCount={
                                  this.refreshPartScrapTotalCount
                                }
                                onUpdatePOCPartCount={this.onUpdatePOCPartCount}
                                onUpdateSeletedUserForPOC={
                                  this.onUpdateSeletedUserForPOC
                                }
                                onUpdateConditionalPartCount={
                                  this.onUpdateConditionalPartCount
                                }
                                setLoading={(bool) =>
                                  this.setState({ fetchDataLoading: bool })
                                }
                                isStartingOperation={
                                  this.state.isStartingOperation
                                }
                                {...(this.props.workcenter
                                  ?.subcontractor_no && {
                                  subcontractorMode: true,
                                })}
                              />
                            </Card>
                          </BlockUI>
                        </Col>
                        <Col span={7}>
                          <QcInspectionButton
                            inspectionEnabled={this.state.inspectionEnabled}
                            onClick={() =>
                              this.setState({ showSupervisorPopup: true })
                            }
                            onTouchEnd={(e) => {
                              e.preventDefault();
                              this.setState({
                                showSupervisorPopup: true,
                              });
                            }}
                            onDrawingClick={(e) => {
                              this.setState({ showDrawingModal: true });
                              if (e && e.type === "touchend") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </BlockUI>
                  {this.state.operationsMerged.filter((el) => {
                    return el.opNo !== this.state.currentOperation;
                  }).length > 0 ? (
                    <Row>
                      <Col span={24}>
                        <Card
                          style={{
                            marginLeft: "10px",
                            width: "100%",
                            float: "right",
                          }}
                        >
                          <OperationsDataScroller
                            operationsMerged={this.state.operationsMerged}
                            operationsMergedHandler={(value) => {
                              this.setState({ operationsMerged: value });
                            }}
                            currentOperationHandler={(value) => {
                              this.setState({ currentOperation: value }, () =>
                                console.log(value, "op")
                              );
                            }}
                            currentOperationCharHandler={(value) => {
                              this.setState({ currentOperationChar: value });
                            }}
                            operationScrollerMOHandler={
                              this.operationScrollerMOHandler
                            }
                            MO={this.state.currentMO}
                            operationNo={this.state.currentOperation}
                            operationNoChar={this.state.currentOperationChar}
                            routingDescriptions={
                              this.state.currentRoutingDescription
                            }
                            operationsMergedLoading={
                              this.state.operationsMergedLoading
                            }
                          ></OperationsDataScroller>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
              </>
            )}
          </Row>
        </Spin>
        <SupervisorPermission
          showModal={this.state.showSupervisorPopup}
          type={this.state.supervisorPopupType}
          onCancel={() =>
            this.setState({
              showSupervisorPopup: false,
              supervisorApproved: false,
            })
          }
          onSuccess={this.onSuccessSupervisorPopup}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.workCenter.users,
  workcenter: state.workCenter,
  workcentertype: state.workCenter.workcenter_type,
  division: state.appConfig.division,
  workcenterNonProductiveGroup: state.workCenter.workcenterNonProductiveGroup,
});

export default connect(mapStateToProps)(Boxes);
