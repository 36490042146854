import React from "react";
// import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import "./OperationsDataScroller.styles.css";
import { LoadingOutlined } from "@ant-design/icons";

class OperationsDataScroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goDisabled: false,
    };
  }

  //Operation scroller item template per entry
  itemTemplate(
    data,
    currentOperationHandler,
    operationScrollerMOHandler,
    currentOperationCharHandler
  ) {
    return (
      <div key={data.opNo} className="operationScrollerContainer">
        <Button
          disabled={this.state.goDisabled}
          id="operationChangeButton"
          className={
            this.props.operationNo === data.opNo
              ? `changeOperationButtonSelected-${
                  data.isFinished ? "green" : "red"
                }`
              : "changeOperationButton"
          }
          onClick={() => {
            // console.log("Button clicked")
            operationScrollerMOHandler(
              data.opNo.toString(),
              data.opNoChar.toString(),
              data.hspop.toString()
            );
            currentOperationCharHandler(data.opNoChar);
            this.setState({ goDisabled: true });
            setTimeout(() => this.setState({ goDisabled: false }), 1000);
          }}
          onTouchEnd={(e) => {
            operationScrollerMOHandler(
              data.opNo.toString(),
              data.opNoChar.toString(),
              data.hspop.toString()
            );
            currentOperationCharHandler(data.opNoChar);
            this.setState({ goDisabled: true });
            setTimeout(() => this.setState({ goDisabled: false }), 1000);
            e.preventDefault();
          }}
        >
          <div
            style={{
              background: "rgba(0,0,0,0.8)",
              padding: "0 1rem",
              borderRadius: "10px",
            }}
          >
            {this.props.operationsMergedLoading ? (
              <LoadingOutlined style={{ fontSize: "2em", color: "white" }} />
            ) : (
              <>
                <div>{data.part_count}</div>
                <div style={{ background: "white", height: "2px" }}></div>
                <div>{Math.round(data.quantity)}</div>
              </>
            )}
          </div>
          <div
            style={{
              background: "rgba(0,0,0,0.8)",
              padding: "0 1rem",
              borderRadius: "10px",
              marginLeft: "10px",
            }}
          >
            OP#{data.hspop}
            <div className="operationStatusContainer">
              {data.isFinished ? (
                <i
                  className="pi pi-check operationStatus text-success"
                  style={{ fontSize: "2em", color: "green" }}
                ></i>
              ) : (
                <i
                  className="pi pi-times operationStatus"
                  style={{ fontSize: "2em", color: "red" }}
                ></i>
              )}
            </div>
          </div>
        </Button>

        {/*        {data.isFinished ? <div className="operationOverlay"></div> : ""}

        <div className="operationDescContainer">
          <p id="operationDescription">
            {data.description.split("\n")[0]
              ? data.description.split("\n")[0]
              : ""}
            <br />
            {data.description.split("\n")[1]
              ? data.description.split("\n")[1]
              : ""}
          </p>
        </div><div className="operationActionsContainer">
          <Button
            disabled={this.state.goDisabled}
            id="operationChangeButton"
            className="p-button-danger changeOperationButton"
            onClick={() => {
              console.log(data.opNo.toString());
              console.log(data.opNoChar.toString());
              console.log(data.hspop.toString());
              operationScrollerMOHandler(
                data.opNo.toString(),
                data.opNoChar.toString(),
                data.hspop.toString()
              );
              currentOperationCharHandler(data.opNoChar);
              this.setState({ goDisabled: true });
              setTimeout(() => this.setState({ goDisabled: false }), 1000);
            }}
          >
            Select OP#: {data.hspop}
          </Button>
        </div><div className="operationStatusContainer">
          {data.isFinished ? (
            <i
              className="pi pi-check operationStatus text-success"
              style={{ fontSize: "5em" }}
            ></i>
          ) : (
            <i
              className="pi pi-times operationStatus"
              style={{ fontSize: "5.5em", color: "red" }}
            ></i>
          )}
        </div> */}
      </div>
    );
  }

  /*filterChosenOperation(operationArray) {
    var filteredArray = operationArray.filter((el) => {
      return el.opNo !== this.props.operationNo;
    });

    return filteredArray;
  }*/

  render() {
    return (
      <div className="allOperationsContainer">
        {this.props.operationsMerged.map((data) => {
          //console.log(data);
          data = {
            ...data,
            isFinished: data.quantity > 0 && data.part_count >= data.quantity,
          };
          return this.itemTemplate(
            data,
            this.props.currentOperationHandler,
            this.props.operationScrollerMOHandler,
            this.props.currentOperationCharHandler
          );
        })}
        {/* <ScrollPanel style={{ width: "100%", height: "20vh" }}>
          {this.filterChosenOperation(this.props.operationsMerged).map(
            (data) => {
              //console.log(data);
              data = { ...data, isFinished: data.part_count >= data.quantity };
              return this.itemTemplate(
                data,
                this.props.currentOperationHandler,
                this.props.operationScrollerMOHandler,
                this.props.currentOperationCharHandler
              );
            }
          )}
        </ScrollPanel> */}
      </div>
    );
  }
}

export default OperationsDataScroller;
