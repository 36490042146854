import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { persistStore } from "redux-persist";
import workCenterSlice from "./work-center-slice";
import appConfigSlice from "./app-config.slice";
import deviceConfigSlice from "./device-config.slice";

const reducers = combineReducers({
  workCenter: workCenterSlice.reducer,
  appConfig: appConfigSlice.reducer,
  deviceConfig: deviceConfigSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
