import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { connect } from "react-redux";
import moment from "moment";
import "./nonProd.styles.css";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const NonProductiveBody = (props) => {
  const [realTime, setRealTime] = useState(moment());
  useInterval(() => {
    setRealTime(moment());
  }, 60000);

  return (
    <div style={{ height: "100%" }}>
      <Card
        style={{
          height: "calc(100% - 4rem)",
          overflowY: "scroll",
          margin: "0 2rem",
        }}
      >
        <DataTable
          className="non-prod-table"
          value={props.user}
          size={"large"}
          emptyMessage="No user logged in"
        >
          <Column
            header="Name"
            body={(rowData) => `${rowData.first_name} ${rowData.last_name}`}
          />
          <Column
            header="Login Time"
            body={(rowData) =>
              `${moment(rowData.log_in).format("YYYY-MM-DD HH:mm:ss")}`
            }
          />
          <Column
            header="Elapsed Time"
            body={(rowData) => {
              const loginTime = moment(rowData.log_in);
              let elapsedTime = Math.round(
                moment.duration(realTime.diff(loginTime)).asMinutes()
              );
              return `${elapsedTime > 0 ? elapsedTime : 0} Minutes`;
            }}
          />
        </DataTable>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.workCenter.users,
});

export default connect(mapStateToProps)(NonProductiveBody);
