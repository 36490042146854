import axios from "axios";
import { CONST_API_URL } from "../../../appconfig";

export function getLoggedInUsers() {
  return axios
    .get(`${CONST_API_URL}/GetLoggedInUsers`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log(`GetLoggedInUsers Client - ${error}`));
}
