import React, { Fragment } from "react";
import "./datagrid.css";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Spin,
  Tooltip,
  Modal,
  Button,
  Select,
  notification,
  Descriptions,
  Alert,
} from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import Success from "../../../assets/success.png";
import Error from "../../../assets/error.png";
import POCErrorModal from "./pocerrormodal";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { updateCutConsumptionService } from "./pocmodal.service";
import { CONST_API_URL } from "../../../appconfig";
import LotSelector from "../lot-selector/lot-selector.component";
import { updateRoutingQtyService } from "../MultipleConsumptionModal/consumption.service";
const { Option } = Select;

const CUTHISTORY_COLUMN_LENGTH_DEFAULT = 7;

function concatenateString(data) {
  return data.first_name + " " + data.last_name;
}

class POCModal extends React.Component {
  state = {
    isLoading: false,
    isPosted: false,
    errorModalData: {},
    itemData: {},
    errorModalVisible: false,
    historyComment: [],
    modalItemDataSource: [],
    status: 0,
    msg_type: false,
    msg: "",
    emailSent: 0,
    emailSentSuccessful: 0,
    NegativeCons: false,
    calcQty: 0,
    cutQty: 0,
    scrapQty: 0,
    lotNumber: "",
    submsg: "",
    errorMessage: "",
    remainingQty: 0,
    usedQty: 0,
    NegativeCons: false,
    currentLocation: "",
    currentLotMaxQuantity: 0,
    lotNoMantory: false,
    lotSelectorVisible: false,
    lastHeatNumber: "",
    lastHeatNumberLocationCode: "",
    noLotAvailableFg: false, // Flag for no lot is available in list
    selectedLots: [],
    lotsSelected: false, //Flag to check if lot selection has been completed by user
  };

  //State handler

  handleStateChange = (data, field) => {
    this.setState({ [field]: data });
  };

  openNotification = (type, error = "") => {
    if (type === "success") {
      notification.success({
        message: "Success",
        description: "The consumed amount has been posted to NAV.",
        duration: 5,
      });
    } else {
      notification.error({
        message: "Failed",
        description: `Error: ${error}`,
        duration: 5,
      });
    }
  };

  setEmailSent = (data) => {
    this.setState({ emailSent: data });
  };
  setEmailSuccessful = (data) => {
    this.setState({ emailSentSuccessful: data });
  };
  setErrorModalVisible = (data) => {
    this.setState({ errorModalVisible: data });
  };

  HeatTitle = () => {
    return (
      <div style={{ fontSize: "5rem", padding: "10px" }}>
        Please Select Heat No
      </div>
    );
  };

  showTitle = () => {
    return <div style={{ height: "20px" }}></div>;
  };
  showFooter = () => {
    return [
      <Button
        key="1"
        disabled={this.state.isLoading ? true : false}
        style={{ fontSize: "3rem", height: "fit-content" }}
        onClick={(e) => this.onCancel(e)}
        onTouchEnd={(e) => this.onCancel(e)}
      >
        {" "}
        Cancel{" "}
      </Button>,
      <Button
        key="2"
        disabled={this.state.isLoading ? true : false}
        type="primary"
        style={{ fontSize: "3rem", height: "fit-content" }}
        onClick={(e) => this.onPost(e)}
        onTouchEnd={(e) => this.onPost(e)}
      >
        {" "}
        Post{" "}
      </Button>,
    ];
  };

  handleNavCalls = async (packagedConsumption, packagedOutput, extraFields) => {
    console.log("handleNavCalls", {
      packagedConsumption,
      packagedOutput,
      extraFields,
    });
    const consumptionResult = await updateCutConsumptionService({
      batch: packagedConsumption,
      extraFields: extraFields,
    });

    console.log("--------------------------------------");
    console.log(consumptionResult.data);
    console.log("--------------------------------------");
    if (consumptionResult.data.type === false) {
      this.setState({
        msg_type: false,
        isLoading: false,
        errorMessage: consumptionResult.data.msg,
        isPosted: false,
        status: 2,
      });
      this.showErrorModalMessage(
        this.props.data,
        this.state.errorMessage,
        packagedConsumption
      );
    } else {
      //No errors, do output
      const outputObjectResult = await updateRoutingQtyService(
        packagedOutput,
        packagedConsumption
      );

      console.log("--------------------------------------");
      console.log(outputObjectResult.data);
      console.log("--------------------------------------");

      if (!outputObjectResult.data.type) {
        this.setState({
          msg_type: false,
          isLoading: false,
          errorMessage: outputObjectResult.data.msg,
          isPosted: false,
          status: 2,
        });
        this.setState({
          errorMessage: outputObjectResult.data.msg,
        });
        this.showErrorModalMessage(
          this.props.data,
          this.state.errorMessage,
          packagedConsumption
        );
        //revert consumption
        // let revertconsumptionObject = {
        //   ...consumptionObject,
        //   cutQty: consumptionObject.cutQty * -1,
        //   scrapQty: -consumptionObject.scrapQty * -1,
        //   totalQty: consumptionObject.totalQty * -1,
        // };

        var packagedRevertConsumption = [];
        for (var i = 0; i < packagedConsumption.length; i++) {
          var current = packagedConsumption[i];
          var revertObject = {
            ...current,
            CutQty: current.CutQty * -1,
            ScrapQty: current.ScrapQty,
            TotalQty: current.TotalQty * -1,
          };
          packagedRevertConsumption.push(revertObject);
        }

        await updateCutConsumptionService({
          batch: packagedRevertConsumption,
          extraFields,
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "",
          isPosted: true,
          status: 2,
          currentLotMaxQuantity: 0,
          msg_type: true,
        });

        //update part_count in sql but this is not in use because of pulling part count directly from NAV
        axios
          .post(`${CONST_API_URL}/AdjustPartCount`, {
            MO: this.props.data.MO_No,
            OperationNo: this.props.operationNo,
            count: this.props.conditionalPartCount,
          })
          .then((res) => {});

        //this.props.onUpdateCurrentPartCount(this.props.conditionalPartCount);
        this.props.refreshPartScrapTotalCount();
        this.props.fetchSelectedHeatNumber(this.props.data.MO_No);
        this.onCancel();
      }
    }
  };

  packageConsumptionBatch = (extraFields, FakeScrap, parentData, today) => {
    var packagedBatch = [];

    //Common Fields
    // prodOrdNo: parentData.MO_No,
    // prodOrdLineNo: parentData.MO_Line_No,
    // operationNo: this.props.operationNoChar,
    // routingReferenceNo: parentData.RoutingReferenceNo,
    // facility: this.props.division,

    for (var i = 0; i < this.state.selectedLots.length; i++) {
      var calcCutQty =
        this.state.selectedLots[i].quantity /
        this.props.data.ComponentQuantityPer;

      //Fix decimals to 5 decimal places
      calcCutQty = calcCutQty.toFixed(5);

      // const consumptionObject = {
      //   ...extraFields,
      //   lineNo: parentData.Line_No,
      //   cutLen: this.props.data.ComponentQuantityPer,
      //   lotNo: this.state.selectedLots[i].lotNumber,
      //   locationCode: this.state.selectedLots[i].location,
      //   cutQty: calcCutQty,
      //   scrapQty: FakeScrap,
      //   totalQty: this.state.selectedLots[i].quantity,
      //   compLineNo: parentData.ComponentLineNo,
      //   compItemNo: parentData.ComponentItemNo,
      //   webUserID: this.props.user ? concatenateString(this.props.user) : "",

      //   strAppLocalDate:
      //     today.getFullYear() +
      //     "-" +
      //     (today.getMonth() + 1) +
      //     "-" +
      //     today.getDate(),
      //   strAppLocalTime:
      //     today.getHours() +
      //     ":" +
      //     today.getMinutes() +
      //     ":" +
      //     today.getSeconds(),
      // };

      const consumptionObject = {
        CompLineNo: parentData.ComponentLineNo,
        CompItemNo: parentData.ComponentItemNo,
        LotNo: this.state.selectedLots[i].lotNumber,
        LocationCode: this.state.selectedLots[i].location,
        CutLen: this.props.data.ComponentQuantityPer,
        CutQty: calcCutQty,
        ScrapQty: FakeScrap,
        TotalQty: this.state.selectedLots[i].quantity,
      };

      //Put new consumption object in batch package

      packagedBatch.push(consumptionObject);
    }

    return packagedBatch;
  };

  packageOutputBatch = (extraFields, parentData, today) => {
    const packagedBatch = {
      ...extraFields,
      prodOrderItemNo: parentData.PartNumber,
      workCenterNo: this.props.workcenterNo,
      // lot_Heat_No: this.state.selectedLots[0].lotNumber,
      scrapQty: this.state.scrapQty,
      // outputQty: Math.round(
      //   this.state.selectedLots[i].quantity /
      //     this.props.data.ComponentQuantityPer
      // ),
      outputQty: this.state.cutQty,
      webUserID: this.props.user ? concatenateString(this.props.user) : "",
      webUserActualID: this.props.user ? this.props.user.ID : 0,
      userId: this.props.user ? this.props.user.ID : "",
      routingNo: parentData.RoutingNo,
      hspopNo: this.props.hspop,
    };

    return packagedBatch;
  };

  onPost = async (e) => {
    if (e && e.type === "touchend") {
      e.preventDefault();
    }

    const parentData = this.props.data;
    let facility = "";
    if (this.props.division === "HAVEN") {
      facility = "Havensteel";
    } else if (this.props.division === "MCKENZIE") {
      facility = "MCKENZIE";
    }

    this.setState({
      isLoading: true,
      submsg: "",
      msg_type: false,
      status: 1,
      msg: "",
      emailSent: 0,
      isPosted: false,
    });
    if (this.state.lotsSelected !== true && this.state.lotNoMantory) {
      this.setState({
        submsg: "Must assign heat #s before proceeding",
        isLoading: false,
        status: 0,
      });
    } else if (this.state.calcQty === 0) {
      this.setState({
        submsg: "Please Consume At Least One Quantity",
        isLoading: false,
        status: 0,
      });
    } else {
      var FakeScrap = this.state.scrapQty;
      var FakeCutQty = this.state.cutQty;
      var FakeCalQty = this.state.calcQty;
      if (this.state.NegativeCons) {
        FakeScrap = -this.state.scrapQty;
        FakeCutQty = -this.state.cutQty;
        FakeCalQty = -this.state.calcQty;
      }
      var today = new Date();

      // New implementation for batch consumption

      const extraFields = {
        prodOrdNo: parentData.MO_No,
        prodOrdLineNo: parentData.MO_Line_No,
        operationNo: this.props.operationNoChar,
        routingReferenceNo: parentData.RoutingReferenceNo,
        facility: this.props.division,
        strAppLocalDate:
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        strAppLocalTime:
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds(),
        webUserID: this.props.user ? concatenateString(this.props.user) : "",
        lineNo: parentData.Line_No,
      };

      if (this.state.selectedLots.length > 0) {
        var packagedConsumption = this.packageConsumptionBatch(
          extraFields,
          FakeScrap,
          parentData,
          today
        );
        var packagedOutput = this.packageOutputBatch(
          extraFields,
          parentData,
          today
        );
        console.warn(packagedConsumption);
        console.warn(packagedOutput);
        this.handleNavCalls(packagedConsumption, packagedOutput, extraFields);
      } else {
        const consumptionObject = {
          compLineNo: parentData.ComponentLineNo,
          compItemNo: parentData.ComponentItemNo,
          lotNo: "",
          locationCode: this.state.currentLocation,
          cutLen: this.props.data.ComponentQuantityPer,
          cutQty: FakeCutQty,
          scrapQty: FakeScrap,
          totalQty: FakeCalQty,
        };
        const outputObject = {
          ...extraFields,
          prodOrderItemNo: parentData.PartNumber,
          workCenterNo: this.props.workcenterNo,
          // lot_Heat_No: this.state.lotNumber,
          scrapQty: this.state.scrapQty,
          outputQty: this.state.cutQty,
          webUserID: this.props.user ? concatenateString(this.props.user) : "",
          webUserActualID: this.props.user ? this.props.user.ID : 0,
          userId: this.props.user ? this.props.user.ID : "",
          routingNo: parentData.RoutingNo,
          hspopNo: this.props.hspop,
          facility: facility,
        };
        await this.handleNavCalls(
          [consumptionObject],
          outputObject,
          extraFields
        );
      }
    }
  };

  onCancel = (e) => {
    this.setState(
      {
        submsg: "",
        cutQty: "",
        scrapQty: 0,
        lotNumber: "",
        selectedLots: [],
        status: 0,
        calcQty: 0,
        isPosted: false,
        errorMessage: "",
      },
      () => {
        this.props.onCancel();
      }
    );
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  };
  handleChangeCutQty = (event) => {
    const currValue = event.target.value;
    this.setState({ status: 0, emailSent: 0 });
    if (currValue === "" || currValue === " ") {
      let scrap = 0;
      let cutQty = 0;
      if (this.state.scrapQty === " " || this.state.scrapQty === "") {
        scrap = 0;
      } else {
        scrap = this.state.scrapQty;
      }
      var newQty =
        parseFloat(cutQty) * parseFloat(this.props.data.ComponentQuantityPer) +
        parseFloat(scrap);
      newQty = Math.round((newQty + Number.EPSILON) * 100000) / 100000;
      this.setState({ cutQty: 0, calcQty: newQty, submsg: "" });
    } else {
      if (Number.isInteger(parseFloat(currValue))) {
        if (parseInt(currValue) < 0 || parseInt(currValue) === 0) {
          this.setState({
            submsg: "Cut Qty should be greater than 0",
            cutQty: "",
            calcQty: "",
          });
        } else if (
          parseInt(currValue) > this.state.currentLotMaxQuantity &&
          this.state.lotNoMantory
        ) {
          this.setState({
            submsg:
              "Cut Qty should be less than the available quantity in the current lot",
            cutQty: "",
            calcQty: "",
          });
        } else {
          let scrap = 0;
          let cutQty = currValue;
          if (this.state.scrapQty === " " || this.state.scrapQty === "") {
            scrap = 0;
          } else {
            scrap = this.state.scrapQty;
          }
          newQty =
            parseFloat(cutQty) *
              parseFloat(this.props.data.ComponentQuantityPer) *
              (1 + parseFloat(this.props.data.ComponentScrapPercent) / 100) +
            parseFloat(scrap);
          newQty = Math.round((newQty + Number.EPSILON) * 100000) / 100000;
          this.setState({ cutQty: cutQty, calcQty: newQty, submsg: "" });
        }
      } else {
        let value = currValue;
        if (Number.isNaN(parseInt(value))) {
          value = 0;
        }
        this.setState({
          submsg: "Cut Qty should be a whole Number",
          cutQty: parseInt(value),
        });
      }
    }
  };
  handleChangeScrapQty = (current) => {
    let currValue = current;
    this.setState({ status: 0, emailSent: 0 });
    if (currValue === "" || currValue === " " || currValue === 0) {
      let cutQty = 0;
      if (this.state.cutQty === " " || this.state.cutQty === "") {
        cutQty = 0;
      } else {
        cutQty = this.state.cutQty;
      }
      var newQty =
        parseFloat(cutQty) *
        parseFloat(this.props.data.ComponentQuantityPer) *
        (1 + parseFloat(this.props.data.ComponentScrapPercent) / 100);
      newQty = Math.round((newQty + Number.EPSILON) * 100000) / 100000;
      this.setState({ scrapQty: 0, calcQty: newQty, submsg: "" });
    } else {
      if (parseFloat(currValue) < 0) {
        this.setState({
          submsg: "Scrap Quantity should be a positive value",
          scrapQty: "",
        });
      } else {
        let cutQty = 0;
        if (this.state.cutQty === " " || this.state.cutQty === "") {
          cutQty = 0;
        } else {
          cutQty = this.state.cutQty;
        }
        newQty =
          parseFloat(cutQty) *
            parseFloat(this.props.data.ComponentQuantityPer) *
            (1 + parseFloat(this.props.data.ComponentScrapPercent) / 100) +
          parseFloat(currValue);
        newQty = Math.round((newQty + Number.EPSILON) * 100000) / 100000;
        this.setState({
          scrapQty: currValue,
          calcQty: newQty,
          submsg: "",
        });
      }
    }
  };

  //When negative consumption checkbox is checked or unchecked on Mo screen
  postNegative = () => {
    this.setState({ status: 0, emailSent: 0 });
    let value = document.getElementById("postCheck").checked;
    if (value === true) {
      this.setState({ NegativeCons: true });
    }
    if (value === false) {
      this.setState({ NegativeCons: false });
    }
  };

  showErrorModalMessage = (dataObject, errorMessage, consumptionObject) => {
    if (Array.isArray(consumptionObject)) {
      let totalQty = 0;
      let scrapQty = 0;
      for (const obj of consumptionObject) {
        totalQty += obj.TotalQty;
        scrapQty += obj.ScrapQty;
      }

      consumptionObject = {
        CompItemNo: consumptionObject[0].CompItemNo,
        CompLineNo: consumptionObject[0].CompLineNo,
        CutLen: consumptionObject[0].CutLen,
        TotalQty: totalQty,
        ScrapQty: scrapQty,
        LotNo: consumptionObject.map((obj) => obj.LotNo).join(", "),
        CutQty: consumptionObject.map((obj) => obj.CutQty).join(", "),
      };
    }

    this.setState({
      errorModalVisible: true,
      errorModalData: {
        ...dataObject,
        errorMessage: errorMessage,
        ...consumptionObject,
        userFullName: `${this.props.user?.first_name} ${this.props.user?.last_name}`,
      },
    });
  };

  heatFoot = () => {
    return [
      <Button
        key="1"
        style={{ fontSize: "3rem", height: "fit-content" }}
        onClick={() => this.setState({ lotSelectorVisible: false })}
        onTouchEnd={(e) => {
          this.setState({ lotSelectorVisible: false });
          e.preventDefault();
        }}
      >
        {" "}
        Cancel{" "}
      </Button>,
      <Button
        key="2"
        type="primary"
        style={{ fontSize: "3rem", height: "fit-content" }}
        onClick={() => this.setState({ lotSelectorVisible: false })}
        onTouchEnd={(e) => {
          this.setState({ lotSelectorVisible: false });
          e.preventDefault();
        }}
      >
        {" "}
        Ok{" "}
      </Button>,
    ];
  };

  closeErrorModalMessage = () => {
    this.setState({ errorModalVisible: false });
  };

  loadItemData = () => {
    return (
      <div>
        <div></div>
        <div>
          <div>
            {" "}
            <Descriptions bordered={true} colon={false} column={2}>
              <Descriptions.Item
                span={2}
                label={
                  <h3>
                    <b>Raw Material</b>
                  </h3>
                }
              >
                <h3>
                  {this.props.data.ComponentItemNo} -{" "}
                  {this.props.data.ComponentItemDescription}{" "}
                </h3>
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                label={
                  <h3>
                    <b>Part</b>
                  </h3>
                }
              >
                <h3>
                  {this.props.data.PartNumber} -{" "}
                  {this.props.data.PartDescription}{" "}
                </h3>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <h3>
                    <b>Expected Qty</b>
                  </h3>
                }
              >
                <h3>{this.props.data.MO_Line_Quantity}</h3>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <h3>
                    <b>Remaining Qty</b>
                  </h3>
                }
              >
                <h3>{this.state.remainingQty}</h3>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div
            className="scrollHistory"
            style={{
              borderTop: "solid 3px #d4d4d4",
              borderBottom: "solid 3px #d4d4d4",
              padding: "0",
            }}
          >
            <table width="100%" className="CutHistoryTableSingle">
              <tbody>
                {this.state.historyComment.map((item, index) => {
                  var result = item.split(",");
                  if (index === 0) {
                    return (
                      <tr key={index} style={{}}>
                        <th>{result[4]}</th>
                        <th>{result[1]}</th>
                        <th>{result[2]}</th>
                        <th>{result[3]}</th>
                        <th>{result[0]}</th>
                        <th>{result[5]}</th>
                        <th>{result[6]}</th>
                      </tr>
                    );
                  } else {
                    const columnLength =
                      this.state.historyComment[0]?.split(",").length ||
                      CUTHISTORY_COLUMN_LENGTH_DEFAULT;

                    if (item.includes("[M]")) {
                      const quantity = result[0]?.split(":")[1] || "N/A";
                      return (
                        <tr key={index} style={{ border: "1px black solid" }}>
                          <td
                            colSpan={columnLength}
                            style={{
                              border: "1px black solid",
                              backgroundColor: "#ffbdbd",
                              textAlign: "center",
                            }}
                          >
                            {`Consumption made directly in Business Central with total Qty: ${quantity}`}
                          </td>
                        </tr>
                      );
                    } else if (result.length < columnLength) {
                      const comment = item.substring(item.indexOf(",") + 1);
                      return (
                        <tr key={index} style={{ border: "1px black solid" }}>
                          <td
                            colSpan={columnLength}
                            style={{ border: "1px black solid" }}
                          >
                            {comment + " (" + result[0] + ")"}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <td>{result[4]}</td>
                          <td>{result[1]}</td>
                          <td>{result[2]}</td>
                          <td>{result[3]}</td>
                          <td>{result[0]}</td>
                          <td>{result[5]}</td>
                          <td>{result[6]}</td>
                        </tr>
                      );
                    }
                  }
                })}
              </tbody>
            </table>
          </div>
          <div>
            {" "}
            <Descriptions bordered={true} colon={false} column={2}>
              <Descriptions.Item
                label={
                  <h3>
                    <b>Total Material Consumed</b>
                  </h3>
                }
              >
                <h3>
                  {this.state.NegativeCons ? "-" : ""} {this.state.calcQty}
                </h3>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <h3>
                    <b>
                      Cut Length{" "}
                      {this.props.data.ComponentUOM
                        ? `(${this.props.data.ComponentUOM})`
                        : ""}
                    </b>
                  </h3>
                }
              >
                <h3>{this.props.data.ComponentQuantityPer}</h3>
              </Descriptions.Item>
              <Descriptions.Item
                span={1}
                label={
                  <h3>
                    <b>Cut Quantity</b>
                  </h3>
                }
              >
                <h3>
                  {this.state.NegativeCons ? "-" : ""}{" "}
                  {this.state.cutQty ? this.state.cutQty : "N/A"}
                </h3>
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{ paddingRight: "10px" }}
                label={
                  <h3>
                    <b>
                      Scrap Quantity{" "}
                      {this.props.data.ComponentUOM
                        ? `(${this.props.data.ComponentUOM})`
                        : ""}
                    </b>
                  </h3>
                }
              >
                {" "}
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    className="incrementer__value"
                    style={{
                      width: "35%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <h3>
                      {" "}
                      {this.state.NegativeCons ? "-" : ""}{" "}
                      {this.state.scrapQty ? this.state.scrapQty : 0}
                    </h3>{" "}
                  </div>
                  <div
                    className="incrementer__buttons"
                    style={{ width: "65%" }}
                  >
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                      onClick={() => {
                        this.handleChangeScrapQty(this.state.scrapQty + 1);
                        this.setState({ scrapQty: this.state.scrapQty + 1 });
                      }}
                      icon={<UpOutlined style={{ fontSize: "2rem" }} />}
                      disabled={this.state.isLoading ? true : false}
                    ></Button>
                    <Button
                      style={{
                        marginLeft: "10px",
                        width: "40px",
                        height: "40px",
                      }}
                      onClick={() => {
                        this.handleChangeScrapQty(this.state.scrapQty - 1);
                        this.setState({ scrapQty: this.state.scrapQty - 1 });
                      }}
                      icon={<DownOutlined style={{ fontSize: "2rem" }} />}
                      disabled={this.state.isLoading ? true : false}
                    ></Button>
                  </div>
                </div>
              </Descriptions.Item>
            </Descriptions>
          </div>

          <table
            width="100%"
            style={{
              borderCollapse: "separate",
            }}
          >
            <tbody>
              {this.state.submsg ? (
                <Alert
                  showIcon
                  message={<h3>{this.state.submsg} </h3>}
                  type="warning"
                />
              ) : (
                <></>
              )}
            </tbody>
          </table>
          <div
            style={{
              margin: "10px 0px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.lotNoMantory ? (
              <>
                {" "}
                <Button
                  style={{
                    fontSize: "2.5rem",
                    height: "fit-content",
                    width: "75%",
                    borderRadius: "5px",
                  }}
                  onClick={() => this.setState({ lotSelectorVisible: true })}
                  onTouchEnd={(e) => {
                    this.setState({ lotSelectorVisible: true });
                    e.preventDefault();
                  }}
                  type="danger"
                >
                  Assign heat #
                </Button>
                <Modal
                  onCancel={() => {
                    this.setState({ lotSelectorVisible: false });
                  }}
                  destroyOnClose
                  title={false}
                  footer={false}
                  visible={this.state.lotSelectorVisible}
                  maskClosable={false}
                  centered
                  width={window.innerWidth > 1280 ? "80vw" : "90vw"}
                >
                  <LotSelector
                    lotNumber={this.state.lotNumber}
                    locationCode={this.state.currentLocation}
                    handleStateChange={this.handleStateChange}
                    dataSource={this.state.modalItemDataSource}
                    setDataSource={(value) => {
                      this.setState({ modalItemDataSource: value });
                    }}
                    targetQuantity={this.state.calcQty}
                    visible={this.state.lotSelectorVisible}
                    setVisible={(value) => {
                      this.setState({ lotSelectorVisible: value });
                    }}
                    setSelectedLots={(value) => {
                      this.setState({ selectedLots: value });
                    }}
                    selectedLots={this.state.selectedLots}
                    setLotsSelected={(value) => {
                      this.setState({ lotsSelected: value });
                    }}
                  ></LotSelector>
                </Modal>
              </>
            ) : (
              <Alert
                style={{ width: "100%" }}
                showIcon
                message={<h3>Heat # not required</h3>}
                type="success"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  componentDidUpdate(prevProps, prevStates) {
    if (
      (this.props.visible !== prevProps.visible &&
        this.props.visible === true) ||
      this.state.isPosted !== prevStates.isPosted
    ) {
      this.setState({ historyComment: [] }); // To Reset historyComment (If not, data is somehow accumulated)

      axios
        .post(
          `${CONST_API_URL}/consumption/GetLastPostHeatNumberAndLocationCode`,
          {
            MO: this.props.data.MO_No,
            operationNo: this.props.operationNo,
          }
        )
        .then((res) => {
          this.setState({
            lastHeatNumber: res.data[res.data.length - 1].heat_no,
            lastHeatNumberLocationCode:
              res.data[res.data.length - 1].location_no,
          });
        });
      let newQty =
        parseFloat(Number(this.props.partCount)) *
        parseFloat(this.props.data.ComponentQuantityPer) *
        (1 + parseFloat(this.props.data.ComponentScrapPercent) / 100);
      newQty = Math.round((newQty + Number.EPSILON) * 100000) / 100000;
      this.setState({
        cutQty: Number(this.props.partCount),
        calcQty: newQty,
      });
      axios
        .post(`${CONST_API_URL}/consumption/ItemLotAvailability`, {
          itemNo: this.props.data.ComponentItemNo,
          division: this.props.division,
        })
        .then((res) => {
          if (
            res.data[0]?.label === "NOT AVAILABLE" ||
            res.data[0]?.value === "NOT AVAILABLE"
          ) {
            this.setState({ modalItemDataSource: [], noLotAvailableFg: true });
          } else {
            this.setState({
              modalItemDataSource: res.data,
              noLotAvailableFg: false,
            });
          }
          if (res.data.length === 0 && this.state.lotNoMantory) {
            this.setState({
              submsg: "The current item has no availability at any lot",
            });
          }
          if (res.data) {
            if (res.data.some((e) => e.heat_lot_no_required === "true")) {
              this.setState({ lotNoMantory: true });
            } else {
              this.setState({ lotNoMantory: false });
            }
          }
          if (this.state.lastHeatNumber !== "" && this.state.lotNoMantory) {
            let selected = res.data.find(
              (item) =>
                item.location_code === this.state.lastHeatNumberLocationCode &&
                item.value === this.state.lastHeatNumber
            );
            if (selected) {
              this.setState({
                lotNumber: selected.value,
                currentLotMaxQuantity: selected.total_available_quantity,
                // currentLocation: selected.location_code,
              });
            }
          }
        });

      this.setState({ isLoading: true });
      const history = {
        consumptionSource: "MO",
        webUserID: concatenateString(this.props.user),
        mO_Job_No: this.props.data.MO_No,
        item_No: this.props.data.ComponentItemNo,
        mO_Line_No: this.props.data.MO_Line_No,
        division_Code: this.props.division,
      };
      axios
        .post(`${CONST_API_URL}/consumption/CutHistory`, history)
        .then((res) => {
          let tempusedQty = 0;
          let tempHistoryComment = [];
          res.data.Cuthistory.forEach((item, index) => {
            if (isNaN(parseFloat(item.Comment.split(",")[1]))) {
              tempHistoryComment.push(item.Comment);
              if (index !== 0 && item.Comment.split(",").length === 9) {
                tempusedQty =
                  tempusedQty + parseFloat(item.Comment.split(",")[4]);
              }
            } else {
              let comma = item.Comment.indexOf(",");
              let comment =
                item.Comment.substring(0, comma) +
                item.Comment.substring(comma + 1);
              tempHistoryComment.push(comment);
              if (index !== 0 && comment.split(",").length === 9) {
                tempusedQty = tempusedQty + parseFloat(comment.split(",")[4]);
              }
            }
          });
          tempusedQty =
            Math.round((tempusedQty + Number.EPSILON) * 100000) / 100000;
          this.setState(
            {
              isLoading: false,
              historyComment: tempHistoryComment,
              remainingQty:
                Math.round(
                  (parseFloat(
                    Number(this.props.data.MO_Line_Quantity) - tempusedQty
                  ) +
                    Number.EPSILON) *
                    100000
                ) / 100000,
            },
            () => {}
          );
        });

      //Activate Notification
      if (this.state.isPosted === true) {
        this.openNotification("success");
        this.onCancel();
      }
    }
  }

  render() {
    return (
      <>
        <Modal
          // title={this.showTitle()}
          //zIndex={1003}
          destroyOnClose
          closable={false}
          visible={this.props.visible}
          onCancel={() => this.onCancel()}
          centered
          footer={this.showFooter()}
          width="90vw"
          style={{ maxHeight: "100vh" }}
          okButtonProps={{
            disabled: this.state.isLoading ? true : false,
          }}
          cancelButtonProps={{
            disabled: this.state.isLoading ? true : false,
          }}
        >
          {this.state.isLoading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10000000000000,
                color: "black",
                background: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin
                size="large"
                tip={this.state.status === 1 ? "Posting to NAV" : "Loading"}
              ></Spin>
            </div>
          ) : (
            ""
          )}

          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            {this.loadItemData()}
          </div>
        </Modal>
        <POCErrorModal
          visible={this.state.errorModalVisible}
          onCancel={this.closeErrorModalMessage}
          data={this.state.errorModalData}
          setEmailSent={this.setEmailSent}
          setEmailSuccessful={this.setEmailSuccessful}
          setErrorModalVisible={this.setErrorModalVisible}
          emailSent={this.state.emailSent}
          emailSentSuccessful={this.state.emailSentSuccessful}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  division: state.appConfig.division,
  workcenter: state.workCenter,
});

export default connect(mapStateToProps)(withRouter(POCModal));

// export default POCModal;
