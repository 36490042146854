import React from "react";
import "./datagrid.css";
import axios from "axios";
import POCModal from "./pocmodal";
import { notification } from "antd";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { CONST_API_URL } from "../../../appconfig";
class POCButton extends React.Component {
  state = {
    modalVisible: false,
    modalDataSource: [],
  };

  onModalCancle = () => {
    this.setState({ modalVisible: false });
  };

  handleButtonClick = () => {
    if (this.state.modalVisible === false) {
      axios
        .post(`${CONST_API_URL}/consumption/ComponentItems`, {
          MO: this.props.MO,
        })
        .then((res) => {
          if (res.data === "No") {
            notification.error({
              message: "Failed",
              description: `Error: The Bill of Materials of this MO is blank`,
              duration: 5,
            });
          } else {
            this.setState({ modalVisible: true, modalDataSource: res.data[0] });
          }
        });
    } else {
      this.setState({ modalVisible: false, modalDataSource: [] });
    }
  };

  render() {
    return (
      <>
        <POCModal
          progressSetupTime={this.props.progressSetupTime}
          hspop={this.props.hspop}
          operationNoChar={this.props.operationNoChar}
          operationNo={this.props.operationNo}
          workcenterNo={this.props.workcenterNo}
          visible={this.state.modalVisible}
          onCancel={this.onModalCancle}
          data={this.state.modalDataSource}
          user={this.props.user}
          partCount={this.props.partCount}
          scrapCount={this.props.scrapCount}
          parentOnSubmit={() => this.props.onSubmit()}
          conditionalPartCount={this.props.conditionalPartCount}
          onUpdateCurrentPartCount={this.props.onUpdateCurrentPartCount}
          refreshPartScrapTotalCount={this.props.refreshPartScrapTotalCount}
          fetchSelectedHeatNumber={this.props.fetchSelectedHeatNumber}
        />
      </>
    );
  }
}

export default POCButton;
