import React, { useState, useEffect } from "react";
import { List, Input, Card, Avatar, Button } from "antd";
import { getLoggedInUsers } from "./work-center-table.services";
import LoadingSpinner from "../../loading-spinner/loading-spinner.component";
import { useHistory } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";

import "./work-center-table.styles.css";

const WorkCenterTable = () => {
  const [workCenters, setWorkCenters] = useState([]);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    refreshList();
  }, []);

  function refreshList() {
    setLoading(true);
    getLoggedInUsers().then((result) => {
      console.log(result);
      setWorkCenters(result);
      setLoading(false);
    });
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function renderWorkCenters() {
    return (
      <div className="allwc__container">
        <div className="header__container">
          <div className="back__button">
            <Button
              onClick={() => {
                history.push("/");
              }}
            >
              Back
            </Button>
          </div>
          <div className="refresh__button">
            <Button
              onClick={() => {
                refreshList();
              }}
            >
              Refresh
            </Button>
          </div>
        </div>

        {Object.entries(workCenters).map((entry, index) => {
          return (
            <div
              key={entry + index}
              className="wc__container animate__animated animate__fadeInLeft"
            >
              <Card>
                <h1>{toTitleCase(entry[0])}</h1>
                <List
                  bordered
                  dataSource={entry[1]}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <div className="wc__container--avatar">
                            <Avatar>
                              {item.first_name[0]}
                              {item.last_name[0]}
                            </Avatar>
                          </div>
                        }
                        title={
                          <h2>{`${item.first_name} ${item.last_name}`}</h2>
                        }
                        description={
                          <h5 style={{ color: "grey" }}>{item.position}</h5>
                        }
                      />
                    </List.Item>
                  )}
                ></List>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }

  return <> {loading ? <></> : renderWorkCenters()}</>;
};

export default WorkCenterTable;
