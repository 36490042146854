import { Button, Table, Row, Col, Divider } from "antd";
import React from "react";
import "./consumptionTable.styles.css";
import ConsumptionTableFirstStep from "./consumptionFirstStep/consumptionFirstStep.component";
import ConsumptionSecondStep from "./consumptionSecondStep/consumptionSecondStep.component";
import config from "../../config";
// const data = [
//     {
//         key: '1',
//         name: 'John Brown',
//         age: 32,
//         address: 'New York No. 1 Lake Park',
//     },
//     {
//         key: '2',
//         name: 'Jim Green',
//         age: 42,
//         address: 'London No. 1 Lake Park',
//     },
//     {
//         key: '3',
//         name: 'Joe Black',
//         age: 32,
//         address: 'Sidney No. 1 Lake Park',
//     },
// ];

const columns = [
  {
    title: "Item No.",
    dataIndex: "ComponentItemNo",
    key: "ComponentItemNo",
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",
  },
  {
    title: "Qty Per Consumption",
    dataIndex: "ComponentQualityPer",
    key: "ComponentQualityPer",
  },
  {
    title: "Unit",
    dataIndex: "UnitOfMeasurementCode",
    key: "UnitOfMeasurementCode",
  },
];

const columnForTable = [
  {
    title: "Component Item No.",
    dataIndex: "ComponentItemNo",
    key: "ComponentItemNo",
  },
  {
    title: "Description",
    dataIndex: "PartDescription",
    key: "PartDescription",
  },
  { title: "UOM", dataIndex: "ComponentUOM", key: "ComponentUOM" },
  {
    title: "Consumed Quantity",
    dataIndex: "InputQuantity",
    key: "InputQuantity",
  },
  {
    title: "Scrap Quantity",
    dataIndex: "ScrapQuantity",
    key: "ScrapQuantity",
  },
  {
    title: "Total Quantity",
    dataIndex: "TotalQuantity",
    key: "TotalQuantity",
  },

  // {
  //   title: "Location",
  //   dataIndex: "Location",
  //   key: "Location",
  // },
  // {
  //   title: "Lot Number",
  //   dataIndex: "LotNumber",
  //   key: "LotNumber",
  // },
];

class ConsumptionTable extends React.Component {
  state = {
    currentState: this.props.sendBackState,
    // consumptionTableVisible: true,
    // newConsumptionTableVisible: false,
    choices: [],
    //  stepTableVisible: false,
    selected: "",
    selectedRowKeys: [],
    index: 0,
    currentStep: 0,
  };

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    // Delete all selected
    var newState = this.state.currentState.filter(
      (elem) => !this.state.selectedRowKeys.includes(elem.number)
    );
    var newSendBackState = this.props.sendBackState.filter(
      (elem) => !this.state.selectedRowKeys.includes(elem.number)
    );
    this.setState({ currentState: newState });
    // this.setState({ sendBackState: newSendBackState });
    this.props.handleSetSendBackState(newSendBackState);
    this.setState({ selectedRowKeys: [] });
  };

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.currentState.length !== this.state.choices.length) {
      this.props.setLoading(true);
      var object = this.props.currentState.map((elem) => ({
        key: elem.ComponentItemNo,
        ComponentItemNo: elem.ComponentItemNo,
        Description: elem.ComponentItemDescription,
        ComponentQualityPer: elem.ComponentQuantityPer,
        UnitOfMeasurementCode: elem.Unit_of_Measure_Code,
        ...elem,
      }));
      this.setState({ choices: object });
      this.props.setLoading(false);
    }
  }

  openAddTable = () => {
    // this.setState({ consumptionTableVisible: false });
    //  this.setState({ newConsumptionTableVisible: true });
    this.props.setComponentStep(1);
  };

  onOpenStepForm = (record, rowIndex) => {
    //  this.setState({ newConsumptionTableVisible: false });
    //  this.setState({ stepTableVisible: true });
    this.setState({ selected: rowIndex });
    this.props.setComponentStep(2);
  };

  cancelStepForm = () => {
    // this.setState({ stepTableVisible: false });
    //  this.setState({ consumptionTableVisible: true });
    this.props.setComponentStep(0);
  };

  onClickRow = (tempobject) => {
    // Save the rowIndex into original
    var object = {
      ...tempobject,
      key: this.state.index,
      number: this.state.index,
    };
    var addedConsumptionTable = [...this.props.sendBackState, object];
    console.log("addConsumptionTable", addedConsumptionTable);
    this.props.handleSetSendBackState(addedConsumptionTable);
    // var addedConsumptionTable = [...this.state.currentState, object];
    // this.setState({ currentState: addedConsumptionTable });
    // this.setState({ stepTableVisible: false });
    // this.setState({ consumptionTableVisible: true });
    this.props.setComponentStep(2);
  };

  sendBack = (object, index) => {
    var tempObject = {
      ...object,
      ...this.state.choices[index],
      number: this.state.index,
    };
    var addedSendBack = [...this.props.sendBackState, tempObject];
    // this.setState({ sendBackState: addedSendBack });
    this.props.handleSetSendBackState(addedSendBack);
    this.setState({ index: this.state.index + 1 });
    this.props.setComponentStep(0);
  };

  renderBody = () => {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };
    if (this.props.componentStep === 0) {
      return (
        <div style={{}} className={"consumptionTable" + this.state.visible}>
          <h1
            className=" animate__animated animate__fadeIn"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              background: "#fafafa",
              padding: "10px",
              border: "1px solid #f0f0f0",
              background: "#1e1e1e",
              color: "white",
            }}
          >
            Components
          </h1>

          <div
            style={{
              overflow: "auto",
              // , height: "400px"
            }}
            className="component__table--big  animate__animated animate__fadeIn"
          >
            {" "}
            <Table
              rowSelection={rowSelection}
              columns={columnForTable}
              dataSource={this.props.sendBackState}
              rowKey={"number"}
              footer={() => {
                return <div></div>;
              }}
            />
          </div>
          <Row>
            <Col span={11} style={{ margin: "10px 0" }}>
              {" "}
              <Button
                style={{
                  fontSize: "2rem",

                  height: "fit-content",
                  backgroundColor: config.branding.primaryColor,
                  borderColor: config.branding.primaryColor,
                }}
                onClick={() => this.openAddTable()}
                type="primary"
                disabled={this.props.loading}
              >
                Add Component
              </Button>
              <Button
                style={{
                  fontSize: "2rem",
                  marginLeft: "20px",
                  height: "fit-content",
                  backgroundColor: config.branding.primaryColor,
                  borderColor: config.branding.primaryColor,
                }}
                disabled={!this.state.selectedRowKeys.length > 0}
                type="primary"
                onClick={() => this.onDelete()}
              >
                Delete Component
              </Button>
            </Col>
          </Row>
        </div>
      );
    } else if (this.props.componentStep === 1) {
      return (
        <ConsumptionTableFirstStep
          choices={this.state.choices}
          finishFirst={(record, rowIndex) =>
            this.onOpenStepForm(record, rowIndex)
          }
          onCancel={this.cancelStepForm}
        />
      );
    } else if (this.props.componentStep === 2) {
      console.log("StepTable is Visible");
      return (
        <ConsumptionSecondStep
          inputPartCount={this.props.inputPartCount}
          key={this.state.selected}
          index={this.state.selected}
          lotInfo={this.props.currentState[this.state.selected].lotInfo}
          lotMandatory={
            // true
            this.props.currentState[this.state.selected].lotMandatory
          }
          inputQty={this.props.currentState[this.state.selected].inputQuantity}
          scrapQty={this.props.currentState[this.state.selected].scrapQuantity}
          componentScrapPercent={
            this.props.currentState[this.state.selected].ComponentScrapPercent
          }
          onChange={(e) => handleOnChange(index, e)}
          totalQty={this.props.currentState[this.state.selected].totalQuantity}
          // lotNo={this.props.currentState[this.state.selected].lotNo}
          // onSave={(e, lotNo, location, quantity) =>
          //     handleOnSave(index, e, lotNo, location, quantity)
          // }
          name={this.props.currentState[this.state.selected].name}
          location={this.props.currentState[this.state.selected].location}
          unit={this.props.currentState[this.state.selected].ComponentUOM}
          quantityPer={
            this.props.currentState[this.state.selected].ComponentQuantityPer
          }
          cancelStepForm={this.cancelStepForm}
          addToTable={(object) => this.onClickRow(object)}
          sendBack={(object, index) => this.sendBack(object, index)}
        />
      );
    } else {
      return <div> </div>;
    }
  };

  render() {
    return this.renderBody();
  }
}

export default ConsumptionTable;
