import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hiddenWorkCenters: [],
};

const deviceConfigSlice = createSlice({
  name: "deviceConfig",
  initialState: initialState,
  reducers: {
    updateHiddenWorkCenters(state, action) {
      state.hiddenWorkCenters = action.payload;
    },

    overwriteDeviceConfig(state, action) {
      return action.payload;
    },
  },
});

export const deviceConfigActions = deviceConfigSlice.actions;
export default deviceConfigSlice;
