import { useState } from "react";

import { Drawer } from "antd";
import { Button } from "primereact/button";
import { connect } from "react-redux";

import {
  SettingOutlined,
  CloseOutlined,
  FilterOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import WorkStationFilter from "./workStationFilter/workStationFilter.component";
import DeviceSetup from "./deviceSetup/deviceSetup.component";

import MenuItem from "./menuItem/menuItem.component";
import SupervisorPermissionComponent from "../SupervisorPermissionModal/SupervisorPermission.component";

const SettingsDrawer = ({ items, division }) => {
  const [open, setOpen] = useState(false);
  const [setting, setSetting] = useState("");
  const [supervisorPopupVisible, setSupervisorPopupVisible] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  ///////////////////////////////////////////////////

  const menu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <MenuItem
          label="Device Setup"
          icon={<TabletOutlined />}
          onClick={() => {
            setSetting("setup");
          }}
        />
        <MenuItem
          label="Filter Work Stations"
          icon={<FilterOutlined />}
          onClick={() => {
            setSetting("filter");
          }}
          disabled={division ? false : true}
        />
      </div>
    );
  };

  const chosenSetting = () => {
    switch (setting) {
      case "filter":
        return <WorkStationFilter items={items} />;
      case "setup":
        return <DeviceSetup />;
      default:
        return;
    }
  };

  const onSuccessSupervisorApprovalHandler = () => {
    setSupervisorPopupVisible(false);
    showDrawer();
  };

  const onClickSettingsButton = () => {
    if (!!!division) {
      return;
    }
    setSupervisorPopupVisible(true);
  };

  return (
    <>
      <Button
        size="lg"
        type="primary"
        style={{ fontSize: "3rem" }}
        onClick={onClickSettingsButton}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SettingOutlined />
        </div>
      </Button>
      <Drawer
        width={800}
        title={null}
        placement="right"
        onClose={onClose}
        visible={open}
        getContainer={false}
        zIndex={10000}
        closable={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", fontSize: "3rem", fontWeight: "600" }}>
            Settings
          </div>
          <div>
            {setting ? (
              <div className="animate__animated animate__fadeIn">
                <Button
                  size="lg"
                  type="primary"
                  style={{ fontSize: "3rem" }}
                  onClick={() => {
                    setSetting("");
                  }}
                >
                  <h1 style={{ color: "#FFF" }}>Back</h1>
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Button
              size="lg"
              type="primary"
              style={{ fontSize: "3rem" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CloseOutlined />
              </div>
            </Button>
          </div>
        </div>

        <div style={{ padding: "20px" }}>
          {setting ? (
            <div className="animate__animated animate__fadeInLeft">
              {chosenSetting()}
            </div>
          ) : (
            menu()
          )}
        </div>
      </Drawer>
      <SupervisorPermissionComponent
        showModal={supervisorPopupVisible}
        type={"Supervisor Approval"}
        message={"Supervisor approval required"}
        onCancel={() => setSupervisorPopupVisible(false)}
        bgColor="black"
        dialogWidth="60vw"
        onSuccess={() => onSuccessSupervisorApprovalHandler()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  division: state.appConfig.division,
});

export default connect(mapStateToProps)(SettingsDrawer);
