import React from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
export default class QcInspectionButton extends React.Component {
  render() {
    const visibility = this.setDisplay();
    return (
      <Card style={{ height: "100%", marginLeft: "10px" }}>
        {visibility === "hidden" ? (
          ""
        ) : (
          <Button
            label="QC Inspection"
            className="p-button-danger"
            style={{
              width: "100%",
              fontSize: "30px",

              padding: "4px",
            }}
            onClick={this.props.onClick}
            onTouchEnd={this.props.onClick}
          />
        )}
        <Button
          label="Drawing"
          className="p-button-danger"
          style={{
            width: "100%",
            fontSize: "30px",

            padding: "4px",
            marginTop: "10px",
          }}
          onClick={this.props.onDrawingClick}
          onTouchEnd={this.props.onDrawingClick}
        />
      </Card>
    );
  }

  setDisplay = () => {
    if (this.props.inspectionEnabled === undefined) {
      return "hidden";
    }
    return this.props.inspectionEnabled ? "unset" : "hidden";
  };
}
