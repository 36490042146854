import React from "react";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./numpad.styles.css";
import {
  notDoubleDecimal,
  notExceededMax,
  hasDecimal,
  notExceededMaxWhole,
} from "./numpad.functions";

const numPadKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9];

/* <PROPS>
	value = numbersEntered,
	numberType,
	maxDecimals
*/
class NumPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numbersEntered: this.props.value,
      numberType: this.props.allowDecimals ? "decimal" : "int",
      maxDecimals: this.props.maxDecimals,
      maxWhole: this.props.maxWhole || 10,
      override: false,
    };
  }

  keyboardEventListener = (event) => {
    const { key } = event;
    const { numbersEntered } = this.state;
    if (!isNaN(key)) {
      this.handleAdd(key);
    } else {
      switch (key) {
        case ".":
          this.handleAdd(".");
          break;
        case "Backspace":
          this.handleDelete(numbersEntered);
          break;
        case "Enter":
          this.handleFinish();
          break;
        default:
      }
    }
  };

  componentDidMount() {
    window.addEventListener("keydown", this.keyboardEventListener);
  }

  componentDidUpdate() {
    window.addEventListener("keydown", this.keyboardEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyboardEventListener);
  }

  renderMessage = (maxExceeded, maxDecimals, maxExceededWhole, maxWhole) => {
    console.log("Rendering message");
    var message;

    if (maxExceededWhole && !this.state.override) {
      message = <span>Maximum {maxWhole} whole numbers allowed!</span>;
    } else if (maxExceeded && this.state.override) {
      message = <span>Maximum {maxDecimals} decimals allowed!</span>;
    }

    return message;

    // return maxExceeded ? (
    //   <span>Maximum {maxDecimals} decimals allowed!</span>
    // ) : (
    //   ""
    // );
  };

  renderButtons = (maxExceeded, maxExceededWhole) => {
    return numPadKeys.map((key) => (
      <Button
        onClick={() => this.handleAdd(key)}
        disabled={maxExceeded || (maxExceededWhole && !this.state.override)}
        key={`numpad-${key}`}
      >
        {key}
      </Button>
    ));
  };

  render() {
    const { numbersEntered, numberType, maxDecimals, maxWhole } = this.state;
    const maxExceeded = !notExceededMax(numbersEntered, maxDecimals);
    const maxExceededWhole = !notExceededMaxWhole(numbersEntered, maxWhole);

    return (
      <div className="numpad-component">
        <div className="numpad-display">{numbersEntered}</div>
        <div className="numpad-message">
          {this.renderMessage(
            maxExceeded,
            maxDecimals,
            maxExceededWhole,
            maxWhole
          )}
        </div>
        <div className="numpad-grid-container">
          <div className="numpad-grid">
            {this.renderButtons(maxExceeded, maxExceededWhole)}
            <Button
              onClick={() => {
                this.handleAdd(".");
                this.setState({ override: true });
              }}
              key="numpad-decimal"
              disabled={numberType === "int" || hasDecimal(numbersEntered)}
            >
              .
            </Button>
            <Button
              onClick={() => this.handleAdd("0")}
              disabled={
                maxExceeded || (maxExceededWhole && !this.state.override)
              }
              key="numpad-0"
            >
              0
            </Button>
            <Button
              onClick={() => {
                this.handleNegative();
                this.setState({ override: true });
              }}
              key="numpad-negative"
            >
              +/-
            </Button>
            <Button
              onClick={() => this.handleDelete(numbersEntered)}
              key="numpad-delete"
            >
              <ArrowLeftOutlined className="arrow-left-icon" />
            </Button>
          </div>
        </div>
        <Button className="light-btn" onClick={this.handleFinish}>
          done
        </Button>
      </div>
    );
  }

  addNumber(existingNumber, newNumber) {
    const updatedNumber = !existingNumber
      ? newNumber === "."
        ? "0" + newNumber
        : newNumber
      : `${existingNumber}` + `${newNumber}`;
    this.setState({ numbersEntered: updatedNumber });
  }

  handleAdd = (input) => {
    const { numbersEntered, numberType, maxDecimals, maxWhole } = this.state;

    if (numberType === "int" && input !== ".") {
      this.addNumber(numbersEntered, input);
    } else if (numberType === "decimal") {
      console.log("Adding decimals");
      if (
        notDoubleDecimal(numbersEntered, input) &&
        notExceededMax(numbersEntered, maxDecimals)
      ) {
        this.addNumber(numbersEntered, input);
      }
    }
  };

  //Turn the inputted number into a negative/positive depending on it's current state
  handleNegative = () => {
    const { numbersEntered } = this.state;
    var numbers = String(numbersEntered);

    if (numbers.includes("-")) {
      this.setState({ numbersEntered: String(Math.abs(numbers)) });
    } else {
      this.setState({ numbersEntered: "-" + numbers });
    }
  };

  handleDelete = (numbersEntered) => {
    if (numbersEntered) {
      const digits = numbersEntered.length;
      const removed = numbersEntered[digits - 1];
      const newNumbers = `${numbersEntered}`.slice(0, digits - 1);

      let updatedStates = {};
      updatedStates.numbersEntered = newNumbers;
      if (removed === ".") {
        updatedStates.decimalEntered = false;
      }

      this.setState(updatedStates);
    }
  };

  handleFinish = () => {
    const { numbersEntered } = this.state;

    // remove keydown event
    window.removeEventListener("keydown", this.keyboardEventListener);

    this.props.onFinish(numbersEntered);
  };
}

NumPad.defaultProps = {
  allowDecimals: false,
  value: undefined,
  maxDecimals: 10,
};

export default NumPad;
