import React from "react";
import { Table, Button, Divider, Switch } from "antd";
import "./consumptionFirstStep.scss";

const columns = [
  {
    title: "Component Item No.",
    dataIndex: "ComponentItemNo",
    key: "ComponentItemNo",
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",
  },
  {
    title: "Component Quantity Per",
    dataIndex: "ComponentQualityPer",
    key: "ComponentQualityPer",
  },
];

class ConsumptionTableFirstStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHeight: 200,
      tableLoading: true,
      defaultState: true,
    };
  }

  updateTableHeight = () => {
    const { height: wrapperHeight } = document
      .querySelector(".multi-consumption-modal .ant-table-wrapper")
      .getBoundingClientRect();
    const { height: headerHeight } = document
      .querySelector(".multi-consumption-modal .ant-table-thead")
      .getBoundingClientRect();

    const tableHeight = wrapperHeight - headerHeight;
    this.setState({ tableHeight });
  };

  componentDidMount() {
    this.updateTableHeight();
    window.addEventListener("resize", this.updateTableHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateTableHeight);
  }

  render() {
    const { tableHeight, tableLoading } = this.state;
    const { choices, finishFirst, onCancel } = this.props;
    return (
      <div className="consumption-first-step animate__animated animate__fadeIn">
        <div className="header">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "bold",
              background: "#fafafa",
              padding: "10px",
              border: "1px solid #f0f0f0",
              background: "#1e1e1e",
              color: "white",
            }}
          >
            Step 1: Select Component
          </h1>

          <Button
            type="primary"
            className="back-button"
            onClick={(e) => onCancel(e)}
            onTouchEnd={(e) => {
              e.preventDefault();
              onCancel(e);
            }}
          >
            Back
          </Button>
        </div>
        <Divider />
        <div className="select__table--big">
          {" "}
          <Table
            scroll={{ y: tableHeight }}
            pagination={false}
            columns={columns}
            dataSource={this.props.choices}
            onRow={(record, rowIndex) => {
              const index = this.props.choices.findIndex((object) => {
                return object.key === record.key;
              });

              return {
                onClick: () => finishFirst(record, index),
              };
            }}
          />
        </div>
      </div>
    );
  }
}

export default ConsumptionTableFirstStep;
