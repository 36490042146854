import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notification, Modal } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { attemptLoginService, userLoginLogoutService } from "./KeyPad.service";
import "./KeyPad.styles.css";
import SupervisorPermissionComponent from "../SupervisorPermissionModal/SupervisorPermission.component";
import { workCenterActions } from "../../store/work-center-slice";
class KeyPad extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      label: "Please Enter Your Pin",
      showSupervisorPopup: false,
      supervisorPinpadMessage: "",
    };

    this.addDigit = this.addDigit.bind(this);
    this.clearDigits = this.clearDigits.bind(this);
    this.renderPin = this.renderPin.bind(this);
    this.attemptLoginorLogout = this.attemptLoginorLogout.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // 12 is the column limit in db
  addDigit(digit, limit = 12, e) {
    const { pin } = this.state;
    if (pin.length < limit) {
      const newPin = pin + digit;
      this.setState({ pin: newPin });
    }
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  }

  clearDigits(e) {
    this.setState({ pin: "", label: "Please Enter Your Pin" });
    let currentPin = this.state.pin;
    if (currentPin === "") {
      this.props.onCancel();
    }
    if (e && e.type === "touchend") {
      e.preventDefault();
    }
  }

  renderPin() {
    return this.state.pin.replace(/.(?=.{1,}$)/g, "*");
  }

  attemptLoginorLogout(supervisorApproved = false) {
    attemptLoginService(this.state.pin, this.props.division).then((res) => {
      if (res.data?.length < 1) {
        return this.setState({
          pin: "",
          label: "Invalid Pin. Please try again.",
        });
      } else if (!res.data[0].active) {
        return this.setState({
          pin: "",
          label: "Entered Pin is deactivated.\nPlease contact supervisor.",
        });
      }

      let firstName = res.data[0].first_name;
      let lastName = res.data[0].last_name;
      userLoginLogoutService(
        res.data[0],
        this.props.users,
        this.props.workCenter.workcenter_no,
        supervisorApproved
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.config.url.includes("Login")) {
              notification.success({
                message: "Success",
                description: `Hi, ${firstName} ${lastName}. You have successfully logged in.`,
                duration: 10,
                style: {
                  width: "40vw",
                },
              });
            } else {
              notification.success({
                message: "Success",
                description: `Hi, ${firstName} ${lastName}. You have successfully logged out.`,
                duration: 10,
                style: {
                  width: "40vw",
                },
              });
            }
            this.clearDigits();
            this.props.onCancel();
          }
        })
        .catch((error) => {
          const errorMsg =
            error?.response?.data?.msg || "Unexpected Error occurred";
          const errorCode = error?.response?.data?.errorCode;
          if (errorCode === 1) {
            notification.error({
              description: errorMsg,
              duration: 10,
              style: {
                minWidth: "30vw",
              },
            });
          } else if (errorCode === 2) {
            this.setState({
              showSupervisorPopup: true,
              supervisorPinpadMessage:
                "Supervisor approval required to sign out without posting",
            });
          }
        });
    });
  }

  onClickCancel = () => {
    this.clearDigits();
    this.props.onCancel();
  };

  onClickHandler = () => {
    if (this.props.requireSupervisorApproval === true) {
      this.setState({ showSupervisorPopup: true });
    } else {
      this.attemptLoginorLogout();
    }
  };

  onSuccessHandler = () => {
    this.attemptLoginorLogout(true);
  };

  render() {
    return (
      <>
        <Modal
          destroyOnClose
          visible={this.props.visible}
          onCancel={() => this.onClickCancel()}
          width="85vw"
          zIndex={2000}
          footer={null}
          centered
        >
          <div className="keypad-background">
            <div className="keypad-container">
              <div className="keypad keypad-grid">
                <div className="keypad-trackpad">
                  <span className="keypad-label">{this.state.label}</span>
                  <div className="keypad-pin">{this.renderPin()}</div>
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(1, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(1, undefined, e)}
                >
                  1
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(2, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(2, undefined, e)}
                >
                  2
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(3, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(3, undefined, e)}
                >
                  3
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(4, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(4, undefined, e)}
                >
                  4
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(5, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(5, undefined, e)}
                >
                  5
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(6, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(6, undefined, e)}
                >
                  6
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(7, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(7, undefined, e)}
                >
                  7
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(8, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(8, undefined, e)}
                >
                  8
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(9, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(9, undefined, e)}
                >
                  9
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.clearDigits(e)}
                  onTouchEnd={(e) => this.clearDigits(e)}
                >
                  {this.state.pin === "" ? (
                    <RollbackOutlined />
                  ) : (
                    <CloseOutlined className="keypad-icon" />
                  )}
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.addDigit(0, undefined, e)}
                  onTouchEnd={(e) => this.addDigit(0, undefined, e)}
                >
                  0
                </div>
                <div
                  className="keypad-block"
                  onClick={(e) => this.onClickHandler()}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                    this.onClickHandler();
                  }}
                >
                  <CheckOutlined className="keypad-icon" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <SupervisorPermissionComponent
          showModal={this.state.showSupervisorPopup}
          type={"Supervisor Approval"}
          message={this.state.supervisorPinpadMessage}
          onCancel={() =>
            this.setState({
              showSupervisorPopup: false,
              supervisorPinpadMessage: "",
            })
          }
          bgColor="black"
          dialogWidth="60vw"
          onSuccess={() => this.onSuccessHandler()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  workCenter: state.workCenter,
  division: state.appConfig.division,
  users: state.workCenter.users,
});

export default connect(mapStateToProps)(withRouter(KeyPad));
