import { useState, useEffect } from "react";
import { Divider, notification } from "antd";

import LotSelectorPageOne from "./pages/lot-selector-page-one.component";
import LotSelectorPageTwo from "./pages/lot-selector-page-two.component";
import LotSelectorPageThree from "./pages/lot-selector-page-three.component";

const TOTAL_PAGES = 3;

const SHOW_BACK = [2, 3];
const SHOW_NEXT = [2];

const LotSelector = (props) => {
  const [page, setPage] = useState(1);
  const [locationSelections, setLocationSelections] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLotsBackup, setSelectedLotsBackup] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [dataSourceBackup, setDataSourceBackup] = useState([]);

  useEffect(() => {
    //Backup datasource on mount
    setDataSourceBackup(props.dataSource);
  }, []);

  useEffect(() => {
    props.handleStateChange("", "currentLocation"); //Reset location selection
    if (props.lotNumber !== "") {
      setLocationSelections(
        extractLocations(props.dataSource, props.lotNumber)
      );
    }
  }, [props.lotNumber]);

  /**
   * Get the locations for the chosen lot
   *
   * @param {Object[]} data
   * @param {String} lotNumber
   * @returns location array
   */
  function extractLocations(data, lotNumber) {
    var locations = [];

    for (var i = 0; i < data.length; i++) {
      if (data[i].value === lotNumber) {
        locations.push({
          id: Math.random(),
          lotNumber: data[i].value,
          location: data[i].location_code,
          quantity: data[i].total_available_quantity,
          originalQuantity: data[i].total_available_quantity, //Used as backup if quantity ever changes to fulfill a lot
        });
      }
    }

    return locations;
  }

  function nextPage() {
    if (page < TOTAL_PAGES) setPage(page + 1);

    if (page === 2) {
      //Set backup of selected lots array so that user can go back
      setSelectedLotsBackup([...props.selectedLots]);
    }
  }

  function prevPage() {
    if (page > 0) setPage(page - 1);

    //Reset next button to disabled state
    setNextDisabled(true);

    //Reset selected locations when going back
    setSelectedLocations([]);

    //Reset lot/heat data source to unfiltered backup
    props.setDataSource(dataSourceBackup);

    //Recover old selected lots array if user goes back
    if (page === 3) {
      props.setSelectedLots([...selectedLotsBackup]);
    }
  }

  //Used if user does not fulfill target quantity with chosen lot
  function loopBack() {
    setSelectedLocations([]);
    setPage(1);
  }

  function getLotTotal() {
    var total = 0;
    for (var i = 0; i < props.selectedLots.length; i++) {
      total += props.selectedLots[i].quantity;
    }
    return total;
  }

  function calculateCurrentTarget() {
    var target = 0;
    target = props.targetQuantity - getLotTotal();
    target = target;
    return target;
  }

  function deleteFromSelectedLots(index) {
    var clone = [...props.selectedLots];

    clone.splice(index, 1);

    props.setSelectedLots(clone);
  }

  //Render Button component for last page
  function getLastButton() {
    if (calculateCurrentTarget() <= 0) {
      return (
        <div
          style={{
            padding: "10px",
            borderRadius: "7px",
            marginRight: "10px",
            background: "#1890ff",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setVisible(false);
            props.setLotsSelected(true);
          }}
        >
          <h1 style={{ fontWeight: "700", color: "white" }}>Finish</h1>
        </div>
      );
    } else {
      return (
        <div
          style={{
            padding: "10px",
            borderRadius: "7px",
            marginRight: "10px",
            background: "#1890ff",
            cursor: "pointer",
          }}
          onClick={() => {
            loopBack();
          }}
        >
          <h1 style={{ fontWeight: "700", color: "white" }}>Next Lot</h1>
        </div>
      );
    }
  }

  //Main Render
  return (
    <div>
      <div
        className="selector__header"
        style={{ paddingLeft: "20px", paddingTop: "20px" }}
      >
        {page === 1 ? <h1>1. Select a Lot</h1> : <></>}
        {page === 2 ? (
          <h1>
            2. Select Location(s) -{" "}
            <span style={{ textDecoration: "underline" }}>
              Lot {props.lotNumber}
            </span>
          </h1>
        ) : (
          <></>
        )}
        {page === 3 ? <h1>3. Review & Finish</h1> : <></>}
      </div>
      <Divider></Divider>
      <div className="selector__content" style={{ minHeight: "60vh" }}>
        {page === 1 ? (
          <LotSelectorPageOne
            dataSource={props.dataSource}
            handleStateChange={props.handleStateChange}
            nextPage={nextPage}
            targetQuantity={calculateCurrentTarget()}
            selectedLots={props.selectedLots}
            currentLotTotal={getLotTotal()}
            deleteFromSelectedLots={deleteFromSelectedLots}
            setLotsSelected={props.setLotsSelected}
          ></LotSelectorPageOne>
        ) : (
          <></>
        )}
        {page === 2 ? (
          <LotSelectorPageTwo
            locationSelections={locationSelections}
            targetQuantity={calculateCurrentTarget()}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            setLocationSelections={setLocationSelections}
            setDataSource={props.setDataSource}
            dataSource={props.dataSource}
            setNextDisabled={setNextDisabled}
            selectedLots={props.selectedLots}
          ></LotSelectorPageTwo>
        ) : (
          <></>
        )}
        {page === 3 ? (
          <LotSelectorPageThree
            locationSelections={locationSelections}
            selectedLots={props.selectedLots}
            remainder={calculateCurrentTarget()}
            selectedLocations={selectedLocations}
            targetQuantity={calculateCurrentTarget()}
          ></LotSelectorPageThree>
        ) : (
          <></>
        )}
      </div>
      <div
        className="selector__footer"
        style={{ paddingright: "20px", paddingBottom: "20px" }}
      >
        <div
          style={{ display: "flex", width: "100%", justifyContent: "right" }}
        >
          {SHOW_BACK.includes(page) ? (
            <div
              style={{
                padding: "10px",
                border: "solid 2px black",
                borderRadius: "7px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                prevPage();
              }}
            >
              <h1 style={{ fontWeight: "700" }}>Back</h1>
            </div>
          ) : (
            <></>
          )}

          {SHOW_NEXT.includes(page) ? (
            <div
              style={{
                padding: "10px",
                borderRadius: "7px",
                marginRight: "10px",
                background: "#1890ff",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!nextDisabled) {
                  if (page === 2) {
                    props.setSelectedLots([
                      ...props.selectedLots,
                      ...selectedLocations,
                    ]);
                  }

                  nextPage();
                } else {
                  notification.warning({
                    message: "Warning",
                    description: `A location was not selected`,
                    style: {
                      width: "40vw",
                    },
                  });
                }
              }}
            >
              <h1 style={{ fontWeight: "700", color: "white" }}>Next</h1>
            </div>
          ) : (
            <></>
          )}

          {/*
           *Finally check if target quantity is reached, loop back if not
           */}

          {page === TOTAL_PAGES ? getLastButton() : <></>}
        </div>
      </div>
    </div>
  );
};

export default LotSelector;
