import { Layout } from "antd";
import React from "react";
import axios from "axios";

import Boxes from "../../components/boxes/Terminal/terminal.component";
import Header from "../../components/header/header.component";
import { connect } from "react-redux";
import { CONST_API_URL } from "../../appconfig";
import { workCenterActions } from "../../store/work-center-slice";
import { appConfigActions } from "../../store/app-config.slice";
import store from "../../store";

const { Content } = Layout;

const INTERVAL_CHECK_VERSION_MS = 1000 * 60 * 1;
class MTUPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signinVisible: false,
      navOpNo: null,
      hspop: null,
      orderNo: null,
      showLogin: false,
    };
  }

  componentDidMount() {
    // --- Update work center info ---
    if (this.props.workCenter) {
      this.updateWorkCenterInfo(this.props.workCenter);
    }

    // --- Initial version check when this component is rendered ---
    this.checkVersion();

    // --- Timer setup for interval version check ---
    const browserVersion = this.props.version;
    setTimeout(function runCheckVersionTimer() {
      try {
        axios.get(`${CONST_API_URL}/getversion`).then((res) => {
          var version = res.data;
          if (version.err) {
          } else {
            if (version.data !== browserVersion) {
              store.dispatch(appConfigActions.updateVersion(version.data)); //this.props cannot be reachable from this timeout function
              setTimeout(() => window.location.reload(), 10000);
            }
          }
        });
      } catch (error) {
        console.log("ERROR - getversion", error);
      } finally {
        setTimeout(runCheckVersionTimer, INTERVAL_CHECK_VERSION_MS);
      }
    }, INTERVAL_CHECK_VERSION_MS);
  }

  async updateWorkCenterInfo(wcObj) {
    try {
      const res = await axios.get(`${CONST_API_URL}/getWorkCenter`, {
        params: { workcenter: wcObj.workcenter_no },
      });
      const data = res.data;

      const formattedValue = {
        workcenter_no: data.workcenter || "",
        workcenter_name: data.workcenter_name || "",
        workcenter_type: data.consumption_type || 0,
        workcenterpausetype: data.pause_type || 0,
        workcenterNonProductiveGroup: data.non_productive_group || null,
        workcenterLoginSupervisorRequired:
          data.login_supervisor_approval_required || null,
        subcontractor_no: data.subcontractor_no || null,
      };

      //Store in redux
      this.props.updateWorkCenter(formattedValue);
    } catch (error) {
      console.log("ERROR - updateWorkCenterInfo", error);
    }
  }

  checkVersion() {
    axios
      .get(`${CONST_API_URL}/GetVersion`)
      .then((res) => {
        var version = res.data;
        if (version.err) {
        } else {
          if (version.data !== this.props.version) {
            this.props.updateVersion(version.data);
            setTimeout(() => window.location.reload(), 10000);
          }
        }
      })
      .catch((error) => {
        console.log("ERROR - getversion", error);
      });
  }

  render() {
    return (
      <>
        <Layout style={{ height: "100vh" }}>
          <Header />
          <Layout>
            <Layout className="site-layout">
              <Content style={{ margin: "0" }}>
                <div
                  className="site-layout-background"
                  style={{ padding: 0, margin: 0, height: "100%" }}
                >
                  <Boxes />
                </div>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  workCenter: state.workCenter,
  version: state.appConfig.version,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateWorkCenter: (workCenter) =>
      dispatch(workCenterActions.updateWorkCenter(workCenter)),
    updateVersion: (version) =>
      dispatch(appConfigActions.updateVersion(version)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MTUPage);
