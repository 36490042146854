var config = {
  moLimit: 0, //0: No limit
  emailRecipients: "helpdesk@alpacasys.com",
  branding: {
    primaryColor: "#D32F2F",
    secondaryColor: "#235921",
    backgroundColor: "#212529",
    primaryTextColor: "white",
    logo: "", //TODO: Attach logo path?
  },
};

export default config;
