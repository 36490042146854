import { RightOutlined } from "@ant-design/icons";
import { Button } from "primereact/button";

const MenuItem = ({ label, icon, onClick, disabled = false }) => {
  return (
    <Button
      size="lg"
      type="primary"
      style={{ fontSize: "3rem", width: "100%", textAlign: "center" }}
      onClick={onClick}
      disabled={disabled}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          {icon}

          <h1 style={{ color: "#FFF" }}>{label}</h1>
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "20px",
          }}
        >
          <RightOutlined />
        </div>
      </div>
    </Button>
  );
};

export default MenuItem;
