import { Table } from "antd";
const { Column } = Table;

const LotSelectorPageThree = (props) => {
  function getRemainderColour(remainder) {
    var colour;
    if (remainder <= 0) {
      colour = "#4dff56";
    } else {
      colour = "#ffd04d";
    }
    return colour;
  }

  //Main Render
  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ margin: "20px" }}
    >
      <Table
        pagination={false}
        dataSource={props.selectedLots}
        style={{ minHeight: "250px" }}
      >
        <Column
          title={<h3 style={{ fontWeight: "700" }}>Lot #</h3>}
          dataIndex="lotNumber"
          key="lotNumber"
          render={(value) => {
            return <h3>{value}</h3>;
          }}
        ></Column>
        <Column
          title={<h3 style={{ fontWeight: "700" }}>Location Code</h3>}
          dataIndex="location"
          key="location"
          render={(value) => {
            return <h3>{value}</h3>;
          }}
        ></Column>
        <Column
          title={<h3 style={{ fontWeight: "700" }}>Quantity</h3>}
          dataIndex="quantity"
          key="quantity"
          render={(value, record) => {
            return <h3>{value.toFixed(5)}</h3>;
          }}
        ></Column>
      </Table>
      <div style={{ marginTop: "20px" }}>
        <h1>
          Remaining target quantity:{" "}
          <span style={{ color: getRemainderColour(props.remainder) }}>
            {props.remainder > 0 ? props.remainder.toFixed(5) : 0}
          </span>
        </h1>
      </div>
    </div>
  );
};

export default LotSelectorPageThree;
