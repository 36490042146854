import axios from "axios";
import { CONST_API_URL } from "../../../appconfig";

export async function getComponentItems(mo, workCenter, partNo, division) {
  try {
    const result = await axios.post(
      `${CONST_API_URL}/consumption/ComponentItems`,
      {
        MO: mo,
        // workCenter: workCenter,
        // partNo: partNo,
      }
    );
    if (result.data) {
      let finalData = result.data;
      for (let i = 0; i < finalData.length; i++) {
        let lotInfo = await getItemLotAvailability(
          finalData[i].ComponentItemNo,
          division
        );
        finalData[i] = {
          ...finalData[i],
          lotInfo: lotInfo.data,
          lotMandatory: lotInfo.data.some(
            (e) => e.heat_lot_no_required === "true"
          ),
        };
      }
      return finalData;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getHisotry(data) {
  try {
    const result = await axios.post(
      `${CONST_API_URL}/consumption/MultiCutHistory`,
      data
    );
    let tempusedQty = 0;
    let tempHistoryComment = [];
    if (result.data) {
      result.data.Cuthistory.forEach((item, index) => {
        if (isNaN(parseFloat(item.Comment.split(",")[1]))) {
          tempHistoryComment.push(item.Comment);
          if (index !== 0 && item.Comment.split(",").length === 9) {
            tempusedQty = tempusedQty + parseFloat(item.Comment.split(",")[4]);
          }
        } else {
          let comma = item.Comment.indexOf(",");
          let comment =
            item.Comment.substring(0, comma) +
            item.Comment.substring(comma + 1);
          tempHistoryComment.push(comment);
          if (index !== 0 && comment.split(",").length === 9) {
            tempusedQty = tempusedQty + parseFloat(comment.split(",")[4]);
          }
        }
      });
      tempusedQty = Math.round((tempusedQty + Number.EPSILON) * 1000) / 1000;
      return { userQty: tempusedQty, history: tempHistoryComment };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function sendEmail(values) {
  try {
    const result = axios.post(`${CONST_API_URL}/consumption/sendEmail`, values);
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function getItemLotAvailability(componentItemNo, division) {
  try {
    const result = axios.post(
      `${CONST_API_URL}/consumption/ItemLotAvailability`,
      { itemNo: componentItemNo, division: division }
    );
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function updateRoutingQtyService(
  outputObject,
  packagedConsumption
) {
  try {
    const result = await axios.post(
      `${CONST_API_URL}/consumption/updateRoutingQty`,
      { ...outputObject, packagedConsumption }
    );
    return result;
  } catch (error) {
    console.log("updateRoutingQtyService", error);
  }
}

export async function getReleasedProdOrders(orderNo) {
  try {
    const result = await axios.get(
      `${CONST_API_URL}/nav/getReleasedProdOrder`,
      { params: { order_no: orderNo } }
    );
    return result.data;
  } catch (error) {
    console.log("getReleasedProdOrders", error);
  }
}
